import styled, { css } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const FooterContainer = styled.footer.withConfig({
  shouldForwardProp: (prop) => prop !== "themeVariant",
})<{ themeVariant: "dark" | "light" | "white" }>`
  width: 100%;
  margin-top: 40px;

  ${({ themeVariant }) => {
    switch (themeVariant) {
      case "dark":
        return css`
          background-color: ${colorPalette.blue900};
          color: ${colorPalette.white};
        `;
      case "light":
        return css`
          background-color: ${colorPalette.blue300};
          color: ${colorPalette.textMain};
        `;
      case "white":
      default:
        return css`
          border-top: 1px solid ${colorPalette.blue300};
          background-color: ${colorPalette.white};
          color: ${colorPalette.textMain};
        `;
    }
  }}
`;

export const FooterContent = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "themeVariant",
})<{ themeVariant: "dark" | "light" | "white" }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;

  ${({ themeVariant }) => {
    switch (themeVariant) {
      case "dark":
        return css`
          border-bottom: 1px solid ${colorPalette.blue700};
        `;
      case "light":
        return css`
          border-bottom: 1px solid #fafbfc66;
        `;
      case "white":
      default:
        return css`
          border-bottom: 1px solid ${colorPalette.blue200};
        `;
    }
  }}
`;

export const FooterLogo = styled.img`
  height: 20px;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FooterLink = styled.a.withConfig({
  shouldForwardProp: (prop) => prop !== "themeVariant",
})<{ themeVariant: "dark" | "light" | "white" }>`
  margin: 0;
  text-decoration: none;
  color: ${({ themeVariant }) =>
    themeVariant === "dark" ? colorPalette.blue50 : colorPalette.textMain};
  ${typeScale.bodySMMain};

  &:hover {
    color: ${colorPalette.blue400};
  }
`;

export const FooterCopyright = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "themeVariant",
})<{ themeVariant: "dark" | "light" | "white" }>`
  padding: 16px;
  text-align: center;
  ${typeScale.bodyXXSMain};

  ${({ themeVariant }) => {
    switch (themeVariant) {
      case "dark":
        return css`
          color: ${colorPalette.textDark};
        `;
      case "light":
        return css`
          color: ${colorPalette.blue200};
        `;
      case "white":
      default:
        return css`
          color: ${colorPalette.textDark};
        `;
    }
  }}
`;

export const MobileFooterContainer = styled.footer.withConfig({
  shouldForwardProp: (prop) => prop !== "themeVariant",
})<{ themeVariant: "dark" | "light" | "white" }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ themeVariant }) =>
    themeVariant === "dark"
      ? colorPalette.blue900
      : themeVariant === "light"
      ? colorPalette.blue300
      : colorPalette.white};
  padding: 16px;
`;

export const MobileFooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MobileFooterCopyright = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "themeVariant",
})<{ themeVariant: "dark" | "light" | "white" }>`
  margin-top: 16px;
  text-align: center;
  ${typeScale.bodyXXSMain};
  color: ${({ themeVariant }) =>
    themeVariant === "dark"
      ? colorPalette.textDark
      : themeVariant === "light"
      ? colorPalette.blue200
      : colorPalette.textDark};
`;
