import React from 'react';
import {
    DocumentRow,
    DocumentName,
    DocumentDate,
    DocumentCategory,
    DocumentRowMobile,
} from './document-card.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';
import { formatDocumentType, IDocument } from '../../services/documentService';
import useIsMobile from '../../hooks/useIsMobile';
import { Spinner } from 'react-bootstrap';

interface DocumentCardProps {
    document: IDocument;
    showCategory?: boolean;
    isDownloading?: boolean;
    onDownload?: (document: IDocument) => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ document, showCategory = true, isDownloading = false, onDownload }) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <DocumentRowMobile onClick={onDownload ? () => onDownload(document) : undefined}>
                <div className="d-flex align-items-center" style={{ gap: 16 }}>
                    <MaterialIcon icon="draft" size={24} color={colorPalette.errorDark} />
                    <DocumentName>{document.file_name}</DocumentName>
                </div>
                {isDownloading ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{ color: colorPalette.blue500 }} />
                ) : onDownload ? (
                    <MaterialIcon icon="file_save" style="outlined" size={24} color={colorPalette.blue500} />
                ) : null
                }
            </DocumentRowMobile>
        );
    } else {
        return (
            <DocumentRow showCategory={showCategory} onClick={onDownload ? () => onDownload(document) : undefined}>
                <MaterialIcon icon="draft" size={24} color={colorPalette.errorDark} />
                <DocumentName>{document.document_type ? formatDocumentType(document.document_type) : document.file_name}</DocumentName>
                <DocumentDate>{new Date(document.created_at).toLocaleDateString()}</DocumentDate>
                {showCategory && (
                    <DocumentCategory>Unbekannt</DocumentCategory>
                )}
                {isDownloading ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{ color: colorPalette.blue500 }} />
                ) : onDownload ? (
                    <MaterialIcon icon="file_save" style="outlined" size={24} color={colorPalette.blue500} />
                ) : null
                }
            </DocumentRow>
        );
    }
};

export default DocumentCard;
