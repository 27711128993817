import styled from "styled-components";
import { typeScale, colorPalette } from "../../../../utils/theme";

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: inherit;
  padding: 24px 0 24px 24px;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 767.98px) {
    border-radius: 8px;
    height: 100%;
    padding: 12px 0 12px 12px;
    box-shadow: 0px 0px 20px 0px #0000001a;
  }

  /* Add a pseudo-element for the blurred background */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffa6;
    backdrop-filter: blur(20px);
    z-index: 1;
    border-radius: 8px;
    border: 1px solid ${colorPalette.blue200};
  }

  /* Ensure content stays clear and on top */
  * {
    position: relative;
    z-index: 2;
  }
`;

export const ChartContent = styled.div`
  width: 100%;
  height: 230px;
  margin-top: 16px;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 767.98px) {
    height: 150px;
  }
`;

export const ChartHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PortfolioVolume = styled.h2`
  margin: 0;
  text-align: start;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const PortfolioSubtitle = styled.p`
  margin: 0;
  text-align: start;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;

export const ROIContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
`;

export const ROISubLabel = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const ROILabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;
