import { FAQSection, FAQSectionBadge, FAQSectionDescription, FAQSectionHeadline, FeatureDescription, FeatureHeadline, FeatureImage, FeatureSection, Headline, HeroSection, HorizontalScrollWrapper, LogoContainer, PackageButtonWrapper, PackageSection, PackageSectionDescription, PackageSectionFooterText, PackageSectionHeadline, Subtitle, TabBarContainer, TabList } from "./details-page.styles";
import Logo from "../../../../assets/images/logo/logo-multicolor.svg";
import BasicImage1 from "../../../../assets/images/landing-page/basic_image_1.png";
import { Col, Row } from "react-bootstrap";
import Card from "./components/card/card";
import BasicPackageIcon from "../../../../assets/images/landing-page/basic-package-icon.png";
import PremiumPackageIcon from "../../../../assets/images/landing-page/premium-package-icon.png";
import FAQ from "./components/faq/faq";
import PackageCard from "./components/package-card/package-card";
import { useEffect, useState } from "react";
import PricingModal from "../../../../modals/pricing-modal/pricing-modal";
import CTAButton from "../../../../components/cta-button/cta-button";
import useIsMobile from "../../../../hooks/useIsMobile";
import TabBar from "../../components/tab-bar/tab-bar";

interface DetailsPageProps {
    anchor: string | null;
    onClickBack: () => void;
    onClickDetails: (value: string) => void;
}

const DetailsPage: React.FC<DetailsPageProps> = ({ anchor, onClickBack, onClickDetails }) => {
    const isMobile = useIsMobile();

    // States
    const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);

    useEffect(() => {
        if (anchor) {
            const targetElement = document.getElementById(anchor);
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: "smooth", // Smooth scrolling
                    block: "start",    // Align to the top of the section
                });
            }
        }
    }, [anchor]);

    return (
        <>
            <TabBar isDefaultSticky onClickItem={onClickDetails} />

            <HeroSection>
                <Headline>Leistungen & Pakete</Headline>
                <Subtitle>Die Mieterverwaltung mit managd ist simpel. Sobald du dich für ein Management-Paket, kümmern wir uns um den Rest.</Subtitle>
            </HeroSection>

            <FeatureSection id="services">
                <Row className={isMobile ? "" : "align-items-center"}>
                    <Col xs={12} md={4} className={isMobile ? "" : "text-center"}>
                        <FeatureImage src={BasicPackageIcon} alt="Basic Package" onClick={onClickBack} />
                        <FeatureHeadline>Basic Paket</FeatureHeadline>
                        <FeatureDescription>Die Mieterverwaltung mit managd ist simpel.</FeatureDescription>
                    </Col>

                    <Col xs={12} md={8}>
                        <Row className="align-items-center gx-3 gy-3">
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Basic"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Basic"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Basic"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Basic"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Basic"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FeatureSection>

            <FeatureSection>
                <Row className={isMobile ? "" : "align-items-center"}>
                    <Col xs={12} md={4} className={isMobile ? "" : "text-center"}>
                        <FeatureImage src={PremiumPackageIcon} alt="Premium Package" onClick={onClickBack} />
                        <FeatureHeadline>Premium Paket</FeatureHeadline>
                        <FeatureDescription>Die Mieterverwaltung mit managd ist simpel.</FeatureDescription>
                    </Col>

                    <Col xs={12} md={8}>
                        <Row className="align-items-center gx-3 gy-3">
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Premium"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Premium"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                            <Col xs={12} md={6} xl={4}>
                                <Card
                                    badgeText="Premium"
                                    iconSrc={BasicImage1}
                                    title="Unterstützung beim Handover"
                                    description="Entscheide dich, deine Unit von managd verwalten zu lassen und wähle ein Property-Management Paket aus, das deinen Bedürfnissen entspricht."
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FeatureSection>

            <PackageSection id="packages">
                <PackageSectionHeadline>
                    {isMobile ? "Unsere Property Management Pakete:" : "Unsere Pakete im Überblick:"}
                </PackageSectionHeadline>
                <PackageSectionDescription>Die Mieterverwaltung mit managd ist simpel. Sobald du dich für ein Management-Paket, kümmern wir uns um den Rest.</PackageSectionDescription>

                {isMobile ? (
                    <HorizontalScrollWrapper>
                        <PackageCard
                            title="Basic"
                            price={5}
                            minFee={3500}
                            features={[
                                'Handover-Support',
                                'Vermarktung und Vermietung',
                                'Mietermanagement',
                                'Wartung und Instandhaltung',
                                'Vermieterportal',
                            ]}
                            onClickInfo={() => setIsPricingModalVisible(true)}
                            isPremium={false}
                        />
                        <PackageCard
                            title="Premium"
                            price={7}
                            minFee={4000}
                            features={[
                                'Handover-Support',
                                'Vermarktung und Vermietung',
                                'Mietermanagement',
                                'Wartung und Instandhaltung',
                                'Vermieterportal',
                                'Cash-Handling',
                                'Service-Charge Management',
                            ]}
                            onClickInfo={() => setIsPricingModalVisible(true)}
                            isPremium={true}
                        />
                    </HorizontalScrollWrapper>
                ) : (
                    <Row className="justify-content-center">
                        <Col xs={12} md={4}>
                            <PackageCard
                                title="Basic"
                                price={5}
                                minFee={3500}
                                features={[
                                    'Handover-Support',
                                    'Vermarktung und Vermietung',
                                    'Mietermanagement',
                                    'Wartung und Instandhaltung',
                                    'Vermieterportal',
                                ]}
                                onClickInfo={() => setIsPricingModalVisible(true)}
                                isPremium={false}
                            />
                        </Col>

                        <Col xs={12} md={4}>
                            <PackageCard
                                title="Premium"
                                price={7}
                                minFee={4000}
                                features={[
                                    'Handover-Support',
                                    'Vermarktung und Vermietung',
                                    'Mietermanagement',
                                    'Wartung und Instandhaltung',
                                    'Vermieterportal',
                                    'Cash-Handling',
                                    'Service-Charge Management',
                                ]}
                                onClickInfo={() => setIsPricingModalVisible(true)}
                                isPremium={true}
                            />
                        </Col>
                    </Row>
                )}

                <PackageButtonWrapper>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Pakete vergleichen"
                        onClick={() => alert("Coming soon...")}
                        fullWidth
                    />
                </PackageButtonWrapper>

                <PackageSectionFooterText>
                    Um mehr über Pakete für Investoren mit Erstwohnsitz in den VAE zu erfahren,{" "}
                    <span onClick={() => alert("Coming soon...")}>kontaktiere uns.</span>
                </PackageSectionFooterText>

            </PackageSection>

            <FAQSection id="faq">
                <Row>
                    <Col xs={12} md={5}>
                        <FAQSectionBadge>FAQ</FAQSectionBadge>
                        <FAQSectionHeadline>Häufige Fragen</FAQSectionHeadline>
                        <FAQSectionDescription>Eine Plattform, die entwickelt wurde, um die komplexen Anforderungen anspruchsvoller Investoren zu erfüllen.</FAQSectionDescription>
                    </Col>

                    <Col xs={12} md={7}>
                        <FAQ />
                    </Col>

                </Row>
            </FAQSection>

            {isPricingModalVisible && (
                <PricingModal
                    type="property-management"
                    onClose={() => setIsPricingModalVisible(false)}
                />
            )}
        </>
    );
};

export default DetailsPage;
