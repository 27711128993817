import React, { useMemo } from 'react';
import { SPACES_ENDPOINT } from '../../../../../../utils/constants';
import { formatMoney, getActiveLease } from '../../../../../../utils/helpers';
import {
    Container,
    MobileUnitCardContent,
    UnitRentalContainer,
    UnitStatistic,
    UnitStatisticLabel,
    UnitStatistics,
    UnitTagsContainer,
    UnitTitle
} from './mobile-unit-card.styles';
import Tag from '../../../../../../components/tag/tag';
import { colorPalette } from '../../../../../../utils/theme';
import MaterialIcon from '../../../../../../components/material-icon';
import LazyLoadImage from '../../../../../../components/lazy-load-image/lazy-load-image';
import { useNavigate } from 'react-router-dom';
import { IUnit, UnitPurposeType } from '../../../../../../services/unitService';

interface MobileUnitCardProps {
    unit: IUnit;
    showFooterContent?: boolean;
}

const MobileUnitCard: React.FC<MobileUnitCardProps> = ({
    unit,
    showFooterContent = false,
}) => {
    const navigate = useNavigate();

    // Functions
    const onClickUnit = (unitId: number) => {
        navigate(`/my-units/${unitId}`);
    };

    // Memos
    const constructionProgressItem = useMemo(() => {
        return unit.property.construction_progress_items.length > 0 ? unit.property.construction_progress_items[0] : null
    }, [unit]);

    const activeLease = useMemo(() => {
        return getActiveLease(unit.leases);
    }, [unit]);

    return (
        <Container onClick={() => onClickUnit(unit.unit_id)}>
            <LazyLoadImage
                src={unit.pictures.length > 0 ? `${SPACES_ENDPOINT}${unit.pictures[0].picture_path}` : ""}
                alt={unit.unit_number}
                width={"40%"}
                maxHeight={180}
            />
            <MobileUnitCardContent>
                <UnitTitle>{unit.unit_number} • {unit.property.property_name}</UnitTitle>
                <UnitStatistics>
                    <UnitStatistic>
                        <MaterialIcon icon="bed" size={16} color={colorPalette.blue300} />
                        <UnitStatisticLabel>{unit.bedroom} Bedroom</UnitStatisticLabel>
                    </UnitStatistic>
                    <UnitStatistic>
                        <MaterialIcon icon="straighten" size={16} color={colorPalette.blue300} />
                        <UnitStatisticLabel>{unit.square_feet} Sqft</UnitStatisticLabel>
                    </UnitStatistic>
                </UnitStatistics>
                <UnitTagsContainer>
                    {activeLease && (
                        <Tag label="Vermietet" type="unitStatus" variant="rented" size={"S"} />
                    )}
                    {!constructionProgressItem && !activeLease && unit.minimum_annual_rent && (
                        <Tag label="Mietersuche" type="unitStatus" variant="vacant" size={"S"} />
                    )}
                    {constructionProgressItem && constructionProgressItem.percentage < 100 && (
                        <Tag label="Bauphase" type="unitStatus" variant="inProgress" size={"S"} />
                    )}
                    {unit.purpose_type === UnitPurposeType.SELF_INTEREST && (
                        <Tag label="Selbstnutzer" type="unitStatus" variant="selfInterest" size={"S"} />
                    )}
                </UnitTagsContainer>
                {showFooterContent && (
                    <UnitRentalContainer>
                        <p>AED {formatMoney(getActiveLease(unit.leases)?.annual_rent_amount ?? 0, false)}</p>
                        <span>/Jahr</span>
                    </UnitRentalContainer>
                )}
            </MobileUnitCardContent>
        </Container>
    );
};

export default MobileUnitCard;
