import React, { useEffect } from 'react';
import LogoMulticolor from '../../../../assets/images/logo/logo-multicolor.svg';
import { SidebarContainer, NavItem, LogoImage, SidebarHeader, Overlay, Category, LoginButtonWrapper } from './sidebar.styles';
import { useSwipeable } from 'react-swipeable';
import MaterialIcon from '../../../../components/material-icon';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import { colorPalette } from '../../../../utils/theme';
import useInvestorDetailStore from '../../../../stores/investorDetailStore';
import CTAButton from '../../../../components/cta-button/cta-button';
import { ComingSoonBadge } from '../tab-bar/tab-bar.styles';
import { usePWA } from '../../../../context/PWAContext';
import useIsMobile from '../../../../hooks/useIsMobile';

interface SidebarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
    onClickItem: (value: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarVisible, toggleSidebar, onClickItem }) => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();
    const isPWA = usePWA();

    // Stores
    const { investor } = useInvestorDetailStore();

    // Prevent scrolling down
    useEffect(() => {
        if (isSidebarVisible) {
            document.body.style.overflow = 'hidden';

            const preventDefault = (e: TouchEvent) => {
                e.preventDefault();
            };

            document.addEventListener('touchmove', preventDefault, { passive: false });

            return () => {
                document.body.style.overflow = 'unset';
                document.removeEventListener('touchmove', preventDefault);
            };
        }
    }, [isSidebarVisible]);

    const handleItemClick = (url: string) => {
        toggleSidebar();
        navigate(url, { replace: true, clearHistory: true });
    };

    // Swipeable configuration for mobile
    const handlers = useSwipeable({
        onSwipedLeft: () => toggleSidebar(),
        onSwipedRight: () => toggleSidebar(),
        delta: 30,
        trackMouse: true,
    });

    return (
        <>
            <SidebarContainer isVisible={isSidebarVisible} addBottomSpacing={isMobile && isPWA} {...handlers}>
                <SidebarHeader>
                    <LogoImage src={LogoMulticolor} alt="Logo" onClick={() => navigate("/", { replace: true, clearHistory: true })} />
                    <MaterialIcon
                        className="d-lg-none d-block"
                        icon="close"
                        size={24}
                        color={colorPalette.textMain}
                        onClick={toggleSidebar}
                    />
                </SidebarHeader>
                <ul className="nav flex-column">
                    <Category>Vermieter</Category>
                    <NavItem onClick={() => onClickItem("packages")}>
                        <button className="nav-link">Pakete</button>
                    </NavItem>
                    <NavItem onClick={() => onClickItem("services")}>
                        <button className="nav-link">Leistungen</button>
                    </NavItem>
                    <NavItem onClick={() => onClickItem("faq")}>
                        <button className="nav-link">FAQ</button>
                    </NavItem>
                    <Category topBorder>
                        Mieter
                        <ComingSoonBadge>Coming<br></br>soon</ComingSoonBadge>
                    </Category>
                    {investor && (
                        <NavItem topBorder onClick={() => handleItemClick("/profile")}>
                            <button className="nav-link">
                                <MaterialIcon icon="account_circle" size={24} color={colorPalette.primaryDark} />
                                Account
                            </button>
                        </NavItem>
                    )}
                </ul>
                {!investor && (
                    <LoginButtonWrapper>
                        <CTAButton
                            variant="primary"
                            size="L"
                            label="Anmelden"
                            onClick={() => navigate("/login")}
                            fullWidth
                        />
                    </LoginButtonWrapper>
                )}
            </SidebarContainer>
            {isSidebarVisible && <Overlay onClick={toggleSidebar} />}
        </>
    );
};

export default Sidebar;
