// DetailItem.tsx
import React from 'react';
import { colorPalette } from '../../utils/theme';
import MaterialIcon from '../../components/material-icon';
import { DetailItemContainer, LabelContainer, Value } from './detail-item.styles';
import { MaterialSymbol } from 'material-symbols';

interface DetailItemProps {
    icon: MaterialSymbol;
    label: string;
    value: string | number;
    hideBottomBorder?: boolean;
    removeHorizontalPadding?: boolean;
}

const DetailItem: React.FC<DetailItemProps> = ({ icon, label, value, hideBottomBorder = false, removeHorizontalPadding = false }) => {
    return (
        <DetailItemContainer hideBottomBorder={hideBottomBorder} removeHorizontalPadding={removeHorizontalPadding}>
            <LabelContainer>
                <MaterialIcon icon={icon} size={20} color={colorPalette.textDark} />
                {label}
            </LabelContainer>
            <Value>{value}</Value>
        </DetailItemContainer>
    );
};

export default DetailItem;
