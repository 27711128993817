import styled from "styled-components";
import { colorPalette, typeScale } from "../../../utils/theme";

export const CardContainer = styled.div`
  border: 1px solid ${colorPalette.blue500};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background: linear-gradient(
    82.85deg,
    #324c71 0.41%,
    #17224d 52.38%,
    #25438b 97.76%
  );

  @media (max-width: 767.98px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Title = styled.h2`
  margin-top: 16px;
  margin-bottom: 12px;
  color: ${colorPalette.white};
  ${typeScale.headingSMMain};
`;

export const Subtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textLight};
  ${typeScale.bodyXS};
`;

export const Divider = styled.div`
  margin-bottom: 24px;
  width: 100%;
  height: 1px;
  background-color: ${colorPalette.blue200};
`;

export const FeaturesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  gap: 32px;

  @media (max-width: 767.98px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: start;
  gap: 16px;
`;

export const FeatureIcon = styled.div`
  color: ${colorPalette.blue400};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${colorPalette.blue300};
  flex-shrink: 0;
`;

export const FeatureTitle = styled.h3`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXSMain};
  }
`;

export const FeatureDescription = styled.p`
  margin: 4px 0 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const ButtonsContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 24px;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;

  @media (max-width: 767.98px) {
    display: block;

    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;
