import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const HeaderContainer = styled.div`
  padding: 0px 16px;
  margin-top: 48px;

  @media (max-width: 767.98px) {
    width: fit-content;
    margin-top: 32px;
  }
`;

export const PropertyUpdatesContainer = styled.div`
  .scroll-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 24px;
    border-bottom: 1px solid ${colorPalette.blue200};
  }

  .updates-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding-left: 16px;
    padding-right: 16px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > div {
      margin-right: 16px; /* Default margin between items */

      &:first-child {
        margin-left: 0; /* No gap before the first item */
      }

      &:last-child {
        margin-right: 0; /* No gap after the last item */
      }
    }
  }
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Subtitle = styled.p`
  color: ${colorPalette.textDark};
  margin: 4px 0 16px;
  ${typeScale.bodyLG}

  @media (max-width: 767.98px) {
    ${typeScale.bodySM};
  }
`;

export const ScrollButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 35%;
  transform: translateY(-50%);
  color: ${colorPalette.primaryDark};
  transition: background-color 0.2s ease;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.8);

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  &.left {
    left: 4px;
  }

  &.right {
    right: 4px;
  }
`;
