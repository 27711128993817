import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import { fetchUnits } from "../../services/unitService";
import useUnitStore from "../../stores/unitStore";
import UnitCard from "./components/unit-card/unit-card";
import { getActiveLease } from "../../utils/helpers";
import HouseAnim from "../../assets/anims/house-anim.json";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import Filter from "../../components/filter/filter";

// Import styled components
import {
    StyledContainer,
    Title,
    LoadingContainer,
    NoUnitsContainer,
    NoUnitsMessage,
    Row,
    Col,
    LoaderContainer,
    HeaderContainer,
    FilterContainer,
    HeaderTitleContainer,
} from './my-units-page.styles';
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";
import Statistics from "./components/statistics/statistics";
import useAlertStore from "../../stores/alertStore";
import useIsMobile from "../../hooks/useIsMobile";

const MyUnitsPage = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // States
    const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

    // Stores
    const {
        units,
        currentPage,
        isLoading,
        isInitialLoaded,
        moreDataAvailable,
        setUnits,
        setCurrentPage,
        setIsLoading,
        setIsInitialLoaded,
        setMoreDataAvailable
    } = useUnitStore();
    const showAlert = useAlertStore(state => state.showAlert);

    // Function to handle project selection
    const handleProjectSelect = (projectId: number) => {
        setSelectedProjects((prevSelected) =>
            prevSelected.includes(projectId)
                ? prevSelected.filter((id) => id !== projectId)
                : [...prevSelected, projectId]
        );
    };

    const loadUnits = useCallback(async () => {
        setIsLoading(true);
        try {
            // Fetch units based on selected projects
            const fetchedUnits = await fetchUnits(undefined, currentPage, 20);
            setUnits(currentPage === 1 ? fetchedUnits : [...units, ...fetchedUnits]);
            setMoreDataAvailable(fetchedUnits.length === 20);
            setIsInitialLoaded(true);
        } catch (error) {
            console.error('Error while fetching units:', error);
            showAlert("error", (error as Error).message);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage, units, setUnits, setMoreDataAvailable, setIsInitialLoaded, setIsLoading, showAlert]);

    useEffect(() => {
        loadUnits();
    }, [loadUnits]);

    // Infinite scroll fetch function
    const fetchMoreData = () => {
        if (moreDataAvailable && !isLoading) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onClickUnit = (unitId: number) => {
        navigate(`/my-units/${unitId}`);
    };

    const filteredUnits = useMemo(() => {
        let filteredUnits = units;

        // Apply project filters
        if (selectedProjects.length > 0) {
            filteredUnits = filteredUnits.filter(unit => selectedProjects.includes(unit.property.property_id));
        }

        // Apply status filters
        if (selectedStatus) {
            if (selectedStatus === "under-construction") {
                filteredUnits = filteredUnits.filter(unit => unit.property.under_construction);
            } else if (selectedStatus === "rented") {
                filteredUnits = filteredUnits.filter(unit => getActiveLease(unit.leases) !== null);
            } else if (selectedStatus === "ready") {
                filteredUnits = filteredUnits.filter(unit => !unit.property.under_construction && getActiveLease(unit.leases) === null);
            }
        }

        return filteredUnits;
    }, [units, selectedProjects, selectedStatus]);

    const projectFilters = useMemo(() => {
        const projectIds = Array.from(new Set(units.map((unit) => unit.property.property_id)));
        return projectIds.map((projectId) => ({
            label: units.find((unit) => unit.property.property_id === projectId)?.property.property_name || "",
            value: projectId,
        }));
    }, [units]);

    return (
        <StyledContainer>
            <HeaderContainer>
                <HeaderTitleContainer>
                    <Title>Meine Einheiten</Title>
                </HeaderTitleContainer>

                <Statistics units={units} />

                <FilterContainer>
                    <Filter
                        label="Status"
                        hasDropdown
                        items={[
                            { label: "Alle anzeigen", value: "reset" },
                            { label: "Im Bau", value: "under-construction" },
                            { label: "Fertig", value: "ready" },
                            { label: "In Vermietung", value: "rented" },
                        ]}
                        selectedItems={selectedStatus ? [selectedStatus] : []}
                        onSelect={(value) => {
                            if (value[0] === "reset") {
                                setSelectedStatus(null);
                            } else {
                                setSelectedStatus(value[0]);
                            }
                        }}
                        isSelected={selectedStatus !== null && selectedStatus.trim().length > 0}
                    />

                    {projectFilters.map((filter) => (
                        <Filter
                            key={filter.value}
                            label={filter.label}
                            isSelected={selectedProjects.includes(filter.value)}
                            onSelect={() => handleProjectSelect(filter.value)}
                        />
                    ))}
                </FilterContainer>
            </HeaderContainer>

            {isLoading && !isInitialLoaded ? (
                <LoadingContainer>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </LoadingContainer>
            ) : filteredUnits.length === 0 ? (
                <NoUnitsContainer>
                    <Lottie className="mt-4" animationData={HouseAnim} loop={false} style={{ height: 400 }} />
                    <NoUnitsMessage>Derzeit sind keine Immobilien in deinem Besitz aufgeführt.</NoUnitsMessage>
                </NoUnitsContainer>
            ) : (
                <>
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden", paddingLeft: 16, paddingRight: 16 }}
                        dataLength={filteredUnits.length}
                        next={fetchMoreData}
                        hasMore={moreDataAvailable}
                        loader={
                            <LoaderContainer>
                                <div className="spinner-border text-primary" role="status" />
                            </LoaderContainer>
                        }
                        scrollableTarget="content-area"
                    >
                        <Row>
                            {filteredUnits.map((unit) => (
                                <Col key={unit.unit_id}>
                                    <UnitCard
                                        imageSrc={unit.pictures.length > 0 ? unit.pictures[0].picture_path : ""}
                                        propertyName={unit.property.property_name}
                                        title={unit.unit_number}
                                        onClick={() => onClickUnit(unit.unit_id)}
                                        bedroom={unit.bedroom}
                                        bathroom={unit.bathroom}
                                        square_feet={unit.square_feet}
                                        purchasePrice={unit.owner?.purchase_price ?? 0}
                                        expectedAnnualRent={unit.minimum_annual_rent}
                                        rent={getActiveLease(unit.leases)?.annual_rent_amount ?? undefined}
                                        purpose_type={unit.purpose_type}
                                        constructionProgressItem={unit.property.construction_progress_items.length > 0 ? unit.property.construction_progress_items[0] : undefined}
                                        pmPackage={undefined}
                                        isMobile={isMobile}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </InfiniteScroll>

                    <GoodToKnowArticles className="mt-3" />
                </>
            )}
        </StyledContainer>
    );
};

export default MyUnitsPage;
