import React from 'react';
import {
    CardContainer,
    BackgroundBlur,
    Content,
    Header,
    Amount,
    GrossReturn,
    Label,
    Transactions,
    TransactionItem,
    TransactionAmount,
    TransactionTitle,
    Title,
} from './mobile-rent-payments-card.styles';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import { formatMoney } from '../../../../utils/helpers';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

interface MobileRentPaymentsCardProps {
    annualRent: number;
    grossReturn: number;
}

const MobileRentPaymentsCard: React.FC<MobileRentPaymentsCardProps> = ({
    annualRent,
    grossReturn,
}) => {
    const navigate = useCustomNavigate();

    return (
        <CardContainer onClick={() => navigate("/financials")}>
            {/* Blurred Background */}
            <BackgroundBlur />
            {/* Content */}
            <Content>
                <div style={{ flexGrow: 1 }}>
                    <div className="d-flex align-items-center" style={{ gap: 4, paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
                        <Title>Mieteinnahmen</Title>
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </div>
                    <Header>
                        <div>
                            <Amount>AED {formatMoney(annualRent, false)}</Amount>
                            <Label>2024</Label>
                        </div>

                        <div className="d-flex text-end" style={{ gap: 16 }}>
                            <div>
                                <GrossReturn>-</GrossReturn>
                                <Label>Nettorendite</Label>
                            </div>
                            <div>
                                <GrossReturn>{grossReturn.toFixed(2)}%</GrossReturn>
                                <Label>Bruttorendite</Label>
                            </div>
                        </div>
                    </Header>
                </div>
                {/* Transactions Section */}
                <Transactions>
                    <TransactionItem>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                            <MaterialIcon icon="location_away" size={20} color={colorPalette.successMedium} />
                            <TransactionTitle>Mieteinnahmen</TransactionTitle>
                        </div>
                        <TransactionAmount>AED {formatMoney(100000)}</TransactionAmount>
                    </TransactionItem>
                    <TransactionItem>
                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                            <MaterialIcon icon="send_money" size={20} color={colorPalette.textDark} />
                            <TransactionTitle>Ausgaben</TransactionTitle>
                        </div>
                        <TransactionAmount>AED {formatMoney(10000)}</TransactionAmount>
                    </TransactionItem>
                </Transactions>
            </Content>
        </CardContainer>
    );
};

export default MobileRentPaymentsCard;
