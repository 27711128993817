import './root.css';

import { useState, useEffect, useMemo, useRef, Suspense, useCallback } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import Topbar from '../components/topbar/topbar';
import useScrollStore from '../stores/scrollStore';
import { throttle } from 'lodash';
import useAlertStore from '../stores/alertStore';
import useInvestorDetailStore from '../stores/investorDetailStore';
import useViewportHeight from '../hooks/useViewportHeight';
import { checkSession, logout } from '../services/authService';
import useConstructionUpdateStore from '../stores/constructionUpdateStore';
import ScrollUpButton from '../components/scroll-up-button/scroll-up-button';
import useUnitDetailStore from '../stores/unitDetailStore';
import useDashboardStore, { DASHBOARD_STORAGE_NAME } from '../stores/dashboardStore';
import useUnitStore, { UNIT_STORAGE_NAME } from '../stores/unitStore';
import NavigationBottomBar from '../components/bottom-navigation-bar/bottom-navigation-bar';
import { usePWA } from '../context/PWAContext';
import useIsMobile from '../hooks/useIsMobile';
import useCustomNavigate from '../hooks/useCustomNavigate';
import Alert from '../components/alert/alert';
import { ContentAreaProvider } from '../context/ContentAreaContext';

const Root = () => {
    useViewportHeight();
    const navigate = useCustomNavigate();
    const location = useLocation();
    const isPWA = usePWA();
    const isMobile = useIsMobile();

    // References
    const contentAreaRef = useRef<HTMLDivElement>(null);

    // Stores
    const { investor, reset: resetInvestoreDetailStore } = useInvestorDetailStore();
    const setAtBottom = useScrollStore(state => state.setAtBottom);
    const hideAlert = useAlertStore(state => state.hideAlert);
    const resetUnitStore = useUnitStore(state => state.reset);
    const resetUnitDetailStore = useUnitDetailStore(state => state.reset);
    const resetConstructionUpdateStore = useConstructionUpdateStore(state => state.reset);
    const resetDashboardStore = useDashboardStore(state => state.reset);

    // States
    const [lastVisible, setLastVisible] = useState<number>(Date.now());
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    // Functions
    const scrollToTop = useCallback(() => {
        contentAreaRef?.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [contentAreaRef]);

    const runCheckAuth = async () => {
        try {
            await checkSession();
        } catch (error) {
            console.log('Error while checking user session: ', error);
            await logout();

            resetUnitStore();
            resetConstructionUpdateStore();
            resetInvestoreDetailStore();
            resetUnitDetailStore();
            resetDashboardStore();
        }
    };

    // Reset scrolling state
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryId = searchParams.get('categoryId');

        if (location.pathname !== "/faq" || (location.pathname === "/faq" && !categoryId)) {
            scrollToTop();
        }
    }, [location, scrollToTop]);

    // Clear old unused local storage
    useEffect(() => {
        localStorage.removeItem(DASHBOARD_STORAGE_NAME);
        localStorage.removeItem(UNIT_STORAGE_NAME);
    }, []);

    // Scroll handler to track reach bottom
    useEffect(() => {
        const scrollContainer = contentAreaRef.current;
        if (scrollContainer) {
            const handleScroll = throttle(() => {
                const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
                const offsetHeight = scrollContainer.scrollHeight;
                const reachedBottom = scrollPosition >= offsetHeight - 80;
                setAtBottom(reachedBottom);
            }, 300);

            scrollContainer.addEventListener('scroll', handleScroll);

            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [setAtBottom, contentAreaRef]);

    // Hide alert after navigation
    useEffect(() => {
        hideAlert();
        if (location.pathname === "/home" || location.pathname === "/login" || location.pathname === "/forgot-password") {
            setSidebarVisible(false);
        }
    }, [location]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                setLastVisible(Date.now());
            } else if (document.visibilityState === 'visible') {
                const now = Date.now();
                const diff = now - lastVisible;

                if (diff > 1800000) { // 30 min
                    runCheckAuth();
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [lastVisible]);

    useEffect(() => {
        if (investor) {
            runCheckAuth();
        }
    }, [investor]);

    useEffect(() => {
        if (contentAreaRef.current) {
            const isMobileView = () => window.innerWidth < 992;

            if (isMobileView()) {
                contentAreaRef.current.style.overflowY = isSidebarVisible ? 'hidden' : 'auto';
            }

            return () => {
                if (isMobileView()) {
                    document.body.style.overflow = 'auto';
                }
            };
        }
    }, [isSidebarVisible]);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const isFullPage = useMemo(() => {
        return location.pathname === "/home" || location.pathname === "/login" || location.pathname === "/reset-password" ||
            location.pathname === "/packages" || location.pathname === "/privacy-policy" ||
            location.pathname === "/terms-and-conditions" || location.pathname === "/onboarding"
    }, [location]);

    const theme = useMemo(() => {
        return location.pathname === "/" ? "dark" : "light";
    }, [location]);

    // Effects
    useEffect(() => {
        const backgroundColor = theme === "dark" ? "#0F1C4D" : "white";
        document.body.style.backgroundColor = backgroundColor;
        document.documentElement.style.backgroundColor = backgroundColor;
    }, [theme]);

    return (
        <ContentAreaProvider contentAreaRef={contentAreaRef}>
            <Suspense fallback={<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}>
                <div>
                    <Alert />
                    {isFullPage ? (
                        <>
                            {location.pathname !== "/home" && location.pathname !== "/login" && (
                                <Topbar toggleSidebar={toggleSidebar} theme={theme} isFullPage deactivateSidebar />
                            )}
                            <div id="content-area-full-page" ref={contentAreaRef} style={isMobile && isPWA ? { paddingBottom: 80 } : undefined}>
                                <Outlet />
                            </div>
                        </>
                    ) : (
                        <>
                            <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
                            <div id="detail">
                                <Topbar toggleSidebar={toggleSidebar} theme={theme} />
                                <div id="content-area" ref={contentAreaRef} style={isMobile && isPWA ? { paddingBottom: 80 } : undefined}>
                                    <Outlet />
                                    <ScrollUpButton scrollableRef={contentAreaRef} />
                                </div>
                            </div>
                            <NavigationBottomBar />
                        </>
                    )}
                </div>
            </Suspense>
        </ContentAreaProvider>
    );
};

export default Root;
