import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
    HeroSection,
    HeroContent,
    HeroTitle,
    HeroSubtitle,
    HeroButtonWrapper,
    GlassBuilding,
    SectionTitle,
    FeatureSection,
    FeatureCard,
    FeatureIcon,
    FeatureText,
    Divider,
    SectionSubtitle,
    SectionBadge,
    FeatureButtonWrapper,
    StatsSection,
    StatsTitle,
    StatsSubtitle,
    StatCard,
    StatNumber,
    StatLabel,
    StatsButtonWrapper,
    FeatureCardsWrapper,
    FeatureList,
    FeatureListItem,
    FeatureListItemLabel,
    AppSection,
    AppCard,
    AppCardsWrapper,
    AppScreen,
    AppDescription,
    AppTitle,
    AppOverlay,
    IndicatorDot,
    IndicatorWrapper,
    AppScreenBackground,
    ClickableList,
    ClickableListItem,
    ItemDescription,
    ItemTitle,
    AppButtonWrapper,
    AppSectionBackground,
} from "./home-page.styles";
import GlassBuildingImage from "../../../../assets/images/glass-building.png";
import RentalIcon from "../../../../assets/images/landing-page/rental-icon.svg";
import RentalManagementIcon from "../../../../assets/images/landing-page/rental-management-icon.svg";
import InspectionIcon from "../../../../assets/images/landing-page/inspection-icon.svg";
import RentalIncomeIcon from "../../../../assets/images/landing-page/rental-income-icon.svg";
import ExampleScreen from "../../../../assets/images/landing-page/example_screen.png";
import { useSwipeable } from "react-swipeable";
import { CSSTransition } from "react-transition-group";
import useIsMobile from "../../../../hooks/useIsMobile";
import TabBar from "../../components/tab-bar/tab-bar";
import CTAButton from "../../../../components/cta-button/cta-button";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";

const SERVICE_ITEMS = [
    {
        icon: RentalIcon,
        title: "Vermarktung und Vermietung",
        listItems: [
            "Renditeoptimierte Pricing-Strategie",
            "Vermarktung der Einheit auf relevanten Portalen",
            "Arrangement von Wohnungsbesichtigungen ",
            "Mieterscreening"
        ],
    },
    {
        icon: RentalManagementIcon,
        title: "Mietermanagement",
        listItems: [
            "Mietverträge und rechtliche Dokumente",
            "Direkter Ansprechpartner für Mieter vor Ort",
            "Schnelle Koordination in Notfällen",
            "Maßnahmen zur Räumung bei Mietausfall"
        ],
    },
    {
        icon: InspectionIcon,
        title: "Inspektionen und Instandhaltung",
        listItems: [
            "Inspektionen bei Ein- und Auszug",
            "Koordination von Instandhaltungsarbeiten",
            "Auswahl und Überwachung von Dienstleistern",
            "Garantienmanagement",
        ],
    },
    {
        icon: RentalIncomeIcon,
        title: "Verwaltung der Mieteinnahmen",
        listItems: [
            "Verwaltung der Cheques für Mieteinnahmen",
            "Auszahlung der Mieteinnahmen",
            "Ausfallmanagement bei geplatzten Cheques",
            "Service-Charge Payments"
        ],
    },
];

const APP_ITEMS = [
    {
        id: 1,
        title: "Portfolio-Tracking in Echtzeit",
        description: "Tracke die Performance deiner Immobilien in einer Übersicht – jederzeit und überall.",
        image: ExampleScreen, // Replace with your actual image path
    },
    {
        id: 2,
        title: "Verfolge den Status deiner Einheiten",
        description: "Immer auf dem neuesten Stand mit detaillierten Informationen zu deinen Einheiten.",
        image: ExampleScreen, // Replace with your actual image path
    },
    {
        id: 3,
        title: "Finanzielle Transparenz & Unterlagen",
        description: "Zugriff auf alle Finanzunterlagen und Berichte mit einem Klick.",
        image: ExampleScreen, // Replace with your actual image path
    },
    {
        id: 4,
        title: "Einfache Kommunikation",
        description: "Direkte Kommunikation mit deinem Manager – schnell und unkompliziert.",
        image: ExampleScreen, // Replace with your actual image path
    },
];

interface HomePageProps {
    onClickDetails: (value: string) => void;
}

const HomePage: React.FC<HomePageProps> = ({ onClickDetails }) => {
    const isMobile = useIsMobile();

    // References
    const featuresRef = useRef<HTMLDivElement>(null);
    const appRef = useRef<HTMLDivElement>(null);
    const statsRef = useRef<HTMLDivElement>(null);

    // States
    const [activeIndex, setActiveIndex] = useState(0);
    const [featuresVisible, setFeaturesVisible] = useState(false);
    const [appVisible, setAppVisible] = useState(false);
    const [statsVisible, setStatsVisible] = useState(false);
    const [isHeroVisible, setIsHeroVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsHeroVisible(true);
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const currentStatsRef = statsRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setStatsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.2 } // Trigger when 20% of the element is visible
        );

        if (currentStatsRef) {
            observer.observe(currentStatsRef);
        }

        return () => {
            if (currentStatsRef) observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const currentAppRef = appRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setAppVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.2 } // Trigger when 20% of the element is visible
        );

        if (currentAppRef) {
            observer.observe(currentAppRef);
        }

        return () => {
            if (currentAppRef) observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const currentFeaturesRef = featuresRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setFeaturesVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.2 } // Trigger when 20% of the element is visible
        );

        if (currentFeaturesRef) {
            observer.observe(currentFeaturesRef);
        }

        return () => {
            if (currentFeaturesRef) observer.disconnect();
        };
    }, []);

    // Handle swipe actions
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (activeIndex < APP_ITEMS.length - 1) {
                setActiveIndex((prevIndex) => prevIndex + 1);
            }
        },
        onSwipedRight: () => {
            if (activeIndex > 0) {
                setActiveIndex((prevIndex) => prevIndex - 1);
            }
        },
        trackMouse: true, // Enables swipe with mouse drag for testing
    });

    return (
        <>
            <TabBar onClickItem={onClickDetails} />

            {/* Hero Section */}
            <HeroSection>
                <CSSTransition in={isHeroVisible} timeout={800} classNames="slide" unmountOnExit>
                    <>
                        <Row className="align-items-center">
                            <Col md={7}>
                                <HeroContent>
                                    <p>FÜR VERMIETER</p>
                                    <HeroTitle>Einfach in Dubai vermieten mit managd.</HeroTitle>
                                    <HeroSubtitle>
                                        Wir kombinieren maßgeschneiderte Managementlösungen mit einer
                                        fortschrittlichen digitalen Plattform, um dein Immobilienportfolio
                                        auf höchstem Niveau zu betreuen.
                                    </HeroSubtitle>
                                    <HeroButtonWrapper>
                                        <CTAButton
                                            variant="primary"
                                            size="L"
                                            label="Alle Leistungen"
                                            onClick={() => onClickDetails("services")}
                                            fullWidth
                                        />
                                    </HeroButtonWrapper>
                                </HeroContent>
                            </Col>
                            <Col md={5} className={isMobile ? "text-end" : "text-center"}>
                                <GlassBuilding src={GlassBuildingImage} alt="Glass Building" />
                            </Col>
                        </Row>
                        <Divider />
                    </>
                </CSSTransition>
            </HeroSection>

            {/* Features Section */}
            <FeatureSection ref={featuresRef}>
                <CSSTransition in={featuresVisible} timeout={800} classNames="slide" unmountOnExit>
                    <div>
                        <SectionBadge>Leistungen</SectionBadge>
                        <SectionTitle>
                            Ein ganzheitlicher Ansatz für Ihre Immobilieninvestitionen.
                        </SectionTitle>
                        <SectionSubtitle>
                            Von der operativen Verwaltung bis zur strategischen Optimierung –
                            <br />
                            unser Team arbeitet daran, dein Portfolio nachhaltig zu stärken.
                        </SectionSubtitle>

                        {isMobile ? (
                            <FeatureCardsWrapper>
                                {SERVICE_ITEMS.map((item, index) => (
                                    <FeatureCard key={index}>
                                        <FeatureIcon src={item.icon} alt="Service icon" />
                                        <FeatureText>
                                            <h4>{item.title}</h4>
                                            <FeatureList>
                                                {item.listItems.map((listItem, subIndex) => (
                                                    <FeatureListItem key={subIndex}>
                                                        <MaterialIcon icon="check" size={16} color={colorPalette.blue500} />
                                                        <FeatureListItemLabel>{listItem}</FeatureListItemLabel>
                                                    </FeatureListItem>
                                                ))}
                                            </FeatureList>
                                        </FeatureText>
                                    </FeatureCard>
                                ))}
                            </FeatureCardsWrapper>
                        ) : (
                            <Row className="gx-2" style={{ marginBottom: 32 }}>
                                {SERVICE_ITEMS.map((item, index) => (
                                    <Col md={3} key={index}>
                                        <FeatureCard>
                                            <FeatureIcon src={item.icon} alt="Service icon" />
                                            <FeatureText>
                                                <h4>{item.title}</h4>
                                                <FeatureList>
                                                    {item.listItems.map((listItem, subIndex) => (
                                                        <FeatureListItem key={subIndex}>
                                                            <MaterialIcon icon="check" size={16} color={colorPalette.blue500} />
                                                            <FeatureListItemLabel>{listItem}</FeatureListItemLabel>
                                                        </FeatureListItem>
                                                    ))}
                                                </FeatureList>
                                            </FeatureText>
                                        </FeatureCard>
                                    </Col>
                                ))}
                            </Row>
                        )}
                        <FeatureButtonWrapper>
                            <CTAButton
                                variant="primary"
                                size="L"
                                label={isMobile ? "Alle Leistungen" : "Alle Leistungen & Pakete"}
                                onClick={() => onClickDetails("services")}
                                fullWidth={isMobile}
                            />
                        </FeatureButtonWrapper>
                    </div>
                </CSSTransition>
            </FeatureSection>

            {/* App Section */}
            <AppSection ref={appRef}>
                <CSSTransition in={appVisible} timeout={800} classNames="fade" unmountOnExit>
                    {isMobile ? (
                        <>
                            <SectionBadge>Managd App</SectionBadge>
                            <SectionTitle>Unsere Digitale Plattform</SectionTitle>
                            <SectionSubtitle>
                                Eine Plattform, die entwickelt wurde, um die komplexen Anforderungen
                                anspruchsvoller Investoren zu erfüllen.
                            </SectionSubtitle>

                            <div {...swipeHandlers}>
                                <AppSectionBackground>
                                    <AppCardsWrapper activeIndex={activeIndex}>
                                        {APP_ITEMS.map((item) => (
                                            <AppCard key={item.id}>
                                                <AppScreen src={item.image} alt={item.title} draggable={false} />
                                                <AppOverlay>
                                                    <AppTitle>{item.title}</AppTitle>
                                                    <AppDescription>{item.description}</AppDescription>
                                                </AppOverlay>
                                            </AppCard>
                                        ))}
                                    </AppCardsWrapper>
                                </AppSectionBackground>
                            </div>

                            <IndicatorWrapper>
                                {APP_ITEMS.map((_, index) => (
                                    <IndicatorDot
                                        key={index}
                                        isActive={index === activeIndex}
                                        onClick={() => setActiveIndex(index)}
                                    />
                                ))}
                            </IndicatorWrapper>
                        </>
                    ) : (
                        <Row className="gx-2" style={{ marginBottom: 32 }}>
                            <Col md={5}>
                                <SectionBadge>Managd App</SectionBadge>
                                <SectionTitle>Unsere Digitale Plattform</SectionTitle>
                                <SectionSubtitle>
                                    Eine Plattform, die entwickelt wurde, um die komplexen Anforderungen
                                    anspruchsvoller Investoren zu erfüllen.
                                </SectionSubtitle>

                                <ClickableList>
                                    {APP_ITEMS.map((item, index) => (
                                        <ClickableListItem
                                            key={item.id}
                                            isActive={index === activeIndex}
                                            onClick={() => setActiveIndex(index)}
                                        >
                                            <ItemTitle>{item.title}</ItemTitle>
                                            {index === activeIndex && (
                                                <ItemDescription>{item.description}</ItemDescription>
                                            )}
                                        </ClickableListItem>
                                    ))}
                                </ClickableList>

                                <AppButtonWrapper>
                                    <CTAButton
                                        variant="primary"
                                        size="L"
                                        label="Alle Leistungen"
                                        onClick={() => onClickDetails("services")}
                                        fullWidth
                                    />
                                </AppButtonWrapper>
                            </Col>
                            <Col md={7} className="text-center">
                                <AppScreenBackground>
                                    <AppScreen
                                        src={APP_ITEMS[activeIndex].image}
                                        alt={APP_ITEMS[activeIndex].title}
                                        draggable={false}
                                    />
                                </AppScreenBackground>
                            </Col>
                        </Row>
                    )}
                </CSSTransition>
            </AppSection>

            {/* Stats Section */}
            <StatsSection ref={statsRef}>
                <CSSTransition in={statsVisible} timeout={800} classNames="slide" unmountOnExit>
                    <>
                        <Row className="align-items-center">
                            <Col xs={12} md={6}>
                                <SectionBadge>Über Uns</SectionBadge>
                                <StatsTitle>Managd in Zahlen</StatsTitle>
                                <StatsSubtitle>
                                    Als PropTech-Unternehmen für anspruchsvolle Investoren kombinieren wir
                                    modernste Technologie mit jahrzehntelanger Erfahrung im
                                    Immobilienmanagement. Unsere Mission: Ihr Portfolio zu maximieren –
                                    effizient, diskret und transparent.
                                </StatsSubtitle>
                                {!isMobile && (
                                    <StatsButtonWrapper>
                                        <CTAButton variant="primary" size="L" label="Über Uns" fullWidth />
                                    </StatsButtonWrapper>
                                )}
                            </Col>
                            <Col xs={12} md={6} className="text-end">
                                <Row>
                                    <Col xs={6}>
                                        <StatCard>
                                            <StatNumber>1000</StatNumber>
                                            <StatLabel>Verwaltete Wohnungen</StatLabel>
                                        </StatCard>
                                    </Col>
                                    <Col xs={6}>
                                        <StatCard>
                                            <StatNumber>$800M</StatNumber>
                                            <StatLabel>Verwaltetes Volumen</StatLabel>
                                        </StatCard>
                                    </Col>
                                    <Col xs={6}>
                                        <StatCard>
                                            <StatNumber>11%</StatNumber>
                                            <StatLabel>Durchschnittliche Rendite</StatLabel>
                                        </StatCard>
                                    </Col>
                                    <Col xs={6}>
                                        <StatCard>
                                            <StatNumber>???</StatNumber>
                                            <StatLabel>Verwaltete Wohnungen</StatLabel>
                                        </StatCard>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {isMobile && (
                            <StatsButtonWrapper>
                                <CTAButton variant="primary" size="L" label="Mehr über uns" fullWidth />
                            </StatsButtonWrapper>
                        )}
                    </>
                </CSSTransition>
            </StatsSection>
        </>
    );
};

export default HomePage;
