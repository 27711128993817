import React from "react";
import {
    CardContainer,
    Header,
    Title,
    Subtitle,
    FeaturesList,
    FeatureItem,
    FeatureIcon,
    FeatureTitle,
    FeatureDescription,
    ButtonsContainer,
    Divider,
} from "./finance-upselling-card.styles";
import MaterialIcon from "../../../components/material-icon";
import { colorPalette } from "../../../utils/theme";
import CTAButton from "../../../components/cta-button/cta-button";
import useIsMobile from "../../../hooks/useIsMobile";
import CoinImage from "../../../assets/images/cash_coin_color.png";

const FinanceUpsellingCard: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <CardContainer>
            <Header>
                <img src={CoinImage} width={96} alt="Cash Coin" />
                <Title>Detaillierte Finanz-Daten in den Managd-Paketen</Title>
                <Subtitle>Wenn du deine Einheit in einem Managd-Paket verwalten lässt, stellen wir dir hier alle relevanten Daten zur Verfügung.</Subtitle>
            </Header>
            <Divider />
            <FeaturesList>
                <FeatureItem>
                    <FeatureIcon>
                        <MaterialIcon icon="event_available" size={18} color={colorPalette.primaryDark} />
                    </FeatureIcon>
                    <div>
                        <FeatureTitle>Verfolge jederzeit den Status deiner Mietauszahlung</FeatureTitle>
                        <FeatureDescription>
                            Erfahre genau, wann die Mietchecks eingelöst werden und wann dein Geld auf dem Weg zu dir ist.
                        </FeatureDescription>
                    </div>
                </FeatureItem>
                <FeatureItem>
                    <FeatureIcon>
                        <MaterialIcon icon="receipt_long" size={18} color={colorPalette.primaryDark} />
                    </FeatureIcon>
                    <div>
                        <FeatureTitle>Exportiere wichtige Abrechnungen & Unterlagen</FeatureTitle>
                        <FeatureDescription>
                            Exportiere ganz einfach steuerrelevante Unterlagen wie Einnahmen & Ausgaben oder Transaktionen.
                        </FeatureDescription>
                    </div>
                </FeatureItem>
                <FeatureItem>
                    <FeatureIcon>
                        <MaterialIcon icon="send_money" size={18} color={colorPalette.primaryDark} />
                    </FeatureIcon>
                    <div>
                        <FeatureTitle>Behalte Einnahmen & Ausgaben im Blick</FeatureTitle>
                        <FeatureDescription>
                            Behalte den Überblick über alle Einnahmen und Ausgaben deines Investments.
                        </FeatureDescription>
                    </div>
                </FeatureItem>
                <FeatureItem>
                    <FeatureIcon>
                        <MaterialIcon icon="request_quote" size={18} color={colorPalette.primaryDark} />
                    </FeatureIcon>
                    <div>
                        <FeatureTitle>Alle Transaktionen und Rechnungsverlauf</FeatureTitle>
                        <FeatureDescription>
                            Ein Ort, an dem alle Transaktionen und Rechnungen deines Investments zugänglich sind.
                        </FeatureDescription>
                    </div>
                </FeatureItem>
            </FeaturesList>
            <Divider />
            <ButtonsContainer>
                <CTAButton
                    variant="primary"
                    size="L"
                    label="Paket Auswählen"
                    fullWidth
                />
                <CTAButton
                    variant="secondary"
                    size="L"
                    label="Weitere Informationen"
                    fullWidth
                />
            </ButtonsContainer>
        </CardContainer>
    );
};

export default FinanceUpsellingCard;
