import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const StyledContainer = styled.div`
  width: 100%;
  padding-bottom: 32px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const Title = styled.h4`
  margin: 0;
  padding: 16px 12px;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingLMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const AnimatedSection = styled.div`
  &.fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  &.fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const PageWrapper = styled.div`
  &.fade-slide-enter {
    opacity: 0;
    transform: translateY(30px);
  }
  &.fade-slide-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  &.fade-slide-exit {
    opacity: 1;
    transform: translateY(0);
  }
  &.fade-slide-exit-active {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 500ms, transform 500ms;
  }
`;
