import React, { useState } from 'react';
import {
    Container,
    CustomTable,
    Headline,
    Card,
} from './document-table.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import Tag from '../../../../components/tag/tag';
import { DocumentType, formatDocumentType, IDocument } from '../../../../services/documentService';
import { downloadUnitDocument } from '../../../../services/unitService';
import { downloadLeaseDocument } from '../../../../services/leaseService';
import { Spinner } from 'react-bootstrap';

export interface IDocumentTableItem {
    document: IDocument;
    unit: string;
    date: string;
    status: 'active' | 'inactive' | 'signed' | 'waiting_for_signature';
}

interface DocumentTableProps {
    className?: string | undefined;
    headline: string;
    documents: IDocumentTableItem[];
    onClickDocument: (document: IDocument) => void;
}

const DocumentTable: React.FC<DocumentTableProps> = ({ className, headline, documents, onClickDocument }) => {
    // State to track which documents are downloading
    const [downloadingDocs, setDownloadingDocs] = useState<number[]>([]);

    // Functions
    const getStatusColor = (status: 'active' | 'inactive' | 'signed' | 'waiting_for_signature') => {
        if (status === "active") return colorPalette.successMedium;
        else if (status === "inactive") return colorPalette.blue300;
        else if (status === "waiting_for_signature") return colorPalette.warningMedium;
        else if (status === "signed") return colorPalette.textMain;
        return colorPalette.textDark;
    };

    const getStatusName = (status: 'active' | 'inactive' | 'signed' | 'waiting_for_signature') => {
        if (status === "active") return "Aktiv";
        else if (status === "inactive") return "Ausgelaufen";
        else if (status === "waiting_for_signature") return "Warten auf Unterschrift";
        else if (status === "signed") return "Unterschrieben";
        return "Unbekannt";
    };

    const getStatusTagName = (status: 'active' | 'inactive' | 'signed' | 'waiting_for_signature') => {
        if (status === "active") return "rented";
        else if (status === "inactive") return "completed";
        else if (status === "waiting_for_signature") return "inProgress";
        else if (status === "signed") return "handover";
        return "rented";
    };

    const onClickDownload = async (doc: IDocument) => {
        setDownloadingDocs((prev) => [...prev, doc.document_id]);

        try {
            let blob: Blob | null = null;
            if (doc.document_type === DocumentType.PURCHASE_CONTRACT && doc.unit) {
                blob = await downloadUnitDocument(doc.unit.unit_id, doc.document_id);
            }
            else if (doc.document_type === DocumentType.TENANCY_CONTRACT && doc.lease) {
                blob = await downloadLeaseDocument(doc.lease.lease_id, doc.document_id);
            }

            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = blobUrl;
                anchor.download = doc.file_name;
                document.body.appendChild(anchor);
                anchor.click();
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(anchor);
            }
        } catch (error) {
            console.log("error while downloading document: ", error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setDownloadingDocs((prev) => prev.filter(id => id !== doc.document_id));
        }
    };

    return (
        <Container className={className}>
            <Headline>{headline}</Headline>
            <Card>
                <CustomTable>
                    <thead>
                        <tr>
                            <th>Einheit</th>
                            <th>Typ</th>
                            <th>Zeitraum</th>
                            <th>Status</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map((doc, index) => (
                            <tr key={index} onClick={() => onClickDocument(doc.document)}>
                                <td>
                                    <div className="d-flex justify-items-center" style={{ gap: 8 }}>
                                        {doc.document.document_type === DocumentType.PROPERTY_MANAGEMENT_CONTRACT ? (
                                            <MaterialIcon icon="task" size={20} color={getStatusColor(doc.status)} />
                                        ) : doc.document.document_type === DocumentType.TENANCY_CONTRACT ? (
                                            <MaterialIcon icon="contact_page" size={20} color={getStatusColor(doc.status)} />
                                        ) : (
                                            <MaterialIcon icon="description" size={20} color={getStatusColor(doc.status)} />
                                        )}
                                        {doc.unit}
                                    </div>
                                </td>
                                <td>{formatDocumentType(doc.document.document_type ?? null)}</td>
                                <td>{doc.date}</td>
                                <td>
                                    <Tag
                                        type="unitStatus"
                                        variant={getStatusTagName(doc.status)}
                                        size="S"
                                        label={getStatusName(doc.status)}
                                    />
                                </td>
                                <td style={{ position: 'relative' }}>
                                    {downloadingDocs.includes(doc.document.document_id) ? (
                                        <Spinner animation="border" size="sm" />
                                    ) : (
                                        <MaterialIcon icon="file_save" size={24} color={colorPalette.blue500} onClick={(e) => {
                                            e.stopPropagation();
                                            onClickDownload(doc.document);
                                        }} />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </CustomTable>
            </Card>
        </Container>
    );
};

export default DocumentTable;
