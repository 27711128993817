import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 32px;
`;

export const Header = styled.div`
  padding: 12px 16px;
  gap: 16px;

  @media (max-width: 767.98px) {
    padding: 24px 12px 4px;
    background-color: transparent;
  }
`;

export const HeaderTitle = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingLMain}

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const HeaderSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
`;

export const HeaderSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS}
  }
`;

export const HeaderTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;
