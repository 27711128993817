import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const CardWrapper = styled.div`
  width: 100%;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 4px;
  padding: 16px;
  gap: 32px;
  text-align: center;
`;

export const BadgeWrapper = styled.div`
  width: 100%;
  justify-items: end;
`;

export const Badge = styled.div`
  display: flex;
  height: 30px;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 4px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const BadgeText = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS}
`;

export const Icon = styled.img`
  width: 120px;
  height: auto;
  margin-bottom: 32px;

  @media (max-width: 767.98px) {
    width: 80px;
    margin-bottom: 16px;
  }
`;

export const Title = styled.h4`
  width: 100%;
  margin-bottom: 8px;
  text-align: start;
  ${colorPalette.textMain}
  ${typeScale.headingXSMain};
`;

export const Description = styled.p`
  width: 100%;
  margin: 0;
  text-align: start;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};
`;
