import React, { useCallback, useState } from 'react';
import {
    DocumentsContainer,
    DocumentList,
    Title,
    HeaderContainer,
} from './document-section.styles';
import DocumentCard from '../document-card/document-card';
import { IDocument } from '../../services/documentService';
import useIsMobile from '../../hooks/useIsMobile';
import { downloadLeaseDocument } from '../../services/leaseService';
import useAlertStore from '../../stores/alertStore';
import { downloadUnitDocument } from '../../services/unitService';

interface DocumentSectionProps {
    documents: IDocument[];
    unitId?: number;
    leaseId?: number;
    showCategory?: boolean;
    showHeadline?: boolean;
}

const DocumentSection: React.FC<DocumentSectionProps> = ({ documents, unitId, leaseId, showCategory, showHeadline = true }) => {
    const isMobile = useIsMobile();

    // Stores
    const showAlert = useAlertStore((state) => state.showAlert);

    // States
    const [downloadingDocs, setDownloadingDocs] = useState<{ [key: number]: boolean }>({});

    // Functions
    const onHandleDownload = useCallback(
        (document: IDocument) => {
            if (unitId) {
                onDownloadUnitDocument(unitId, document);
            } else if (leaseId) {
                onDownloadLeaseDocument(leaseId, document);
            }
        },
        [unitId, leaseId]
    );

    const onDownloadUnitDocument = async (unitId: number, unitDocument: IDocument) => {
        try {
            setDownloadingDocs((prev) => ({ ...prev, [unitDocument.document_id]: true }));

            const blob: Blob | null = await downloadUnitDocument(Number(unitId), unitDocument.document_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);

                const isViewable = /image|pdf/.test(blob.type);

                if (isViewable && !/Mobi/i.test(navigator.userAgent)) {
                    window.open(blobUrl, '_blank');
                } else {
                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = unitDocument.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            }
        } catch (error) {
            console.log('error while downloading unit document: ', error);
            // @ts-ignore
            showAlert('error', error.message);
        } finally {
            setDownloadingDocs((prev) => ({ ...prev, [unitDocument.document_id]: false }));
        }
    };

    const onDownloadLeaseDocument = async (leaseId: number, leaseDocument: IDocument) => {
        try {
            setDownloadingDocs((prev) => ({ ...prev, [leaseDocument.document_id]: true }));

            const blob: Blob | null = await downloadLeaseDocument(leaseId, leaseDocument.document_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);

                const isViewable = /image|pdf/.test(blob.type);

                if (isViewable && !/Mobi/i.test(navigator.userAgent)) {
                    window.open(blobUrl, '_blank');
                } else {
                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = leaseDocument.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            }
        } catch (error) {
            console.log('error while downloading lease document: ', error);
            // @ts-ignore
            showAlert('error', error.message);
        } finally {
            setDownloadingDocs((prev) => ({ ...prev, [leaseDocument.document_id]: false }));
        }
    };

    return (
        <div>
            {showHeadline && (
                <HeaderContainer>
                    <Title>{isMobile ? 'Dokumente' : 'Unit-Dokumente'}</Title>
                </HeaderContainer>
            )}
            <DocumentsContainer>
                <DocumentList>
                    {documents.map((document) => (
                        <DocumentCard
                            key={document.document_id}
                            document={document}
                            showCategory={showCategory}
                            isDownloading={!!downloadingDocs[document.document_id]}
                            onDownload={onHandleDownload}
                        />
                    ))}
                </DocumentList>
            </DocumentsContainer>
        </div>
    );
};

export default DocumentSection;
