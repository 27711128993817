import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const CardWrapper = styled.div`
  height: 100%;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  padding: 18px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 767.98px) {
    border: none;
    border-radius: 0;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 12px;
    gap: 12px;
  }
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  padding: 2px;

  @media (max-width: 767.98px) {
    width: 18px;
    height: 18px;
  }
`;

export const Title = styled.h4`
  margin: 10px 0 10px 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};

  @media (max-width: 767.98px) {
    margin: 0;
    font-size: 16px; /* Adjust size for mobile */
  }
`;

export const Contact = styled.a`
  margin-bottom: 4px;
  text-decoration: none;
  color: ${colorPalette.blue500};
  ${typeScale.bodySMMain};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767.98px) {
    margin-bottom: 0; /* Remove margin for mobile */
    font-size: 14px; /* Adjust size for mobile */
  }
`;

export const Time = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};

  @media (max-width: 767.98px) {
    font-size: 12px; /* Adjust size for mobile */
  }
`;
