import React, { useRef, useEffect, useState } from 'react';
import {
    MainImage,
    GalleryContainer,
    SmallImage,
    SliderControl,
    CarouselIndicators,
    CarouselIndicator
} from './project-pictures.styles';
import { Col, Row } from 'react-bootstrap';
import useIsMobile from '../../../../hooks/useIsMobile';
import LazyLoadImage from '../../../../components/lazy-load-image/lazy-load-image';
import { Carousel } from 'bootstrap';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';

interface ProjectPicturesProps {
    images: string[];
    onClickPicture: () => void;
}

const ProjectPictures: React.FC<ProjectPicturesProps> = ({ images, onClickPicture }) => {
    const isMobile = useIsMobile();

    // References
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const carouselInstanceRef = useRef<Carousel | null>(null);

    // States
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (carouselRef.current) {
            carouselInstanceRef.current = new Carousel(carouselRef.current, {
                interval: false,
                ride: false,
            });

            // Listen to the carousel's slide event
            const handleSlide = () => {
                const activeElement = carouselRef.current?.querySelector('.carousel-item.active');
                if (activeElement) {
                    const carouselItems = Array.from(carouselRef.current!.querySelectorAll('.carousel-item'));
                    const newIndex = carouselItems.indexOf(activeElement);
                    setActiveIndex(newIndex);
                }
            };

            carouselRef.current.addEventListener('slid.bs.carousel', handleSlide);

            return () => {
                carouselRef.current?.removeEventListener('slid.bs.carousel', handleSlide);
                carouselInstanceRef.current?.dispose();
            };
        }
    }, []);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            .carousel-item {
                transition: transform 0.4s ease-in-out !important;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return isMobile ? (
        <div
            id="mobileCarousel"
            className="carousel slide"
            ref={carouselRef}
        >
            {images.length > 1 && (
                <>
                    {/* Indicators */}
                    <CarouselIndicators>
                        {images.map((_, index) => (
                            <CarouselIndicator
                                data-bs-target="#carouselExample"
                                data-bs-slide-to={index}
                                aria-label={`Slide ${index + 1}`}
                                className={activeIndex === index ? 'active' : ''}
                                key={`indicator-${index}`}
                            />
                        ))}
                    </CarouselIndicators>
                </>
            )}

            <div className="carousel-inner">
                {images.map((src, index) => (
                    <div
                        className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                        key={`image-${index}`}
                    >
                        <LazyLoadImage
                            src={src}
                            alt={`Unit Image ${index + 1}`}
                            width="100%"
                            height="350px"
                            onClick={onClickPicture}
                        />
                    </div>
                ))}
            </div>
            {images.length > 1 && (
                <>
                    {/* Previous and Next Controls */}
                    <SliderControl
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="prev"
                    >
                        <MaterialIcon icon="arrow_back" size={20} color={colorPalette.textMain} />
                    </SliderControl>
                    <SliderControl
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="next"
                    >
                        <MaterialIcon icon="arrow_forward" size={20} color={colorPalette.textMain} />
                    </SliderControl>
                </>
            )}
        </div>
    ) : (
        <GalleryContainer>
            <Row className="gy-3">
                <Col sm={12} md={6} className="h-100">
                    <LazyLoadImage
                        src={images[0]}
                        alt="Property Image 1"
                        width="100%"
                        height="395px"
                        onClick={onClickPicture}
                        borderRadius="4px"
                        objectPosition="top"
                    />
                </Col>

                <Col sm={12} md={6} className="h-100">
                    <Row className="gy-3">
                        {images.slice(1, 5).map((image, index) => (
                            <Col sm={6} key={index} className="h-100">
                                <LazyLoadImage
                                    src={image}
                                    alt={`Property Image ${index + 2}`}
                                    width="100%"
                                    height="190px"
                                    onClick={onClickPicture}
                                    borderRadius="4px"
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </GalleryContainer>
    );
};

export default ProjectPictures;
