import { useEffect, useState } from "react";
import NewsUpdates from "../../components/news-updates/news-updates";
import Articles from "./components/articles/articles";
import FAQCategories from "./components/faq-categories/faq-categories";
import { StyledContainer, Title, AnimatedSection } from "./knowledge-hub.styles";

const KnowledgeHubPage = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <StyledContainer>
            <Title>Knowledge Hub</Title>

            <AnimatedSection isMounted={isMounted} delay={100}>
                <Articles />
            </AnimatedSection>

            <AnimatedSection isMounted={isMounted} delay={300}>
                <FAQCategories />
            </AnimatedSection>

            <AnimatedSection isMounted={isMounted} delay={500}>
                <NewsUpdates />
            </AnimatedSection>
        </StyledContainer>
    );
};

export default KnowledgeHubPage;
