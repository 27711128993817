import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const ContactListContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  gap: 16px;
`;

// Header Title
export const HeaderTitle = styled.h2`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;
