import React, { useCallback, useEffect, useState } from 'react';
import {
    SidebarModalBackdrop,
    SidebarModal,
    SidebarModalHeadline,
    SidebarModalDivider,
    SidebarModalScrollableContent,
    SidebarModalSubtitle,
    HeadlineContainer,
    SidebarModalHeader
} from '../sidemodal.styles';
import MaterialIcon from '../../../components/material-icon';
import { colorPalette } from '../../../utils/theme';
import { formatDocumentType, IDocument } from '../../../services/documentService';
import { Item, ItemTitle, ItemSubtitle, CardContainer } from './unit-documents-sidemodal.styles';
import { downloadUnitDocument } from '../../../services/unitService';
import useAlertStore from '../../../stores/alertStore';
import { Spinner } from 'react-bootstrap';
import { usePWA } from '../../../context/PWAContext';

interface UnitDocumentsSidebarModalProps {
    unit_id: number;
    unit_number: string;
    property_name: string;
    bedroom: number;
    square_feet: number;
    documents: IDocument[];
    isVisible: boolean;
    onClose: () => void;
}

const UnitDocumentsSidebarModal: React.FC<UnitDocumentsSidebarModalProps> = ({
    documents,
    unit_id,
    unit_number,
    property_name,
    bedroom,
    square_feet,
    isVisible,
    onClose
}) => {
    const isPWA = usePWA();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [downloadingStates, setDownloadingStates] = useState<{ [documentId: number]: boolean }>({});

    // Functions
    const onDownloadUnitDocument = useCallback(async (unitDocument: IDocument) => {
        if (unit_id) {
            try {
                setDownloadingStates((prev) => ({
                    ...prev,
                    [unitDocument.document_id]: true,
                }));

                const blob: Blob | null = await downloadUnitDocument(Number(unit_id), unitDocument.document_id);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);
                    const isViewable = /image|pdf/.test(blob.type);

                    if (isViewable && !/Mobi/i.test(navigator.userAgent)) {
                        window.open(blobUrl, '_blank');
                    } else {
                        const anchor = document.createElement('a');
                        anchor.href = blobUrl;
                        anchor.download = unitDocument.file_name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blobUrl);
                        document.body.removeChild(anchor);
                    }
                }
            } catch (error) {
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setDownloadingStates((prev) => ({
                    ...prev,
                    [unitDocument.document_id]: false,
                }));
            }
        }
    }, [unit_id, showAlert]);

    // Prevent scrolling down
    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';

            const preventDefault = (e: TouchEvent) => {
                e.preventDefault();
            };

            document.addEventListener('touchmove', preventDefault, { passive: false });

            return () => {
                document.body.style.overflow = 'unset';
                document.removeEventListener('touchmove', preventDefault);
            };
        }
    }, [isVisible]);

    return (
        <SidebarModalBackdrop
            visible={isVisible}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onClose();
                }
            }}
        >
            <SidebarModal visible={isVisible}>
                <SidebarModalHeader>
                    <HeadlineContainer>
                        <SidebarModalHeadline>Wohnungsdokumente</SidebarModalHeadline>
                        <SidebarModalSubtitle>
                            {unit_number} • {property_name} • {bedroom} Bedroom • {square_feet} Sqft.
                        </SidebarModalSubtitle>
                    </HeadlineContainer>
                    <MaterialIcon icon="close" size={24} color={colorPalette.blue500} onClick={onClose} />
                </SidebarModalHeader>
                <SidebarModalDivider />
                <SidebarModalScrollableContent isPWA={isPWA}>
                    <CardContainer>
                        {documents.map((document) => (
                            <Item
                                key={document.document_id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDownloadUnitDocument(document);
                                }}
                            >
                                <div className="d-flex align-items-center" style={{ gap: 8 }}>
                                    <MaterialIcon icon="description" size={20} color={colorPalette.successMedium} />
                                    <div>
                                        <ItemTitle>{formatDocumentType(document.document_type ?? null)}</ItemTitle>
                                        <ItemSubtitle>Ausgestellt</ItemSubtitle>
                                    </div>
                                </div>
                                {downloadingStates[document.document_id] ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ color: colorPalette.blue500 }}
                                    />
                                ) : (
                                    <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                                )}
                            </Item>
                        ))}
                    </CardContainer>
                </SidebarModalScrollableContent>
            </SidebarModal>
        </SidebarModalBackdrop>
    );
};

export default UnitDocumentsSidebarModal;
