import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Container,
    Title,
    CardContainer,
    ButtonWrapper,
} from './project-key-data.styles';
import { formatMoney } from '../../../../utils/helpers';
import DetailItem from '../../../../components/detail-item/detail-item';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';

interface ProjectKeyDataProps {
    className?: string | undefined;
    registrationNumber: string;
    buildingArea: number;
    landArea: number;
    landPrice: number;
}

const ProjectKeyData: React.FC<ProjectKeyDataProps> = ({
    className,
    registrationNumber,
    buildingArea,
    landArea,
    landPrice,
}) => {
    const isMobile = useIsMobile();

    // Functions
    const onClickAfa = () => {
        alert("Coming soon...");
    }

    return (
        <Container className={className}>
            <Title>Kerndaten</Title>
            <CardContainer>
                <Row>
                    <Col md={6}>
                        <DetailItem
                            icon="event_available"
                            label="Registrierungsnummer (DLD)"
                            value={registrationNumber.length === 0 ? "N/A" : registrationNumber}
                            removeHorizontalPadding
                        />
                    </Col>

                    <Col md={6}>
                        <DetailItem
                            icon="event_available"
                            label="Gesamtfläche Gebäude"
                            value={`${buildingArea} sqft.`}
                            removeHorizontalPadding
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <DetailItem
                            icon="event_available"
                            label="Gesamtfläche Baugrund"
                            value={`${landArea} sqft.`}
                            removeHorizontalPadding
                        />
                    </Col>

                    <Col md={6}>
                        <DetailItem
                            icon="event_available"
                            label="Kaufpreis Baugrund"
                            value={`AED ${formatMoney(landPrice)}`}
                            removeHorizontalPadding
                        />
                    </Col>

                    <Col md={6}>
                        <ButtonWrapper>
                            <CTAButton
                                variant="ghost"
                                size={isMobile ? "S" : "L"}
                                icon="arrow_outward"
                                label="Wie berechnest du die AfA?"
                                onClick={onClickAfa}
                                noPadding
                            />
                        </ButtonWrapper>
                    </Col>
                </Row>
            </CardContainer>
        </Container>
    );
};

export default ProjectKeyData;
