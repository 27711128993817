import React from "react";
import {
    BannerContainer,
    TextWrapper,
    Title,
    Description,
    Image,
} from "./banner.styles";
import LightBulb from "../../../../../../assets/images/light_bulb.png";

const Banner: React.FC = () => {
    return (
        <BannerContainer>
            <Image src={LightBulb} alt="Light Bulb" />
            <TextWrapper>
                <Title>Wichtige Information zur Versteuerung von Mieteinnahmen im Ausland</Title>
                <Description>Bitte beachte, dass deine Mieteinnahmen aus Immobilien in Dubai in deinem Wohnsitzland steuerpflichtig sein können.<br></br>Du bist verpflichtet, diese Einkünfte in deiner Steuererklärung anzugeben und entsprechend den gesetzlichen Vorgaben zu versteuern.</Description>
            </TextWrapper>
        </BannerContainer>
    );
};

export default Banner;
