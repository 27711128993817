import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  padding: 0 16px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const HeaderContainer = styled.div`
  margin-top: 48px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h2`
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    margin-bottom: 0;
    ${typeScale.headingSMMain};
  }
`;

export const CardContainer = styled.div`
  margin-top: 16px;
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  border-bottom: 1px solid ${colorPalette.blue200};
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }
`;

export const ItemTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
`;

export const ItemSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}
`;
