import React from "react";
import { CardWrapper, Badge, Icon, Title, Description, BadgeText, BadgeWrapper } from "./card.styles";
import LogoIconDark from "../../../../../../assets/icons/logo-icon-dark.png";

interface CardProps {
    badgeText: string;
    iconSrc: string;
    title: string;
    description: string;
}

const Card: React.FC<CardProps> = ({ badgeText, iconSrc, title, description }) => {
    return (
        <CardWrapper>
            <BadgeWrapper>
                <Badge>
                    <img src={LogoIconDark} width={11} height={10} alt="Mangd Icon" />
                    <BadgeText>{badgeText}</BadgeText>
                </Badge>
            </BadgeWrapper>
            <Icon src={iconSrc} alt="Icon" />
            <Title>{title}</Title>
            <Description>{description}</Description>
        </CardWrapper>
    );
};

export default Card;
