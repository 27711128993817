import React, { useState } from "react";
import {
    TableContainer,
    Table,
    TableHeader,
    TableRow,
    TableCell,
    TableBody,
    StatusBadge,
    Amount,
    CategoryTitle,
    CategorySubtitle
} from "./transaction-table.styles";
import MaterialIcon from "../../../../components/material-icon";
import { formatMoney } from "../../../../utils/helpers";
import { colorPalette } from "../../../../utils/theme";
import CurrencyConversionPopup from "../currency-conversion-popup/currency-conversion-popup";

export interface ITransaction {
    id: number;
    transactionType: string;
    property: string;
    date: string;
    status: string;
    amount: number;
    statusType: "open" | "received" | "due" | "paid";
}

interface TransactionTableProps {
    transactions: ITransaction[];
}

const TransactionTable: React.FC<TransactionTableProps> = ({ transactions }) => {
    const [hoveredElement, setHoveredElement] = useState<HTMLElement | null>(null);
    const [hoveredAmount, setHoveredAmount] = useState<number | null>(null);

    return (
        <TableContainer>
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Transaktion</TableHeader>
                        <TableHeader>Wohnung</TableHeader>
                        <TableHeader>Datum</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Betrag</TableHeader>
                        <TableHeader>Aktionen</TableHeader>
                    </TableRow>
                </thead>
                <TableBody>
                    {transactions.map((transaction) => (
                        <TableRow key={transaction.id} highlight={transaction.statusType === "due"}>
                            <TableCell>
                                <MaterialIcon icon="reset_wrench" size={24} />
                                <div>
                                    <CategoryTitle>{transaction.transactionType}</CategoryTitle>
                                    <CategorySubtitle>Beschreibung</CategorySubtitle>
                                </div>
                            </TableCell>
                            <TableCell>{transaction.property}</TableCell>
                            <TableCell>{new Date(transaction.date).toLocaleDateString()}</TableCell>
                            <TableCell>
                                <StatusBadge type={transaction.statusType}>{transaction.status}</StatusBadge>
                            </TableCell>
                            <TableCell
                                onMouseEnter={(e) => {
                                    setHoveredElement(e.currentTarget);
                                    setHoveredAmount(transaction.amount);
                                }}
                                onMouseLeave={() => {
                                    setHoveredElement(null);
                                    setHoveredAmount(null);
                                }}
                            >
                                <Amount
                                    style={{
                                        color: transaction.amount > 0 ? colorPalette.successMedium : colorPalette.textMain,
                                    }}
                                >
                                    AED {formatMoney(transaction.amount)}
                                </Amount>
                            </TableCell>
                            <TableCell>
                                <MaterialIcon icon="receipt_long" size={20} color={colorPalette.blue500} onClick={() => alert("Coming soon...")} />
                                <MaterialIcon icon="open_in_new" size={20} color={colorPalette.blue500} onClick={() => alert("Coming soon...")} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {hoveredElement && hoveredAmount !== null && (
                <CurrencyConversionPopup amount={hoveredAmount} anchorElement={hoveredElement} />
            )}
        </TableContainer>
    );
};

export default TransactionTable;
