import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container, Header, HeaderTitle } from './rented-units-section.styles';
import useIsMobile from '../../../../hooks/useIsMobile';
import { IUnit } from '../../../../services/unitService';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { getActiveLease } from '../../../../utils/helpers';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import UnitCard from './components/unit-card/unit-card';
import CTAButton from '../../../../components/cta-button/cta-button';
import MyUnitsCard from '../../../my-units-page/components/unit-card/unit-card';
import MobileUnitCard from './components/mobile-unit-card/mobile-unit-card';

interface RentedUnitsSectionProps {
    units: IUnit[];
}

const RentedUnitsSection: React.FC<RentedUnitsSectionProps> = ({ units }) => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // Functions
    const onClickUnit = (unitId: number) => {
        navigate(`/my-units/${unitId}`);
    };

    const onClickUnits = () => {
        navigate(`/my-units`);
    };

    return (
        <Container>
            <Header>
                <div className="d-flex align-items-center" style={{ gap: 4, cursor: "pointer" }} onClick={isMobile ? () => {
                    navigate("/my-units");
                } : undefined}>
                    <HeaderTitle>Einheiten in der Vermietung</HeaderTitle>
                    <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </div>
                {!isMobile && (
                    <CTAButton
                        variant="ghost"
                        size="S"
                        label="Alle Einheiten"
                        icon="arrow_outward"
                        onClick={onClickUnits}
                        noPadding
                    />
                )}
            </Header>
            <Row className={isMobile ? "gy-2" : "gy-3"}>
                {units.map((unitItem) => (
                    isMobile ? (
                        <Col xs={12} md={6} xxl={4} key={unitItem.unit_id}>
                            <MobileUnitCard unit={unitItem} showFooterContent />
                        </Col>
                    ) : (
                        <React.Fragment key={unitItem.unit_id}>
                            {units.length === 1 ? (
                                <Col xs={12} key={unitItem.unit_id}>
                                    <MyUnitsCard
                                        imageSrc={unitItem.pictures.length > 0 ? unitItem.pictures[0].picture_path : ""}
                                        propertyName={unitItem.property.property_name}
                                        title={unitItem.unit_number}
                                        onClick={() => onClickUnit(unitItem.unit_id)}
                                        bedroom={unitItem.bedroom}
                                        bathroom={unitItem.bathroom}
                                        square_feet={unitItem.square_feet}
                                        purchasePrice={unitItem.owner?.purchase_price ?? 0}
                                        expectedAnnualRent={unitItem.minimum_annual_rent}
                                        rent={getActiveLease(unitItem.leases)?.annual_rent_amount ?? undefined}
                                        purpose_type={unitItem.purpose_type}
                                        constructionProgressItem={unitItem.property.construction_progress_items.length > 0 ? unitItem.property.construction_progress_items[0] : undefined}
                                        pmPackage={undefined}
                                        isMobile={isMobile}
                                    />
                                </Col>
                            ) : (
                                <Col xs={12} md={6} xxl={4} key={unitItem.unit_id}>
                                    <UnitCard
                                        imageSrc={unitItem.pictures.length > 0 ? unitItem.pictures[0].picture_path : ""}
                                        propertyName={unitItem.property.property_name}
                                        title={unitItem.unit_number}
                                        onClick={() => onClickUnit(unitItem.unit_id)}
                                        bedroom={unitItem.bedroom}
                                        square_feet={unitItem.square_feet}
                                        purchasePrice={unitItem.owner?.purchase_price ?? 0}
                                        rent={getActiveLease(unitItem.leases)?.annual_rent_amount ?? undefined}
                                        pmPackage={undefined}
                                    />
                                </Col>
                            )}
                        </React.Fragment>
                    )
                ))}
            </Row>
        </Container>
    );
};

export default RentedUnitsSection;
