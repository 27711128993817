import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const Container = styled.div`
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
`;

export const Header = styled.div`
  background-color: ${colorPalette.blue50};
  padding: 16px;
`;

export const Title = styled.div`
  margin-bottom: 8px;
  color: ${colorPalette.successMedium};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    margin-bottom: 4px;
    ${typeScale.headingSMMain};
  }
`;

export const Subtitle = styled.div`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;

export const ListItems = styled.div`
  max-height: 325px;
  overflow-y: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
`;
