import React, { useCallback } from 'react';
import {
    CardContainer,
    ImageContainer,
    ProjectImage,
    InfoContainer,
    ProjectTitle,
    ProjectLocation,
    ProjectDetails,
    HeaderContainer,
    Title,
    Container
} from './property-card.styles';
import { colorPalette } from '../../../../utils/theme';
import { IProperty } from '../../../../services/propertyService';
import MaterialIcon from '../../../../components/material-icon';
import CTAButton from '../../../../components/cta-button/cta-button';
import DetailItem from '../../../../components/detail-item/detail-item';
import { usePWA } from '../../../../context/PWAContext';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

interface PropertyCardProps {
    property: IProperty;
}

const PropertyCard: React.FC<PropertyCardProps> = ({ property }) => {
    const navigate = useCustomNavigate();
    const isPWA = usePWA();

    // Functions
    const handleViewDetailsClick = useCallback(() => {
        const url = `/project/${property.property_id}`;
        if (isPWA) {
            navigate(url);
        } else {
            window.open(url, '_blank');
        }
    }, [property, isPWA]);

    return (
        <Container>
            <HeaderContainer>
                <Title>Das Projekt</Title>
            </HeaderContainer>
            <CardContainer>
                <ImageContainer>
                    <ProjectImage src={property.property_image} alt={property.property_name} />
                </ImageContainer>
                <InfoContainer>
                    <ProjectTitle>{property.property_name}</ProjectTitle>
                    <ProjectLocation>
                        <MaterialIcon icon="location_on" size={16} color={colorPalette.textDark} />
                        {property.property_location}
                    </ProjectLocation>
                    <ProjectDetails>
                        <DetailItem icon="event_available" label="Registrierungsnummer (DLD)" value={property.registration_number ?? "-"} />
                        <DetailItem icon="event_available" label="Gesamtfläche Baugrund" value={`${property.plot_size ? `${property.plot_size} sqft.` : "-"}`} />
                        <DetailItem icon="event_available" label="Gesamtfläche Gebäude" value={`${property.total_build_up_area_size ? `${property.total_build_up_area_size} sqft.` : "-"}`} />
                    </ProjectDetails>
                    <CTAButton
                        variant="ghost"
                        size="L"
                        label="Projektdetails ansehen"
                        icon="arrow_outward"
                        onClick={handleViewDetailsClick}
                        noPadding
                    />
                </InfoContainer>
            </CardContainer>
        </Container>
    );
};

export default PropertyCard;
