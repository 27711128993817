import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";
import DGMALogo from "../../../../assets/images/dgma-logo.png";

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 32px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
  padding: 12px 16px;

  @media (max-width: 767.98px) {
    padding: 16px 16px;
    border-bottom: 1px solid ${colorPalette.blue200};
  }
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const ArticleTitle = styled.h1`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingLMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain}
  }
`;

export const ArticleSubtitle = styled.p`
  margin-bottom: 32px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyLG}

  @media (max-width: 767.98px) {
    ${typeScale.bodyMD}
  }
`;

export const SharingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid ${colorPalette.blue200};
  border-bottom: 1px solid ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
export const AuthorAvatar = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  background-image: url(${DGMALogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;

  @media (max-width: 767.98px) {
    width: 32px;
    height: 32px;
  }
`;

export const AuthorName = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXSMain}
  }
`;

export const AuthorRole = styled.p`
  margin-bottom: 4px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS}
  }
`;

export const BreadcrumbItem = styled.p`
  margin: 0;
  color: ${colorPalette.blue500};
  ${typeScale.bodySMMain}
`;

export const ArticleImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`;

export const ArticleImage = styled.img.withConfig({
  shouldForwardProp: (prop) => prop !== "showBorderRadius",
})<{ showBorderRadius: boolean }>`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: ${({ showBorderRadius }) => (showBorderRadius ? "8px" : "0")};
`;

export const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;

  @media (max-width: 767.98px) {
    width: 50px;
    height: 50px;
  }
`;

export const VideoLoadingContainer = styled.div`
  width: 100%;
  height: 350px;
  background-color: ${colorPalette.blue50};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  margin-bottom: 24px;

  @media (max-width: 767.98px) {
    height: 250px;
  }
`;

export const YouTubeFrame = styled.iframe.withConfig({
  shouldForwardProp: (prop) => prop !== "showBorderRadius",
})<{ showBorderRadius: boolean }>`
  width: 100%;
  height: 400px;
  border: none;
  border-radius: ${({ showBorderRadius }) => (showBorderRadius ? "8px" : "0")};
  margin-bottom: 24px;

  @media (max-width: 767.98px) {
    height: 250px;
  }
`;

export const ArticleDescription = styled.p`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMD}
`;

export const ArticleText = styled.p`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}

  strong {
    ${typeScale.bodySMMain}
  }
`;

export const Headline = styled.p`
  margin-bottom: 24px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}
`;

export const SubHeadline = styled.p`
  margin-top: 32px;
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
`;

export const BulletPointList = styled.ul`
  padding-left: 20px;
  margin-bottom: 24px;
  list-style-type: none;
`;

export const BulletPointItem = styled.li`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};

  &::before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

export const StyledLink = styled.a`
  color: ${colorPalette.blue500};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const ThreeDots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 48px;
  padding: 8px;

  &::before {
    content: "● ● ●";
    color: ${colorPalette.blue300};
    font-size: 16px;
    letter-spacing: 4px;
  }

  @media (max-width: 767.98px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid ${colorPalette.blue200};
`;

export const FilterContainer = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 24px;

  /* Hide scrollbar for all browsers */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767.98px) {
    margin-top: 24px;
    padding-bottom: 0;
  }
`;

export const BottomContainer = styled.div`
  padding-left: 54px;
  padding-right: 54px;

  @media (max-width: 767.98px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
