import React, { useRef } from "react";
import GoodToKnowArticles from "../../../../components/good-to-know-articles/good-to-know-articles";
import { Col, Row } from "react-bootstrap";
import EmptyState from "../../components/empty-state/empty-state";
import useIsMobile from "../../../../hooks/useIsMobile";
import CashCoin from "../../../../assets/images/cash_coin.svg";
import UmbrellaCoin from "../../../../assets/images/umbrella_coin.svg";
import IncomeAndExpenses from "./components/income-and-expenses/income-and-expenses";
import LastTransactions from "./components/last-transactions/last-transactions";
import NextRentPaymentCard from "./components/next-rent-payment-card/next-rent-payment-card";
import { ILeasePayment, PaymentMethod, PaymentPurpose } from "../../../../services/leaseService";
import AnnualProfitCard from "./components/annual-profit-card/annual-profit-card";
import { HorizontalScrollWrapper, IndicatorDot, IndicatorWrapper } from "./overview-page.styles";

const mockLeasePayment: ILeasePayment = {
    payment_id: 1,
    lease_id: 123,
    due_date: "2024-12-01", // ISO 8601 date format
    amount: 15000, // Mock amount in AED
    cheque_no: "123456789", // Example cheque number
    bank_name: "Emirates NBD", // Example bank name
    remark: "December rent", // Example remark
    method: PaymentMethod.CHEQUE, // Example payment method
    purpose: PaymentPurpose.RENT, // Example payment purpose
    bounced: false, // Payment has not bounced
    payment_date: null, // Payment not yet made
    document: null, // No document associated
    created_at: "2024-11-01T12:00:00Z", // ISO 8601 timestamp
    updated_at: "2024-11-15T12:00:00Z", // ISO 8601 timestamp
};

const OverviewPage: React.FC = () => {
    const isMobile = useIsMobile();

    // References
    const scrollWrapperRef = useRef<HTMLDivElement>(null);

    // States
    const [activeIndex, setActiveIndex] = React.useState(0);

    const handleScroll = () => {
        if (!scrollWrapperRef.current) return;

        const scrollLeft = scrollWrapperRef.current.scrollLeft;
        const itemWidth = scrollWrapperRef.current.scrollWidth / scrollWrapperRef.current.childElementCount;
        const newIndex = Math.round(scrollLeft / itemWidth);

        setActiveIndex(newIndex);
    };

    const scrollToCard = (index: number) => {
        if (!scrollWrapperRef.current) return;

        const itemWidth = scrollWrapperRef.current.scrollWidth / scrollWrapperRef.current.childElementCount;
        scrollWrapperRef.current.scrollTo({
            left: itemWidth * index,
            behavior: "smooth",
        });

        setActiveIndex(index);
    };

    return (
        <>
            {isMobile ? (
                <div>
                    <HorizontalScrollWrapper ref={scrollWrapperRef} onScroll={handleScroll}>
                        <NextRentPaymentCard
                            payment={mockLeasePayment}
                            onViewAll={() => console.log('View all transactions')}
                        />
                        <AnnualProfitCard
                            payment={mockLeasePayment}
                            onViewAll={() => console.log('View all transactions')}
                        />
                    </HorizontalScrollWrapper>

                    {/* Indicators */}
                    <IndicatorWrapper>
                        {[...Array(2)].map((_, index) => (
                            <IndicatorDot
                                key={index}
                                isActive={activeIndex === index}
                                onClick={() => scrollToCard(index)}
                            />
                        ))}
                    </IndicatorWrapper>
                </div>
            ) : (
                <Row style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <Col xs={12} lg={6}>
                        <>
                            <EmptyState
                                imageSrc={CashCoin}
                                imageWidth="130px"
                                title="Nächste Mietauszahlung"
                                description="Sobald deine erste Mietzahlung bereit ist, an dich ausgezahlt zu werden, findest du alle Details hier."
                            />
                            {/*<NextRentPaymentCard
                                payment={mockLeasePayment}
                                onViewAll={() => console.log('View all transactions')}
                            />*/}
                        </>
                    </Col>
                    <Col xs={12} lg={6} style={{ marginTop: isMobile ? 14 : 0 }}>
                        <>
                            <EmptyState
                                imageSrc={UmbrellaCoin}
                                imageWidth="116px"
                                title="Jahresgewinn"
                                description="Sobald deine Wohnungen vermietet sind, findest du alle Einnahmen und Ausgaben hier."
                            />
                            {/*<AnnualProfitCard
                                payment={mockLeasePayment}
                                onViewAll={() => console.log('View all transactions')}
                            />*/}
                        </>
                    </Col>
                </Row >
            )}

            {/*
            TODO: SHOW ONLY IF THERE ARE SOME TRANSACTIONS AVAILABLE
                   <IncomeAndExpenses />
            */}

            <LastTransactions />

            <GoodToKnowArticles />
        </>
    );
};

export default OverviewPage;
