import React from 'react';
import {
    ModalContainer,
    Header,
    HeaderTitle,
    CloseButton,
    ContentContainer,
    Overlay,
} from './edit-contact-details-modal.styles';
import MaterialIcon from '../../components/material-icon';
import { colorPalette } from '../../utils/theme';
import countries from 'i18n-iso-countries';
import deLocale from 'i18n-iso-countries/langs/de.json';
import { usePWA } from '../../context/PWAContext';
import useIsMobile from '../../hooks/useIsMobile';
import PersonalDataForm from '../../components/personal-data-form/personal-data-form';
countries.registerLocale(deLocale);

interface EditContactDetailsModalProps {
    onClose: () => void;
}

const EditContactDetailsModal: React.FC<EditContactDetailsModalProps> = ({
    onClose,
}) => {
    const isPWA = usePWA();
    const isMobile = useIsMobile();

    return (
        <Overlay onClick={onClose}>
            <ModalContainer onClick={(e) => e.stopPropagation()}>
                <Header>
                    <HeaderTitle>Persönliche Daten ändern</HeaderTitle>
                    <CloseButton onClick={onClose}>
                        <MaterialIcon icon="close" size={24} color={colorPalette.blue500} />
                    </CloseButton>
                </Header>
                <ContentContainer className="modal-content" isPWA={isPWA && isMobile}>
                    <PersonalDataForm onDataStored={onClose} onCancel={onClose} isStickyButtons />
                </ContentContainer>
            </ModalContainer>
        </Overlay>
    );
};

export default EditContactDetailsModal;
