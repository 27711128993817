import React, { useState } from "react";
import { FAQWrapper, FAQItemWrapper, FAQQuestion, PlusIcon, FAQAnswer } from "./faq.styles";
import MaterialIcon from "../../../../../../components/material-icon";
import { colorPalette } from "../../../../../../utils/theme";

interface FAQItem {
    question: string;
    answer: string;
}

const faqData: FAQItem[] = [
    { question: "Frage 1", answer: "Antwort auf Frage 1" },
    { question: "Frage 2", answer: "Antwort auf Frage 2" },
    { question: "Frage 3", answer: "Antwort auf Frage 3" },
    { question: "Frage 4", answer: "Antwort auf Frage 4" },
    { question: "Frage 5", answer: "Antwort auf Frage 5" },
];

const FAQ: React.FC = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleFAQ = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <FAQWrapper>
            {faqData.map((item, index) => (
                <FAQItemWrapper key={index}>
                    <FAQQuestion onClick={() => toggleFAQ(index)}>
                        {item.question}
                        <PlusIcon icon="add" size={20} color={colorPalette.blue500} isOpen={openIndex === index} />
                    </FAQQuestion>
                    <FAQAnswer isVisible={openIndex === index}>{item.answer}</FAQAnswer>
                </FAQItemWrapper>
            ))}
        </FAQWrapper>
    );
};

export default FAQ;
