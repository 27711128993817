import React from "react";
import { CardWrapper, Contact, Icon, Time, Title } from "./contact-card.styles";
import { colorPalette } from "../../utils/theme";
import MaterialIcon from "../material-icon";
import WhatsappIcon from "../../assets/icons/whatsapp.svg";
import useIsMobile from "../../hooks/useIsMobile";

interface ContactCardProps {
    type: "phone" | "email" | "whatsapp";
}

const ContactCard: React.FC<ContactCardProps> = ({ type }) => {
    const isMobile= useIsMobile();

    const renderIcon = () => {
        switch (type) {
            case "phone":
                return <MaterialIcon icon="call" size={isMobile ? 18 : 32} color={colorPalette.primaryDark} />;
            case "email":
                return <MaterialIcon icon="mark_as_unread" size={isMobile ? 18 : 32} color={colorPalette.primaryDark} />;
            case "whatsapp":
                return <Icon src={WhatsappIcon} alt="WhatsApp Icon" />;
            default:
                return null;
        }
    };

    const renderTitle = () => {
        switch (type) {
            case "phone":
                return "Ruf uns an";
            case "email":
                return "Schreib uns";
            case "whatsapp":
                return "WhatsApp";
            default:
                return null;
        }
    };

    const renderContact = () => {
        switch (type) {
            case "phone":
                return "+971 123 456 78";
            case "email":
                return "support@getmanagd.com";
            case "whatsapp":
                return "+971 123 456 78";
            default:
                return null;
        }
    };

    const renderTime = () => {
        switch (type) {
            case "phone":
                return "Mo.-Fr. 08.00 - 17.00 (GST)";
            case "email":
                return null;
            case "whatsapp":
                return "Mo.-Fr. 08.00 - 17.00 (GST)";
            default:
                return null;
        }
    };

    return (
        <CardWrapper>
            {renderIcon()}
            <Title>{renderTitle()}</Title>
            <Contact href={type === "email" ? `mailto:${renderContact()}` : `tel:${renderContact()}`}>
                {renderContact()}
            </Contact>
            {type !== "email" && (
                <Time>{renderTime()}</Time>
            )}
        </CardWrapper>
    );
};

export default ContactCard;
