import React, { useMemo, useState } from 'react';
import {
    CardContainer,
    Header,
    ProgressBar,
    ProgressStatus,
    ProgressPercentage,
    ProgressDetails,
    DetailRow,
    DetailLabel,
    DetailValue,
    ProgressContainer,
} from './construction-progress-card.styles';
import ConstructionProgressModal from '../../modals/construction-progress-modal/construction-progress-modal';
import { downloadConstructionProgressDocument, IConstructionProgressItem } from '../../services/constructionProgressService';
import { IDocument } from '../../services/documentService';
import useIsMobile from '../../hooks/useIsMobile';
import CTAButton from '../cta-button/cta-button';

interface ConstructionProgressCardProps {
    constructionProgressItems: IConstructionProgressItem[];
    property_name: string;
    showReportDownload: boolean;
}

const ConstructionProgressCard: React.FC<ConstructionProgressCardProps> = ({
    constructionProgressItems,
    property_name,
    showReportDownload,
}) => {
    const isMobile = useIsMobile();

    // States
    const [isConstructionProgressModalVisible, setIsConstructionProgressModalVisible] = useState(false);

    const lastItem: IConstructionProgressItem = useMemo(() => {
        return constructionProgressItems[constructionProgressItems.length - 1];
    }, [constructionProgressItems]);

    const onDownloadDLDReport = async (item_id: number, dldReport: IDocument) => {
        try {
            const blob: Blob | null = await downloadConstructionProgressDocument(item_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);
    
                const isViewable = /image|pdf/.test(blob.type);
    
                if (isViewable && !/Mobi/i.test(navigator.userAgent)) {
                    window.open(blobUrl, '_blank');
                } else {
                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = dldReport.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();
    
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            }
        } catch (error) {
            console.log("error while downloading dld report: ", error);
        }
    };

    return (
        <>
            <CardContainer>
                <Header>
                    Baufortschritt
                    <CTAButton
                        variant="ghost"
                        size="S"
                        label={isMobile ? "Details" : "Baufortschritt ansehen"}
                        icon="arrow_outward"
                        onClick={() => setIsConstructionProgressModalVisible(true)} noPadding
                    />
                </Header>
                <ProgressContainer>
                    <ProgressBar>
                        <div className="progress" style={{ width: `${lastItem.percentage}%` }} />
                    </ProgressBar>
                    <ProgressDetails>
                        <ProgressPercentage>{Math.round(lastItem.percentage)}%</ProgressPercentage>
                        <ProgressStatus>{lastItem.description}</ProgressStatus>
                    </ProgressDetails>
                </ProgressContainer>
                {!isMobile && (
                    <>
                        <DetailRow>
                            <DetailLabel>Geplante Fertigstellung</DetailLabel>
                            <DetailValue>{new Date(lastItem.published_date).toLocaleDateString()}</DetailValue>
                        </DetailRow>
                        {showReportDownload && (
                            <DetailRow>
                                <DetailLabel>DLD Inspection Report</DetailLabel>
                                <CTAButton
                                    variant="ghost"
                                    size="S"
                                    label="Report ansehen"
                                    icon="open_in_new"
                                    onClick={() => {
                                        onDownloadDLDReport(lastItem.id, lastItem.document!);
                                    }}
                                    noPadding
                                />
                            </DetailRow>
                        )}
                    </>
                )}
            </CardContainer>

            {isConstructionProgressModalVisible && (
                <ConstructionProgressModal
                    property_name={property_name}
                    completion_date={lastItem.published_date}
                    constructionProgressItems={constructionProgressItems.sort((b, a) => new Date(a.published_date).getTime() - new Date(b.published_date).getTime())}
                    onClose={() => setIsConstructionProgressModalVisible(false)}
                    onDownloadDLDReport={onDownloadDLDReport}
                />
            )}
        </>
    );
};

export default ConstructionProgressCard;
