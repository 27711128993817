import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";
import { Col } from "react-bootstrap";

export const CategoryTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}
`;

export const ButtonContainer = styled.div<{ isSticky: boolean }>`
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 16px;
  border-top: 1px solid ${colorPalette.blue100};

  ${({ isSticky }) =>
    isSticky &&
    `
    position: sticky;
    bottom: 0;
    background-color: ${colorPalette.white};
    z-index: 10;
  `}

  @media (max-width: 767.98px) {
    display: block;
  }
`;

export const ButtonCol = styled(Col)`
  @media (max-width: 767.98px) {
    &:first-child {
      margin-bottom: 16px;
    }
  }
`;
