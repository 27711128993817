import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const StyledContainer = styled.div`
  width: 100%;
  padding-bottom: 32px;
  overflow-x: hidden;

  @media (max-width: 992px) {
    padding: 16px 12px;
  }
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingLMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }

  @media (min-width: 768px) {
    padding: 16px 12px;
  }
`;
