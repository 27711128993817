import React, { useCallback, useState } from 'react';
import {
    LeaseCardContainer,
    LeaseInfo,
    LeaseTitle,
    LeaseSubtitle,
    LeaseAmountContainer,
    LeaseAmount,
    LeasePercentageChange,
    LeaseContractLink,
    ArrowIconContainer,
    IconWrapper,
    Container,
    Headline,
    LeaseDetailsContainer,
    LeaseContractContainer,
    LeaseContractText,
} from './lease-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';
import { downloadLeaseDocument } from '../../../../services/leaseService';
import { IDocument } from '../../../../services/documentService';
import { Spinner } from 'react-bootstrap';

interface LeaseCardProps {
    lease_id: number;
    lease_document?: IDocument;
    name: string;
    startDate: string;
    endDate: string;
    rentAmount: number;
    rentChangePercentage?: number; // +6% or -3%, etc.
}

const LeaseCard: React.FC<LeaseCardProps> = ({
    lease_id,
    lease_document,
    name,
    startDate,
    endDate,
    rentAmount,
    rentChangePercentage,
}) => {
    // State to track download status
    const [isDownloading, setIsDownloading] = useState(false);

    const onDownloadLeaseDocument = useCallback(async () => {
        if (lease_id && lease_document && !isDownloading) {
            try {
                setIsDownloading(true);

                const blob: Blob | null = await downloadLeaseDocument(lease_id, lease_document.document_id);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);
                    const isViewable = /image|pdf/.test(blob.type);

                    if (isViewable && !/Mobi/i.test(navigator.userAgent)) {
                        window.open(blobUrl, '_blank');
                    } else {
                        const anchor = document.createElement('a');
                        anchor.href = blobUrl;
                        anchor.download = lease_document.file_name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blobUrl);
                        document.body.removeChild(anchor);
                    }
                }
            } catch (error) {
                console.error("Error while downloading lease document: ", error);
            } finally {
                setIsDownloading(false);
            }
        }
    }, [lease_id, lease_document, isDownloading]);

    return (
        <Container>
            <Headline>{`${new Date(startDate).getFullYear()} - ${new Date(endDate).getFullYear()}`}</Headline>
            <LeaseCardContainer>
                <LeaseDetailsContainer>
                    <LeaseInfo>
                        <IconWrapper>
                            <MaterialIcon icon="output" size={24} color={colorPalette.textDark} />
                        </IconWrapper>
                        <div>
                            <LeaseTitle>{name}</LeaseTitle>
                            <LeaseSubtitle>{`${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`}</LeaseSubtitle>
                        </div>
                    </LeaseInfo>
                    <LeaseAmountContainer>
                        <LeaseAmount>AED {formatMoney(rentAmount, false)}</LeaseAmount>
                        {rentChangePercentage && (
                            <LeasePercentageChange>{rentChangePercentage}</LeasePercentageChange>
                        )}
                    </LeaseAmountContainer>
                </LeaseDetailsContainer>
                {lease_document && (
                    <LeaseContractContainer onClick={isDownloading ? undefined : (e) => {
                        e.stopPropagation();
                        if (!isDownloading) {
                            onDownloadLeaseDocument();
                        }
                    }}>
                        <LeaseContractLink>
                            <MaterialIcon icon="contact_page" size={24} color={colorPalette.textMain} />
                            <LeaseContractText>Mietvertrag</LeaseContractText>
                        </LeaseContractLink>
                        <ArrowIconContainer>
                            {isDownloading ? (
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{ color: colorPalette.blue500 }} />
                            ) : (
                                <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                            )}
                        </ArrowIconContainer>
                    </LeaseContractContainer>
                )}
            </LeaseCardContainer>
        </Container>
    );
};

export default LeaseCard;
