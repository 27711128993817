import { useCallback, useEffect, useState } from "react";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import {
    LoadingContainer,
    Container,
    HeaderContainer,
    HeaderSubtitle,
    HeaderSubtitleContainer,
    HeaderTagsContainer,
    HeaderTitle,
} from './project-page.styles';
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";
import PropertyUpdates from "../../components/property-updates/property-updates";
import { useParams } from "react-router-dom";
import ConstructionProgressCard from "../../components/construction-progress-card/construction-progress-card";
import PaymentPlanCard from "../../components/payment-plan-card/payment-plan-card";
import { fetchProperty, IProperty } from "../../services/propertyService";
import ProjectPictures from "./components/project-pictures/project-pictures";
import ProjectKeyData from "./components/project-key-data/project-key-data";
import OwnedUnits from "./components/owned-units/owned-units";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../components/material-icon";
import Tag from "../../components/tag/tag";
import { colorPalette } from "../../utils/theme";
import ImageModal from "../../modals/image-modal/image-modal";
import useIsMobile from "../../hooks/useIsMobile";
import { SPACES_ENDPOINT } from "../../utils/constants";

const ProjectPage = () => {
    const { propertyId } = useParams();

    const isMobile = useIsMobile();

    // States
    const [isLoading, setIsLoading] = useState(false);
    const [property, setProperty] = useState<IProperty | null>(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    //const [activeTab, setActiveTab] = useState<string>("");

    useEffect(() => {
        if (propertyId) {
            const loadProperty = async () => {
                try {
                    setIsLoading(true);

                    const property = await fetchProperty(Number(propertyId));
                    setProperty(property);
                } catch (error) {
                    console.log(`error while fetching property ID: ${propertyId}`, error);
                } finally {
                    setIsLoading(false);
                }
            };
            loadProperty();
        }
    }, [propertyId]);

    /*const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            setActiveTab(sectionId);
        }
    };*/

    const getImages = useCallback(() => {
        if (!property) return [];

        const images: string[] = [property.property_image];
        if (property.property_id === 2) {
            for (let index = 0; index < 7; index++) {
                images.push(`${SPACES_ENDPOINT}properties/${property.property_id}/${index + 1}.png`);
            }
        }
        else if (property.property_id === 3) {
            for (let index = 0; index < 7; index++) {
                images.push(`${SPACES_ENDPOINT}properties/${property.property_id}/${index + 1}.png`);
            }
        }
        else if (property.property_id === 4) {
            for (let index = 0; index < 7; index++) {
                images.push(`${SPACES_ENDPOINT}properties/${property.property_id}/${index + 1}.png`);
            }
        }
        return images;
    }, [property]);

    return (
        <Container>
            {isLoading ? (
                <LoadingContainer>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </LoadingContainer>
            ) : property ? (
                <>
                    {/* HEADER */}
                    <HeaderContainer>
                        <div className="d-flex justify-content-between align-items-center" style={{ gap: 16 }}>
                            <HeaderTitle>{property.property_name}</HeaderTitle>
                            <HeaderTagsContainer className="ms-auto">
                                {property.under_construction && (
                                    <Tag label="Bauphase" type="unitStatus" variant="inProgress" size={isMobile ? "S" : "L"} />
                                )}
                            </HeaderTagsContainer>
                        </div>
                        <HeaderSubtitleContainer>
                            <MaterialIcon icon="distance" size={isMobile ? 14 : 20} color={colorPalette.textDark} />
                            <HeaderSubtitle>{property.property_location}</HeaderSubtitle>
                        </HeaderSubtitleContainer>
                    </HeaderContainer>

                    <ProjectPictures
                        images={getImages()}
                        onClickPicture={() => setIsImageModalOpen(true)}
                    />

                    {/*isMobile && (
                        <TabBar
                            tabs={[
                                ...(property.under_construction
                                    ? [
                                        { label: 'Baufortschritt', value: 'construction-progress' },
                                        { label: 'Zahlungsplan', value: 'payment-plan' },
                                    ]
                                    : []),
                                { label: 'Portfolio', value: 'portfolio' },
                                { label: 'Kerndaten', value: 'key-data' },
                                ...(property.under_construction
                                    ? [
                                        { label: 'Bauupdates', value: 'property-updates' },
                                    ]
                                    : []),
                                { label: 'Wissenwertes', value: 'good-to-know-articles' },
                            ]}
                            activeTab={activeTab}
                            onTabChange={scrollToSection}
                        />
                    )*/}

                    {property.under_construction && (
                        <div className="container-fluid mt-4">
                            <Row className="gy-3" id="construction-progress">
                                {property.construction_progress_items.length > 0 && (
                                    <Col sm={12} md={6}>
                                        <ConstructionProgressCard
                                            property_name={property.property_name}
                                            constructionProgressItems={property.construction_progress_items.sort((a, b) => new Date(a.published_date).getTime() - new Date(b.published_date).getTime())}
                                            showReportDownload={false}
                                        />
                                    </Col>
                                )}
                                {property.payment_plan_items.length > 0 && (
                                    <Col sm={12} md={6} id="payment-plan">
                                        <PaymentPlanCard
                                            paymentPlanItems={property.payment_plan_items.sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime())}
                                            property_name={property.property_name}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    )}

                    <div id="portfolio">
                        <OwnedUnits property_id={property.property_id} />
                    </div>

                    {property.under_construction && (
                        <div id="property-updates">
                            <PropertyUpdates propertyId={property.property_id} />
                        </div>
                    )}

                    <div id="key-data">
                        <ProjectKeyData
                            registrationNumber={property.registration_number}
                            buildingArea={property.total_build_up_area_size}
                            landArea={property.plot_size}
                            landPrice={property.plot_purchased_price}
                        />
                    </div>

                    <div id="good-to-know-articles">
                        <GoodToKnowArticles />
                    </div>
                </>
            ) : (
                <></>
            )}

            {property && (
                <ImageModal
                    images={getImages()}
                    //title="Außenfassade bei Nacht"
                    //subtitle={property.property_name}
                    title={property.property_name}
                    subtitle=""
                    isOpen={isImageModalOpen}
                    onClose={() => setIsImageModalOpen(false)}
                />
            )}
        </Container >
    );
};

export default ProjectPage;
