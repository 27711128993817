import React from 'react';
import {
    ContactListContainer,
    HeaderTitle,
    Container
} from './contacts-list.styles';
import ContactCard from '../../../../components/contact-card/contact-card';
import { Col, Row } from 'react-bootstrap';

const ContactsList: React.FC = () => {
    return (
        <Container>
            <HeaderTitle>Kontakt</HeaderTitle>
            <Row className="gy-2">
                <Col xs={12} xl={4}>
                    <ContactCard type="whatsapp" />
                </Col>
                <Col xs={12} xl={4}>
                    <ContactCard type="phone" />
                </Col>
                <Col xs={12} xl={4}>
                    <ContactCard type="email" />
                </Col>
            </Row>
        </Container>
    );
};

export default ContactsList;
