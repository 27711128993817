// scroll-up-button.styles.ts
import styled from "styled-components";
import { colorPalette } from "../../utils/theme";

export const ScrollToTopButton = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${colorPalette.primaryDark};
  color: ${colorPalette.white};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &:hover {
    background-color: ${colorPalette.blue900};
  }

  &:focus {
    outline: none;
  }
`;
