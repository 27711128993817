import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Animation for the no units message
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible; /* Ensure dropdown is not clipped */
  padding-bottom: 32px;
  position: relative; /* Provide stacking context */
  z-index: 0; /* Lower stacking order than the dropdown */
`;

export const HeaderContainer = styled.div`
  gap: 12px;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 767.98px) {
    gap: 8px;
  }
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingLMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: visible; /* Allow dropdowns to escape vertically */
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;

  position: relative; /* Necessary for dropdown positioning */
  z-index: 1; /* Ensure dropdown appears on top of this container */

  /* Hide scrollbar for better mobile appearance */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const NoUnitsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const NoUnitsMessage = styled.p`
  opacity: 0;
  animation: ${fadeInUp} 1s forwards 1s;
  margin-top: -130px;
  text-align: center;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}
`;

export const Row = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
`;

export const Col = styled.div`
  flex: 0 0 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
