import styled, { keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Animation for the modal coming up from the bottom
const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  @media (max-width: 767.98px) {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const ModalContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  max-width: 500px;
  width: auto;
  z-index: 10000;

  /* Desktop animation */
  animation: fadeIn 0.3s ease-out forwards;

  /* Apply min-width only on larger screens */
  @media (min-width: 768px) {
    width: 900px;
    max-width: 900px;
  }

  /* Mobile-specific animation */
  @media (max-width: 767.98px) {
    width: 100%;
    border-radius: 16px 16px 0 0;
    min-height: 50vh;
    max-height: 90vh;
    animation: ${slideUp} 0.3s ease-out forwards;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue100};

  @media (max-width: 767.98px) {
    padding: 16px;
  }
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSM}

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const ContentContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isPWA",
})<{ isPWA?: boolean }>`
  flex: 1;
  padding: 16px 16px ${({ isPWA }) => (isPWA ? "36px" : "0")};
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
