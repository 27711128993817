import styled from "styled-components";
import { colorPalette, typeScale } from "../../../utils/theme";

export const CardContainer = styled.div`
  min-width: 300px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;

export const UpdateContent = styled.div`
  padding: 0px;
`;

export const UpdateTitle = styled.h3`
  color: ${colorPalette.textMain};
  margin: 8px 0 0;
  ${typeScale.headingXSMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain};
  }
`;

export const UpdateDate = styled.p`
  color: ${colorPalette.textDark};
  margin: 8px 0 0 0;
  ${typeScale.bodyXXS}
`;

export const PlayButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== "isHovering",
})<{ isHovering: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: ${({ isHovering }) =>
    isHovering ? "#0f1c4d" : "#0f1c4dcc"};
  backdrop-filter: blur(4px);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
