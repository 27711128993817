import { useEffect, useState, useRef } from "react";
import Lottie from "lottie-react";
import DocumentTable, { IDocumentTableItem } from "./components/document-table/document-table";
import EmptyCard from "./components/empty-card/empty-card";
import { Title, StyledContainer, LoadingContainer, AnimatedSection, PageWrapper } from "./contracts-page.styles";
import MobileDocumentCard from "./components/mobile-document-card/mobile-document-card";
import TabBar from "../../components/tab-bar/tab-bar";
import EmptyTenancyContracts from '../../assets/images/empty-pm-contract-documents-icon.png';
import EmptyPMContracts from '../../assets/images/empty-tenancy-contract-documents-icon.png';
import useContractStore from "../../stores/contractStore";
import useAlertStore from "../../stores/alertStore";
import { fetchContractData } from "../../services/contractService";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import TenancyContractSidebarModal from "../../modals/sidebar/tenancy-contract-sidemodal/tenancy-contract-sidemodal";
import { IDocument } from "../../services/documentService";
import PurchaseContractSidebarModal from "../../modals/sidebar/purchase-contract-sidemodal/purchase-contract-sidemodal";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const ContractsPage = () => {
    // States
    const [isTenancyContractModalVisible, setIsTenancyContractModalVisible] = useState<IDocument | null>(null);
    const [isPurchaseContractModalVisible, setIsPurchaseContractModalVisible] = useState<IDocument | null>(null);
    const [activeTab, setActiveTab] = useState("verwaltungsverträge");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showPage, setShowPage] = useState(false);

    const verwaltungsvertraegeRef = useRef<HTMLDivElement | null>(null);
    const mietvertraegeRef = useRef<HTMLDivElement | null>(null);
    const kaufvertraegeRef = useRef<HTMLDivElement | null>(null);

    const showAlert = useAlertStore(state => state.showAlert);
    const {
        contract,
        isInitialLoaded,
        isLoading,
        setContract,
        setIsInitalLoaded,
        setIsLoading,
    } = useContractStore();

    useEffect(() => {
        setShowPage(true);

        const loadDashboard = async () => {
            try {
                setIsLoading(true);
                const dashboard = await fetchContractData();
                setContract(dashboard);
                setIsInitalLoaded(true);
            } catch (error) {
                console.log(`Error while fetching contracts:`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
        loadDashboard();
    }, []);

    const tabs = [
        { label: "Verwaltungsverträge", value: "verwaltungsverträge" },
        { label: "Mietverträge", value: "mietverträge" },
        { label: "Kaufverträge", value: "kaufverträge" },
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setActiveTab("verwaltungsverträge");
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Functions
    const handleTabChange = (tabValue: string) => {
        setActiveTab(tabValue);
        if (tabValue === "verwaltungsverträge") {
            verwaltungsvertraegeRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (tabValue === "mietverträge") {
            mietvertraegeRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (tabValue === "kaufverträge") {
            kaufvertraegeRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    };

    const renderDocumentsSection = (headline: string, documents: IDocumentTableItem[], emptyImage: string, emptyTitle: string, emptySubtitle: string, onClickDocument: (document: IDocument) => void) => {
        if (documents.length > 0) {
            return (
                <>
                    <DocumentTable
                        className="d-none d-md-block"
                        headline={headline}
                        documents={documents}
                        onClickDocument={onClickDocument}
                    />
                    <MobileDocumentCard
                        className="d-md-none d-block"
                        headline={headline}
                        documents={documents}
                        onClickDocument={onClickDocument}
                    />
                </>
            );
        } else {
            return (
                <EmptyCard
                    headline={headline}
                    image={emptyImage}
                    title={emptyTitle}
                    subtitle={emptySubtitle}
                />
            );
        }
    };

    return (
        <>
            <CSSTransition
                in={showPage}
                timeout={500}
                classNames="fade-slide"
                unmountOnExit
            >
                <PageWrapper>
                    <StyledContainer>
                        {isLoading && !isInitialLoaded ? (
                            <LoadingContainer>
                                <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                            </LoadingContainer>
                        ) : (
                            <>
                                <Title>Aktive Verträge</Title>
                                {isMobile && (
                                    <TabBar
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        onTabChange={handleTabChange}
                                    />
                                )}
                                <TransitionGroup>
                                    {contract && (
                                        <CSSTransition
                                            key={activeTab}
                                            timeout={500}
                                            classNames="fade-slide"
                                        >
                                            <AnimatedSection>
                                                <div ref={verwaltungsvertraegeRef}>
                                                    {renderDocumentsSection(
                                                        "Verwaltungsverträge",
                                                        [],
                                                        EmptyPMContracts,
                                                        "Kein Verwaltungsvertrag hinterlegt.",
                                                        "Sobald du deinen Mietverwaltungsvertrag unterschrieben hast, findest du alle Details hier.",
                                                        () => { }
                                                    )}
                                                </div>
                                                <div ref={mietvertraegeRef}>
                                                    {renderDocumentsSection(
                                                        "Mietverträge",
                                                        contract.tenancy_contract_documents.map((item) => ({
                                                            document: item,
                                                            unit: `${item.unit?.unit_number} • ${item.unit?.property.property_name}`,
                                                            date: `${new Date(
                                                                item.lease?.start_date ?? ""
                                                            ).toLocaleDateString()} - ${new Date(
                                                                item.lease?.end_date ?? ""
                                                            ).toLocaleDateString()}`,
                                                            status: "active",
                                                        })),
                                                        EmptyTenancyContracts,
                                                        "Kein Mietvertrag hinterlegt.",
                                                        "Für Einheiten, die von managd verwaltet werden, erscheinen Mietverträge hier, sobald dein Mieter unterschrieben hat.",
                                                        (document: IDocument) =>
                                                            setIsTenancyContractModalVisible(document)
                                                    )}
                                                </div>
                                                <div ref={kaufvertraegeRef}>
                                                    {renderDocumentsSection(
                                                        "Kaufverträge",
                                                        contract.purchase_contract_documents.map(
                                                            (item) => ({
                                                                document: item,
                                                                unit: `${item.unit?.unit_number} • ${item.unit?.property.property_name}`,
                                                                date: new Date(item.created_at).toLocaleDateString(),
                                                                status: "signed",
                                                            })
                                                        ),
                                                        EmptyTenancyContracts,
                                                        "Kein Kaufvertrag hinterlegt.",
                                                        "Hier erscheinen Kaufverträge, die du abgeschlossen hast.",
                                                        (document: IDocument) =>
                                                            setIsPurchaseContractModalVisible(document)
                                                    )}
                                                </div>
                                            </AnimatedSection>
                                        </CSSTransition>
                                    )}
                                </TransitionGroup>
                            </>
                        )}
                    </StyledContainer>
                </PageWrapper>
            </CSSTransition>

            {isTenancyContractModalVisible && (
                <TenancyContractSidebarModal
                    leaseDocument={isTenancyContractModalVisible}
                    isVisible={isTenancyContractModalVisible !== null}
                    onClose={() => setIsTenancyContractModalVisible(null)}
                />
            )}

            {isPurchaseContractModalVisible && (
                <PurchaseContractSidebarModal
                    purchaseDocument={isPurchaseContractModalVisible}
                    isVisible={isPurchaseContractModalVisible !== null}
                    onClose={() => setIsPurchaseContractModalVisible(null)}
                />
            )}
        </>
    );
};

export default ContractsPage;
