import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const CardContainer = styled.div`
  height: 100%;
  background: linear-gradient(247.92deg, #0f1c4d 1.75%, #7fccfa 97.83%);
  border: 1px solid ${colorPalette.blue200};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  color: ${colorPalette.white};
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 16px;

  @media (max-width: 767.98px) {
    padding: 16px;
  }
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.headingSMMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const PaymentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ffffff33;
`;

export const Amount = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const UpToPercentage = styled.p`
  margin: 0;
  color: ${colorPalette.successLight};
  ${typeScale.bodySMMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXSMain};
  }
`;

export const Details = styled.div``;

export const DueDate = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  text-align: end;
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain};
  }
`;

export const Label = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  ${typeScale.bodyXS}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const StatisticItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;

  &:first-child {
    border-bottom: 1px solid #ffffff33;
  }
`;

export const StatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.bodyMDMain}
`;

export const StatisticValue = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.bodySM}
`;
