import React from 'react';
import {
    CardContainer,
    Header,
    HeaderTitle,
    Amount,
    Details,
    DueDate,
    Label,
    PaymentInfo,
    StatisticItem,
    StatisticLabel,
    StatisticValue,
    UpToPercentage,
} from './annual-profit-card.styles';
import CTAButton from '../../../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import { ILeasePayment } from '../../../../../../services/leaseService';
import { formatMoney } from '../../../../../../utils/helpers';
import { colorPalette } from '../../../../../../utils/theme';
import MaterialIcon from '../../../../../../components/material-icon';

interface AnnualProfitCardProps {
    payment: ILeasePayment;
    onViewAll: () => void;
}

const AnnualProfitCard: React.FC<AnnualProfitCardProps> = ({
    payment,
    onViewAll,
}) => {
    const isMobile = useIsMobile();

    return (
        <CardContainer onClick={onViewAll}>
            <Header>
                <HeaderTitle>Jahresgewinn</HeaderTitle>
                <CTAButton
                    variant="ghost"
                    size={isMobile ? "S" : "L"}
                    label="Einnahmen & Ausgaben"
                    icon="arrow_outward"
                    color={colorPalette.successLight}
                    noPadding
                />
            </Header>
            <PaymentInfo>
                <Details>
                    <div className="d-flex align-items-center justify-content-center" style={{ gap: 16 }}>
                        <Amount>AED {formatMoney(payment.amount, false)}</Amount>
                        <div className="d-flex align-items-center">
                            <MaterialIcon icon="arrow_outward" size={20} color={colorPalette.successLight} />
                            <UpToPercentage>6.3%</UpToPercentage>
                        </div>
                    </div>
                    <Label>2024</Label>
                </Details>
                <div className="d-flex align-items-center" style={{ gap: 16 }}>
                    <Details>
                        <DueDate>6.3%</DueDate>
                        <Label>Nettorendite</Label>
                    </Details>
                    <Details>
                        <DueDate>9.6%</DueDate>
                        <Label>Bruttorendite</Label>
                    </Details>
                </div>
            </PaymentInfo>
            <div>
                <StatisticItem>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        <MaterialIcon icon="toll" size={20} color={colorPalette.white} />
                        <StatisticLabel>Mieteinahmen</StatisticLabel>
                    </div>
                    <StatisticValue>AED 160,000</StatisticValue>
                </StatisticItem>
                <StatisticItem>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        <MaterialIcon icon="send_money" size={20} color={colorPalette.white} />
                        <StatisticLabel>Ausgaben</StatisticLabel>
                    </div>
                    <StatisticValue>AED 12,000</StatisticValue>
                </StatisticItem>
            </div>
        </CardContainer>
    );
};

export default AnnualProfitCard;
