import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const PackageHeader = styled.div<{ premium: boolean }>`
  background-color: ${({ premium }) =>
    premium ? colorPalette.primaryDark : colorPalette.blue300};
  padding: 24px;
  gap: 24px;
  color: ${({ premium }) =>
    premium ? colorPalette.white : colorPalette.primaryDark};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${colorPalette.white};
  border: 1px solid ${colorPalette.blue200};
  overflow: hidden;
  border-radius: 8px;
  gap: 10px;
  text-align: left;

  @media (max-width: 767.98px) {
    height: inherit;

    &:first-child {
      margin-right: 8px;
    }
  }
`;

export const PackageIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export const PackageTitle = styled.h3`
  margin: 0;
  ${typeScale.headingSMMain}
`;

export const PackageSubtitle = styled.h3<{ premium: boolean }>`
  margin-bottom: 24px;
  color: ${({ premium }) =>
    premium ? colorPalette.blue300 : colorPalette.blue700};
  ${typeScale.bodySMMain}
`;

export const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${colorPalette.white};
  margin-bottom: 8px;
`;

export const PriceAmount = styled.span`
  ${typeScale.headingMain}
  margin-right: 8px;
`;

export const PriceText = styled.span`
  ${typeScale.headingSMMain}
`;

export const TooltipIcon = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.white};

  &:hover {
    color: #ededed;
  }
`;

export const PriceInfoDetail = styled.p<{ premium: boolean }>`
  margin-bottom: 4px;
  color: ${({ premium }) =>
    premium ? colorPalette.blue300 : colorPalette.blue600};
  ${typeScale.bodyXXS}
`;

export const FeatureList = styled.ul`
  flex-grow: 1;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const FeatureItemTitle = styled.p`
  margin-bottom: 0;
  margin-left: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLG};
`;

export const IconWrapper = styled.div<{ premium: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: ${({ premium }) =>
    premium ? colorPalette.primaryDark : colorPalette.blue300};
  border-radius: 50%;
  cursor: pointer;
`;

export const ViewMoreLink = styled.a`
  color: ${colorPalette.blue500};
  text-decoration: none;
  padding-left: 24px;
  display: inline-block;
  margin-bottom: 16px;
  ${typeScale.bodySMMain}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
