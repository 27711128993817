import React, { useMemo, useState } from 'react';
import {
    Container,
    HeaderContainer,
    Title,
    CardContainer,
    Item,
    ItemTitle,
    ItemSubtitle
} from './mobile-rent-and-documents-card.styles';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import { ILease } from '../../../../services/leaseService';
import { IDocument } from '../../../../services/documentService';
import { getActiveLease } from '../../../../utils/helpers';
import ContractHistorySidebarModal from '../../../../modals/sidebar/contract-history-sidemodal/contract-history-sidemodal';
import UnitDocumentsSidebarModal from '../../../../modals/sidebar/unit-documents-sidemodal/unit-documents-sidemodal';
import ActiveTenancyContractSidebarModal from '../../../../modals/sidebar/active-tenancy-contract-sidemodal/active-tenancy-contract-sidemodal';

interface MobileRentAndDocumentsCardProps {
    unit_id: number;
    unit_number: string;
    property_name: string;
    bedroom: number;
    square_feet: number;
    documents: IDocument[];
    leases: ILease[];
}

const MobileRentAndDocumentsCard: React.FC<MobileRentAndDocumentsCardProps> = ({ documents, leases, unit_id, unit_number,
    property_name,
    bedroom,
    square_feet }) => {
    // States
    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
    const [isDocumentsModalVisible, setIsDocumentsModalVisible] = useState(false);
    const [isTenancyContractModalVisible, setIsTenancyContractModalVisible] = useState(false);

    // Functions
    const getLeaseHistory = (leases: ILease[], activeLease: ILease | null): ILease[] => {
        if (!leases || leases.length === 0) return [];

        const today = new Date();

        return leases.filter(lease =>
            lease !== activeLease && new Date(lease.end_date) < today
        );
    };

    // Memos
    const activeLease = useMemo(() => {
        return getActiveLease(leases);
    }, [leases]);

    const leaseHistory = useMemo(() => {
        return getLeaseHistory(leases, activeLease);
    }, [activeLease, leases]);

    return (
        <>
            <Container>
                <HeaderContainer>
                    <Title>Miete & Dokumente</Title>
                </HeaderContainer>
                <CardContainer>
                    {/* DOCUMENTS */}
                    {documents.length > 0 && (
                        <Item onClick={() => setIsDocumentsModalVisible(true)}>
                            <div className="d-flex align-items-center" style={{ gap: 8 }}>
                                <MaterialIcon icon="quick_reference" size={24} color={colorPalette.textMain} />
                                <div>
                                    <ItemTitle>Wohnungsdokumente</ItemTitle>
                                    <ItemSubtitle>Kaufveträge & Title Deed</ItemSubtitle>
                                </div>
                            </div>
                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                        </Item>
                    )}

                    {/* CURRENT ACTIVE TENANCY CONTRACT */}
                    {activeLease && (
                        <Item onClick={() => setIsTenancyContractModalVisible(true)}>
                            <div className="d-flex align-items-center" style={{ gap: 8 }}>
                                <MaterialIcon icon="location_away" size={24} color={colorPalette.textMain} />
                                <div>
                                    <ItemTitle>Mietdetails</ItemTitle>
                                    <ItemSubtitle>Details zum aktuellen Mietverhältnis</ItemSubtitle>
                                </div>
                            </div>
                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                        </Item>
                    )}

                    {/* TENANCY CONTRACT HISTORY AVAILABLE */}
                    {leaseHistory.length > 0 && (
                        <Item onClick={() => setIsHistoryModalVisible(true)}>
                            <div className="d-flex align-items-center" style={{ gap: 8 }}>
                                <MaterialIcon icon="event_repeat" size={24} color={colorPalette.textMain} />
                                <div>
                                    <ItemTitle>Vorherige Mietverhältnisse</ItemTitle>
                                    <ItemSubtitle>Details zu vorherigen Mietern</ItemSubtitle>
                                </div>
                            </div>
                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                        </Item>
                    )}

                </CardContainer>
            </Container>

            <UnitDocumentsSidebarModal
                documents={documents}
                unit_id={unit_id}
                unit_number={unit_number}
                property_name={property_name}
                bedroom={bedroom}
                square_feet={square_feet}
                isVisible={isDocumentsModalVisible}
                onClose={() => setIsDocumentsModalVisible(false)}
            />

            {activeLease && (
                <ActiveTenancyContractSidebarModal
                    lease={activeLease}
                    unit_number={unit_number}
                    property_name={property_name}
                    bedroom={bedroom}
                    square_feet={square_feet}
                    isVisible={isTenancyContractModalVisible}
                    onClose={() => setIsTenancyContractModalVisible(false)}
                />
            )}

            <ContractHistorySidebarModal
                leases={leaseHistory}
                unit_number={unit_number}
                property_name={property_name}
                bedroom={bedroom}
                square_feet={square_feet}
                isVisible={isHistoryModalVisible}
                onClose={() => setIsHistoryModalVisible(false)}
            />
        </>
    );
};

export default MobileRentAndDocumentsCard;
