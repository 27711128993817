import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 32px;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 16px 12px;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingLMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const EmptyTitle = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textDark};
  ${typeScale.headingXSMain};

  @media (max-width: 767.98px) {
    text-align: start;
  }
`;

export const EmptySubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};

  @media (max-width: 767.98px) {
    text-align: start;
  }
`;

export const TabContentContainer = styled.div`
  position: relative;
  margin-top: 24px;

  .fade-enter {
    opacity: 0;
    transform: translateY(10px);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  .fade-exit {
    opacity: 0;
  }
`;
