// theme.ts

// Fonts
export const fonts = {
  primary: `'Manrope', sans-serif`,
  secondary: `'Space Grotesk', sans-serif`,
  tertiary: `'Funnel Sans', sans-serif`,
};

// Colors
export const colorPalette = {
  white: "#FFFFFF",

  // Blue shades
  blue50: "#FAFBFC",
  blue100: "#F4F6F7",
  blue200: "#EBF1F7", // Primary/Light
  blue300: "#B8CEE5",
  blue400: "#7FCCFA", // Primary/Main
  blue500: "#276EF1", // Primary/Action
  blue600: "#666B80",
  blue700: "#3D4666",
  blue800: "#17224D", // Primary/Dark
  blue900: "#080B1A",

  // Gold shades
  gold500: "#B9A862",

  // Semantic colors
  successLight: "#E2F9F1",
  successMedium: "#136956",
  successDark: "#1F4A48",

  warningLight: "#FFE1B3",
  warningMedium: "#FFB23A",
  warningDark: "#AF6107",

  errorLight: "#FAC8C8",
  errorMedium: "#FAC8C8",
  errorDark: "#761414",

  // Primary colors
  primaryLight: "#EBF1F7",
  primaryMain: "#7FCCFA",
  primaryDark: "#17224D",

  // Text colors
  textLight: "#BEDAF7",
  textMain: "#17224D",
  textDark: "#666B80",
};

// Type Scales
export const typeScale = {
  // Headings
  headingXLMain: {
    fontSize: "3.125rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.03em",
    fontFamily: fonts.tertiary,
  },
  headingLMain: {
    fontSize: "2.625rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.02em",
    fontFamily: fonts.tertiary,
  },
  headingMain: {
    fontSize: "2rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.02em",
    fontFamily: fonts.tertiary,
  },
  headingSMMain: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: 1.1,
    letterSpacing: "-0.02em",
    fontFamily: fonts.tertiary,
  },
  headingSM: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.01em",
    fontFamily: fonts.secondary,
  },
  headingXSMain: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.01em",
    fontFamily: fonts.tertiary,
  },
  headingXS: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: "-0.01em",
    fontFamily: fonts.primary,
  },
  headingXXSMain: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.1,
    letterSpacing: "-0.01em",
    fontFamily: fonts.tertiary,
  },

  // Body
  bodyXLMain: {
    fontSize: "1.375rem",
    fontWeight: 700,
    lineHeight: 1.2,
    fontFamily: fonts.primary,
  },
  bodyLGMain: {
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: 1.2,
    fontFamily: fonts.primary,
  },
  bodyLG: {
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: fonts.primary,
  },
  bodyMDMain: {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: 1.4,
    fontFamily: fonts.primary,
  },
  bodyMD: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: fonts.primary,
  },
  bodySMMain: {
    fontSize: "0.9rem",
    fontWeight: 700,
    lineHeight: 1.4,
    fontFamily: fonts.primary,
  },
  bodySM: {
    fontSize: "0.9rem",
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: fonts.primary,
  },
  bodyXSMain: {
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: 1.4,
    fontFamily: fonts.primary,
  },
  bodyXS: {
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: 1.4,
    fontFamily: fonts.primary,
  },
  bodyXXSMain: {
    fontSize: "0.625rem",
    fontWeight: 700,
    lineHeight: 1.2,
    fontFamily: fonts.primary,
  },
  bodyXXS: {
    fontSize: "0.625rem",
    fontWeight: 500,
    lineHeight: 1.2,
    fontFamily: fonts.primary,
  },
};
