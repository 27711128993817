import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 0 16px;
`;

export const HeaderContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const Title = styled.h2`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};
`;

export const CardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "image",
})<{ image: string }>`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${colorPalette.blue200};
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 225px;
  overflow: cover;
`;

export const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

export const InfoContainer = styled.div`
  width: 100%;
  padding: 16px;
  background: rgba(15, 28, 77, 0.7);
  backdrop-filter: blur(30px);
`;

export const ProjectTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.blue50};
  ${typeScale.headingSMMain};
`;

export const ProjectLocation = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  gap: 4px;
  color: ${colorPalette.blue300};
  ${typeScale.bodyXXS};
`;

export const ViewDetailsButton = styled.button`
  display: flex;
  align-items: center;
  margin-top: 16px;
  background: none;
  border: none;
  color: ${colorPalette.white};
  ${typeScale.bodySM};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${colorPalette.blue300};
  }

  svg {
    margin-left: 4px;
  }
`;
