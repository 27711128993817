import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

// Container for the entire card + banner
export const Container = styled.div`
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 100%;
  min-height: 200px;

  &:hover {
    transform: scale(0.99);
  }
`;

// Container for the entire card
export const UnitCardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  display: flex;
  background-color: ${colorPalette.white};
  overflow: hidden;
  position: relative;
  height: 100%;

  display: ${({ isMobile }) => (isMobile ? "block" : "flex")};
`;

// Image container for the unit image
export const ImageContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "320px")};
  height: ${({ isMobile }) => (isMobile ? "auto" : "100%")};
  margin-right: ${({ isMobile }) => (isMobile ? "0" : "16px")};
  display: flex;
  flex-grow: 0;
  justify-content: center;
  overflow: hidden;
`;

// Content of the unit card
export const UnitContent = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-right: ${({ isMobile }) => (isMobile ? "0" : "16px")};
  height: 100%;
`;

// Header for the title, tags, and subtitle
export const UnitHeader = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? "0" : "24px")};
  padding: ${({ isMobile }) => (isMobile ? "16px" : "0")};
`;

// New container for title and tags (they share one row)
export const TitleTagsContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isMobile }) => (isMobile ? "4px" : "8px")};
`;

// Title of the unit
export const UnitTitle = styled.h3.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  margin: 0;
  color: ${colorPalette.textMain};
  ${({ isMobile }) =>
    isMobile ? typeScale.headingSMMain : typeScale.headingMain};
`;

// Tags container for unit status and package
export const UnitTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

// Subtitle container with location icon
export const SubtitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

// Subtitle for the property name
export const UnitSubtitle = styled.h4.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  margin: 0;
  color: ${colorPalette.textDark};
  ${({ isMobile }) => (isMobile ? typeScale.bodyXS : typeScale.bodySM)};
`;

// Details section
export const UnitDetails = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? "0" : "8px")};
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

// Layout for the statistics
export const UnitStatistics = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "isMobile" && prop !== "showBottomBorder",
})<{ isMobile: boolean; showBottomBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? "0" : "8px")};
  padding: ${({ isMobile }) => (isMobile ? "0px 16px 16px" : "8px")};
  border-width: 0px 0px
    ${({ showBottomBorder }) => (showBottomBorder ? "1px" : "0")} 0px;
  border-style: solid;
  border-color: ${colorPalette.blue200};
`;

// Individual statistic item
export const UnitStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodyXS}
  color: ${colorPalette.textMain};
`;

// Label for the statistics
export const UnitStatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain};
`;

// Value of the statistic
export const UnitNumber = styled.p.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  margin: 0;
  color: ${colorPalette.textMain};
  ${({ isMobile }) =>
    isMobile ? typeScale.bodyLGMain : typeScale.headingSMMain};
`;

// Layout for the unit status
export const UnitStatus = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
  width: 100%;
  padding: ${({ isMobile }) => (isMobile ? "12px 16px" : "16px 4px")};
`;

// Individual unit status item
export const UnitStatusItem = styled.div`
  gap: 4px;
`;

// Label for the unit status item
export const UnitStatusLabel = styled.p.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  margin: 0;
  color: ${colorPalette.textDark};
  ${({ isMobile }) => (isMobile ? typeScale.bodyXXS : typeScale.bodySM)};
`;

export const LookingForTenant = styled.h4.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  margin: 0;
  color: ${colorPalette.textDark};
  ${({ isMobile }) => (isMobile ? typeScale.bodySMMain : typeScale.bodyMDMain)};
`;

/* CONSTRUCTION STYLES */
export const ProgressContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  width: 100%;
  padding: ${({ isMobile }) => (isMobile ? "12px 16px" : "16px 4px")};
`;

export const ProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ProgressTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ProgressLabel = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  color: ${colorPalette.textMain};
  ${({ isMobile }) => (isMobile ? typeScale.bodySMMain : typeScale.bodyMDMain)};
`;

export const ProgressDescription = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  color: ${colorPalette.textMain};
  ${({ isMobile }) => (isMobile ? typeScale.bodySM : typeScale.bodyMD)};
`;

export const ProgressPercentage = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>`
  color: ${colorPalette.textMain};
  ${({ isMobile }) =>
    isMobile ? typeScale.headingXXSMain : typeScale.headingXSMain};
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colorPalette.blue200};
  border-radius: 4px;

  @media (max-width: 767.98px) {
    height: 5px;
  }
`;

export const ProgressBar = styled.div`
  height: 100%;
  background: linear-gradient(90deg, #7fccfa 80%, #276ef1 100%);
  border-radius: 4px;
`;
