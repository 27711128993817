import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const CardContainer = styled.div`
  border-radius: 8px;
`;

export const Title = styled.h2`
  margin-top: 48px;
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    margin-top: 32px;
    ${typeScale.headingSMMain};
  }
`;

export const ButtonWrapper = styled.div`
  padding: 24px 0px;
`;
