import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

// Container for the card
export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  height: 100%;
  overflow: hidden;

  @media (max-width: 767.98px) {
    border-radius: 0;
    border: 0;
    margin-left: -12px;
    margin-right: -12px;
  }
`;

// Carousel controls (prev/next buttons)
export const SliderControl = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3.613483190536499px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  opacity: 1 !important;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  &.carousel-control-prev {
    left: 8px;
  }

  &.carousel-control-next {
    right: 8px;
  }
`;

export const CarouselIndicators = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 6px;
  z-index: 10;
`;

export const CarouselIndicator = styled.div`
  width: 6px !important;
  height: 6px !important;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  &.active {
    background-color: ${colorPalette.white};
  }
  &:hover {
    background-color: ${colorPalette.white};
  }
`;

// Statistics section for the card
export const UnitStatistics = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 8px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    margin-top: 0;
    padding: 12px 16px;
  }
`;

// Individual statistic item
export const UnitStatistic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typeScale.bodyXS}
  color: ${colorPalette.textMain};
`;

// Statistic label
export const UnitStatisticLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

// Main image in the carousel
export const MainImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 395px;
  object-position: top;
  object-fit: cover;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  cursor: pointer;
`;

// Small image in the gallery
export const SmallImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 190px;
  object-fit: cover;
  border-radius: 4px;
`;

// Container for the gallery on desktop
export const GalleryContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
`;
