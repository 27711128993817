import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const BannerContainer = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: ${colorPalette.blue200};
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${colorPalette.blue200};
`;

export const Image = styled.img`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
`;

export const Description = styled.p`
  margin-bottom: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};
`;
