import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 0px 20px 0px #0000001a;
  cursor: pointer;

  /* Add a pseudo-element for the blurred background */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffa6;
    backdrop-filter: blur(20px);
    z-index: 1;
    border-radius: 12px;
    border: 1px solid ${colorPalette.blue200};
  }

  /* Ensure content stays clear and on top */
  * {
    position: relative;
    z-index: 2;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
`;

export const ContentContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UnitsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

export const UnitItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Circle = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const UnitLabel = styled.div`
  color: ${colorPalette.textMain};
  ${typeScale.headingXXSMain}
  text-align: start;
`;

export const UnitDescription = styled.div`
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

export const ChartWrapper = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
`;

export const CenterText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${colorPalette.textMain};

  div:nth-child(1) {
    ${typeScale.headingLMain};
  }

  div:nth-child(2) {
    color: ${colorPalette.textDark};
    ${typeScale.bodyXS};
  }

  div:nth-child(3) {
    color: ${colorPalette.textDark};
    ${typeScale.bodyXS};
  }
`;
