import React, { useMemo } from 'react';
import {
    Container,
    CardContainer,
    Amount,
    Label,
    RowContainer
} from './statistics.styles';
import { Col, Row } from 'react-bootstrap';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import useIsMobile from '../../../../hooks/useIsMobile';
import { IUnit } from '../../../../services/unitService';
import { getActiveLease } from '../../../../utils/helpers';

interface StatisticsProps {
    units: IUnit[];
}

const Statistics: React.FC<StatisticsProps> = ({ units }) => {
    const isMobile = useIsMobile();

    // Memos
    const rentedUnits = useMemo(() => {
        return units.filter(unit => getActiveLease(unit.leases) !== null);
    }, [units]);

    const totalOffPlanUnits = useMemo(() => {
        return units.filter(unit => unit.property.under_construction);
    }, [units]);

    return (
        <Container>
            <RowContainer className={isMobile ? "gy-0 gx-0" : "gy-3 gx-3"}>
                <Col xs={6} lg={3}>
                    <CardContainer showRightBorder showBottomBorder>
                        <MaterialIcon icon="home_work" size={isMobile ? 26 : 32} color={colorPalette.blue400} />
                        <div className="text-end">
                            <Amount>{units.length}</Amount>
                            <Label>Gesamt</Label>
                        </div>
                    </CardContainer>
                </Col>

                <Col xs={6} lg={3}>
                    <CardContainer showBottomBorder>
                        <MaterialIcon icon="handyman" size={isMobile ? 26 : 32} color={colorPalette.blue300} />
                        <div className="text-end">
                            <Amount>{totalOffPlanUnits.length}</Amount>
                            <Label>Im Bau</Label>
                        </div>
                    </CardContainer>
                </Col>

                <Col xs={6} lg={3}>
                    <CardContainer showRightBorder>
                        <MaterialIcon icon="in_home_mode" size={isMobile ? 26 : 32} color={colorPalette.textMain} />
                        <div className="text-end">
                            <Amount>{units.length - rentedUnits.length - totalOffPlanUnits.length}</Amount>
                            <Label>Fertig (Unvermietet)</Label>
                        </div>
                    </CardContainer>
                </Col>

                <Col xs={6} lg={3}>
                    <CardContainer>
                        <MaterialIcon icon="location_away" size={isMobile ? 26 : 32} color={colorPalette.successMedium} />
                        <div className="text-end">
                            <Amount>{rentedUnits.length}</Amount>
                            <Label>Fertig (Vermietet)</Label>
                        </div>
                    </CardContainer>
                </Col>
            </RowContainer>
        </Container>
    );
};

export default Statistics;
