import styled from "styled-components";
import { colorPalette, typeScale } from "../../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue200};
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }
`;

export const ItemTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
`;

export const ItemSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.successMedium};
  ${typeScale.bodyXXSMain}
`;
