import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

// Container for the entire section
export const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  padding: 0 16px;

  .news-cards-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

// Header Title
export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;
