import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Container for the filter button
export const FilterContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  padding: 12px;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? colorPalette.blue800 : colorPalette.blue200};
  color: ${({ isSelected }) =>
    isSelected ? colorPalette.white : colorPalette.textMain};
  transition: background-color 0.3s ease, color 0.3s ease;
  ${typeScale.bodyMD};

  svg {
    margin-left: 8px;
    color: ${({ isSelected }) =>
      isSelected ? colorPalette.white : colorPalette.blue800};
  }

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? colorPalette.blue800 : colorPalette.blue100};
    color: ${({ isSelected }) =>
      isSelected ? colorPalette.white : colorPalette.blue800};
  }
`;

export const FilterLabel = styled.span`
  font-size: inherit;
  font-weight: inherit;
`;

export const DropdownContainer = styled.div`
  background-color: ${colorPalette.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 5; /* Ensure it appears above other elements */
  max-height: 200px;
  overflow-y: auto;
  min-width: 200px; /* Ensure a minimum width */
`;

export const DropdownItem = styled.p`
  margin: 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: ${colorPalette.white};
  border-bottom: 1px solid ${colorPalette.blue200};
  transition: background-color 0.2s ease;
  color: ${colorPalette.textMain};
  white-space: nowrap;
  ${typeScale.bodyMDMain};
  padding: 12px;

  &:hover {
    background-color: ${colorPalette.blue100};
  }
`;
