import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";
import { Row } from "react-bootstrap";

export const Container = styled.div`
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const RowContainer = styled(Row)`
  @media (max-width: 767.98px) {
    border-radius: 8px;
    border: 1px solid ${colorPalette.blue200};
  }
`;

export const CardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "showTopBorder" &&
    prop !== "showBottomBorder" &&
    prop !== "showLeftBorder" &&
    prop !== "showRightBorder",
})<{
  showTopBorder?: boolean;
  showBottomBorder?: boolean;
  showLeftBorder?: boolean;
  showRightBorder?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    border-radius: 0;

    // Optional border logic for smaller screens
    border-top: ${({ showTopBorder }) =>
      showTopBorder !== undefined
        ? showTopBorder
          ? `1px solid ${colorPalette.blue200}`
          : "none"
        : "inherit"};
    border-bottom: ${({ showBottomBorder }) =>
      showBottomBorder !== undefined
        ? showBottomBorder
          ? `1px solid ${colorPalette.blue200}`
          : "none"
        : "inherit"};
    border-left: ${({ showLeftBorder }) =>
      showLeftBorder !== undefined
        ? showLeftBorder
          ? `1px solid ${colorPalette.blue200}`
          : "none"
        : "inherit"};
    border-right: ${({ showRightBorder }) =>
      showRightBorder !== undefined
        ? showRightBorder
          ? `1px solid ${colorPalette.blue200}`
          : "none"
        : "inherit"};
  }
`;

export const Amount = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Label = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;
