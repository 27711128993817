// DashboardPage.tsx

import Lottie from "lottie-react";
import useAlertStore from "../../stores/alertStore";
import useDashboardStore from "../../stores/dashboardStore";
import LoadingAnim from "../../assets/anims/loading-anim.json";
import { useEffect, useMemo, useState } from "react";
import GoodToKnowArticles from "../../components/good-to-know-articles/good-to-know-articles";
import PropertyUpdates from "../../components/property-updates/property-updates";
import Header from "./components/header/header";
import NewsUpdates from "../../components/news-updates/news-updates";
import { StyledContainer } from "./dashboard-page.styles";
import RentedUnitsSection from "./components/rented-units-section/rented-units-section";
import { fetchDashboardData } from "../../services/dashboardService";
import { fetchUnits } from "../../services/unitService";
import useUnitStore from "../../stores/unitStore";
import { getActiveLease } from "../../utils/helpers";
import useInvestorDetailStore from "../../stores/investorDetailStore";
import OnboardingModal from "../../modals/onboarding-modal/onboarding-modal";
import { fetchInvestor } from "../../services/investorService";

const DashboardPage = () => {
    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        dashboard,
        isInitialLoaded,
        isLoading,
        setDashboard,
        setIsInitalLoaded,
        setIsLoading,
    } = useDashboardStore();
    const { units, setUnits } = useUnitStore();
    const { investor, setInvestor } = useInvestorDetailStore();

    // States
    const [isOnboardingModalVisible, setIsOnboardingModalVisible] = useState(false);

    // Use Effects
    useEffect(() => {
        const loadDashboardData = async () => {
            try {
                setIsLoading(true);
                const dashboard = await fetchDashboardData();
                setDashboard(dashboard);

                const units = await fetchUnits();
                setUnits(units);

                const investor = await fetchInvestor();
                setInvestor(investor);

                setIsInitalLoaded(true);
            } catch (error) {
                console.log(`Error while fetching dashboard data:`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
        loadDashboardData();
    }, [setDashboard, setInvestor, setIsInitalLoaded, setIsLoading, setUnits, showAlert]);

    useEffect(() => {
        if (investor && investor.onboarding_step === 4) {
            setIsOnboardingModalVisible(true);
        }
    }, [investor]);

    const offPlanUnits = useMemo(() => {
        return units.filter(unit => unit.property.under_construction);
    }, [units]);

    const rentedUnits = useMemo(() => {
        return units.filter(unit => getActiveLease(unit.leases) !== null);
    }, [units]);

    const totalRentableUnits = useMemo(() => {
        return units.filter(unit => !unit.property.under_construction);
    }, [units]);

    // Calculate gross yield
    const grossYield = useMemo(() => {
        if (!dashboard || !dashboard.portfolio_value || rentedUnits.length !== totalRentableUnits.length) return 0;

        const totalAnnualRent = rentedUnits.reduce((acc, unit) => {
            const activeLease = getActiveLease(unit.leases);
            const annualRentAmount = activeLease ? Number(activeLease.annual_rent_amount) : 0;
            return acc + annualRentAmount;
        }, 0);

        return (totalAnnualRent / dashboard.portfolio_value) * 100;
    }, [dashboard, rentedUnits, totalRentableUnits]);

    return (
        <>
            <StyledContainer>
                {isLoading && !isInitialLoaded ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                    </div>
                ) : (
                    dashboard && (
                        <>
                            {/* HEADER COMPONENT */}
                            <Header
                                dashboard={dashboard}
                                totalOffPlanUnits={offPlanUnits.length}
                                totalRentableUnits={totalRentableUnits.length}
                                totalRentedUnits={rentedUnits.length}
                                totalUnits={units.length}
                                grossYield={grossYield}
                            />

                            {rentedUnits.length > 0 && (
                                <RentedUnitsSection units={rentedUnits} />
                            )}

                            <PropertyUpdates />

                            <GoodToKnowArticles />

                            <NewsUpdates />
                        </>
                    )
                )}
            </StyledContainer>

            {/* FINISH ONBOARDING MODAL */}
            {isOnboardingModalVisible && (
                <OnboardingModal
                    onClose={() => {
                        setIsOnboardingModalVisible(false);
                    }}
                />
            )}
        </>
    );
};

export default DashboardPage;
