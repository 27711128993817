import React, { useState } from "react";
import CTAButton from "../../../../components/cta-button/cta-button";
import Filter from "../../../../components/filter/filter";
import TransactionTable, { ITransaction } from "../../components/transaction-table/transaction-table";
import useIsMobile from "../../../../hooks/useIsMobile";
import { CategoryTitle, FilterContainer } from "./transactions-page.styles";
import { colorPalette } from "../../../../utils/theme";
import TransactionItem from "../income-expenses-page/components/transaction-item/transaction-item";
import { format } from "date-fns";

const MOCK_TRANSACTIONS: ITransaction[] = [
    {
        id: 1,
        transactionType: "Auszahlung",
        property: "-",
        date: "10.16.2024",
        status: "Offen",
        amount: -10000,
        statusType: "open",
    },
    {
        id: 2,
        transactionType: "Mieteinnahmen 803",
        property: "803 • The Vybe",
        date: "10.01.2024",
        status: "Cheque Empfangen",
        amount: 20000,
        statusType: "received",
    },
    {
        id: 3,
        transactionType: "Instandhaltung",
        property: "803 • The Vybe",
        date: "06.27.2024",
        status: "Fällig",
        amount: -2000,
        statusType: "due",
    },
];

// Utility function to group transactions by month
const groupTransactionsByMonth = (transactions: ITransaction[]) => {
    return transactions.reduce((acc, transaction) => {
        const month = format(new Date(transaction.date), "MMMM yyyy");
        if (!acc[month]) {
            acc[month] = [];
        }
        acc[month].push(transaction);
        return acc;
    }, {} as Record<string, ITransaction[]>);
};

const TransactionsPage: React.FC = () => {
    const isMobile = useIsMobile();

    // States
    const [selectedYear, setSelectedYear] = useState<string>("2025");

    // Functions
    const handleFilterClick = (year: string) => {
        if (selectedYear === year) {
            setSelectedYear("2025");
        } else {
            setSelectedYear(year);
        }
    };

    const groupedTransactions = groupTransactionsByMonth(MOCK_TRANSACTIONS);

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between" style={{ marginTop: isMobile ? 24 : 42, marginBottom: 24, paddingLeft: 16, paddingRight: 16 }}>
                <FilterContainer>
                    <Filter
                        label="Datum"
                        isSelected={false}
                        onSelect={() => handleFilterClick("2025")}
                    />
                    <Filter
                        label="Transaktion"
                        isSelected={selectedYear === "2024"}
                        onSelect={() => handleFilterClick("2024")}
                        items={[
                            { label: "Alle", value: "all" },
                            { label: "Miete", value: "lease" },
                            { label: "Instandhaltung", value: "maintenance" },
                            { label: "Service Charges", value: "service_charge" }
                        ]}
                        hasDropdown
                        multiple
                    />
                    <Filter
                        label="Status"
                        isSelected={selectedYear === "2024"}
                        onSelect={() => handleFilterClick("2024")}
                        items={[
                            { label: "Alle", value: "all" },
                            { label: "Offen", value: "open" },
                            { label: "Bezahlt", value: "paid" },
                            { label: "Fällig", value: "overdue" }
                        ]}
                        hasDropdown
                        multiple
                    />
                    <Filter
                        label="Wohnung"
                        isSelected={selectedYear === "2024"}
                        onSelect={() => handleFilterClick("2024")}
                        items={[
                            { label: "Alle", value: "all" },
                            { label: "The Ivy", value: "1" },
                            { label: "The Vybe", value: "2" }
                        ]}
                        hasDropdown
                        multiple
                    />
                </FilterContainer>

                {!isMobile && (
                    <CTAButton
                        variant="primary"
                        size="S"
                        label="Exportieren"
                        icon="file_open"
                        onClick={() => alert("Coming soon...")}
                    />
                )}
            </div>

            {isMobile ? (
                <>
                    <div style={{ paddingLeft: 16, paddingRight: 16, marginBottom: 16 }}>
                        <CTAButton
                            variant="ghost"
                            size="S"
                            label="Transaktionen Exportieren"
                            icon="file_open"
                            onClick={() => alert("Coming soon...")}
                            noPadding
                        />
                    </div>

                    {Object.entries(groupedTransactions).map(([month, transactions]) => (
                        <div key={month}>
                            <CategoryTitle>{month}</CategoryTitle>
                            {transactions.map(transaction => (
                                <TransactionItem
                                    key={transaction.id}
                                    title={transaction.transactionType}
                                    property={transaction.property}
                                    description={transaction.date}
                                    amount={transaction.amount}
                                    icon="location_away"
                                    iconColor={colorPalette.successMedium}
                                />
                            ))}
                        </div>
                    ))}
                </>
            ) : (
                <TransactionTable transactions={MOCK_TRANSACTIONS} />
            )}
        </div>
    );
};

export default TransactionsPage;
