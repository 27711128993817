import React, { useCallback } from 'react';
import {
    CardContainer,
    Header,
    HeaderTitle,
    Amount,
    Details,
    DueDate,
    Label,
    StatusSection,
    StatusLabel,
    StatusDescription,
    ProgressBar,
    ProgressSegment,
    ProgressSegmentLine,
    PaymentInfo,
    Title,
} from './next-rent-payment-card.styles';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';
import { ILeasePayment } from '../../../../services/leaseService';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';

interface NextRentPaymentCardProps {
    payment: ILeasePayment;
    onViewAll: () => void;
}

const NextRentPaymentCard: React.FC<NextRentPaymentCardProps> = ({
    payment,
    onViewAll,
}) => {
    const isMobile = useIsMobile();

    const segments = 4;
    const progressPercentage = 75;
    const filledSegments = Math.floor((progressPercentage / 100) * segments);

    const getSegmentWidth = useCallback((index: number) => {
        if (index === 0 || index === 1) return isMobile ? "20%" : "10%";
        if (index === 2) return isMobile ? "60%" : "80%";
        return "0%";
    }, [isMobile]);

    return (
        <div>
            {isMobile && (
                <Title>Mieteinnahmen</Title>
            )}
            <CardContainer onClick={onViewAll}>
                <Header>
                    <HeaderTitle>Nächste Mietauszahlung</HeaderTitle>
                    <CTAButton
                        variant="ghost"
                        size={isMobile ? "S" : "L"}
                        label="Details"
                        icon="arrow_outward"
                        color={colorPalette.successLight}
                        noPadding
                    />
                </Header>
                <PaymentInfo>
                    <Amount>AED {formatMoney(payment.amount)}</Amount>
                    <DueDate>{new Date(payment.due_date).toLocaleDateString()}</DueDate>
                </PaymentInfo>
                <Details>
                    <Label>Betrag</Label>
                    <Label>Auszahlungsdatum</Label>
                </Details>
                <ProgressBar>
                    {Array.from({ length: segments }).map((_, index) => (
                        <React.Fragment key={index}>
                            <ProgressSegment filled={index < filledSegments} />
                            {index < segments - 1 && (
                                <ProgressSegmentLine
                                    width={getSegmentWidth(index)}
                                    filled={index < filledSegments - 1 || (index === filledSegments - 1 && progressPercentage % (100 / segments) > 0)}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </ProgressBar>
                <StatusSection>
                    <StatusLabel>Status: Scheck gutgeschrieben</StatusLabel>
                    <StatusDescription>Die Miete wurde unserem Konto gutgeschrieben.</StatusDescription>
                </StatusSection>
            </CardContainer>
        </div>
    );
};

export default NextRentPaymentCard;
