import { useState, useEffect } from "react";
import { CustomSelectFieldOptions } from "../components/custom-select-field/custom-select-field";
import { allCountries } from 'country-region-data';
import countries from 'i18n-iso-countries';
import deLocale from 'i18n-iso-countries/langs/de.json';
countries.registerLocale(deLocale);

const useCountryStateOptions = (country: string) => {
    const [countryOptions, setCountryOptions] = useState<CustomSelectFieldOptions[]>([]);
    const [stateOptions, setStateOptions] = useState<CustomSelectFieldOptions[]>([]);

    useEffect(() => {
        const countryList = Object.entries(countries.getNames("de")).map(([code, name]) => ({
            label: name,
            value: code,
        }));
        setCountryOptions(countryList);
    }, []);

    useEffect(() => {
        const states =
            allCountries.find((c) => c[1] === country)?.[2].map((region) => ({
                label: region[0],
                value: region[0],
            })) ?? [];
        setStateOptions(states);
    }, [country]);

    return { countryOptions, stateOptions };
};

export default useCountryStateOptions;
