import React, { useState } from "react";
import Footer from "../../components/footer/footer";
import HomePage from "./sub-pages/home-page/home-page";
import DetailsPage from "./sub-pages/details-page/details-page";

const LandingPage: React.FC = () => {

    // States
    const [isDetailsVisible, setIsDetailsVisible] = useState<string | null>(null);

    return (
        <>
            {isDetailsVisible ? (
                <DetailsPage
                    anchor={isDetailsVisible}
                    onClickBack={() => setIsDetailsVisible(null)}
                    onClickDetails={(value: string) => {
                        if (value === "home") {
                            setIsDetailsVisible(null);
                        } else {
                            setIsDetailsVisible(value);
                        }
                    }}
                />
            ) : (
                <HomePage
                    onClickDetails={(value: string) => {
                        if (value === "home") {
                            setIsDetailsVisible(null);
                        } else {
                            setIsDetailsVisible(value);
                        }
                    }}
                />
            )}

            <Footer />
        </>
    );
};

export default LandingPage;
