import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    CardContainer,
    Container,
    Title,
    UnitTitle,
    UnitDetailsRow,
    UnitDetail,
    UnitDetailLabel,
    Header
} from './owned-units.styles';
import { fetchUnits, IUnit } from '../../../../services/unitService';
import { formatMoney, getActiveLease } from '../../../../utils/helpers';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { useNavigate } from 'react-router-dom';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';
import MobileUnitCard from '../../../dashboard-page/components/rented-units-section/components/mobile-unit-card/mobile-unit-card';
import UnitCard from '../../../my-units-page/components/unit-card/unit-card';
import useUnitStore from '../../../../stores/unitStore';

interface OwnedUnitsProps {
    className?: string | undefined;
    property_id: number;
}

const OwnedUnits: React.FC<OwnedUnitsProps> = ({ className, property_id }) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // Stores
    const units = useUnitStore(state => state.units);

    // States
    const [filteredUnits, setFilteredUnits] = useState<IUnit[]>([]);

    const onClickUnit = (unit_id: number) => {
        navigate(`/my-units/${unit_id}`);
    }

    // Fetch units
    useEffect(() => {
        if (property_id) {
            setFilteredUnits(units.filter(unit => unit.property.property_id === property_id));
        }
    }, [property_id, units]);

    if (filteredUnits.length === 0) return (null);

    return (
        <Container className={className}>
            <Header onClick={isMobile ? () => navigate("/my-units") : undefined}>
                <Title>Einheiten im Portfolio</Title>
                {isMobile ? (
                    <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                ) : (
                    <CTAButton
                        variant="ghost"
                        size="L"
                        label="Alle Einheiten"
                        icon="arrow_outward"
                        onClick={() => navigate("/my-units")}
                        noPadding
                    />
                )}
            </Header>
            <Row className="gy-3">
                {filteredUnits.map((unit, index) => {
                    return (
                        isMobile ? (
                            <Col xs={12} md={6} xxl={4} key={unit.unit_id}>
                                <MobileUnitCard unit={unit} />
                            </Col>
                        ) : (
                            <Col sm={12} md={6} xxl={4} key={index} >
                                <UnitCard
                                    imageSrc={unit.pictures.length > 0 ? unit.pictures[0].picture_path : ""}
                                    propertyName={unit.property.property_name}
                                    title={unit.unit_number}
                                    onClick={() => onClickUnit(unit.unit_id)}
                                    bedroom={unit.bedroom}
                                    bathroom={unit.bathroom}
                                    square_feet={unit.square_feet}
                                    purchasePrice={unit.owner?.purchase_price ?? 0}
                                    expectedAnnualRent={unit.minimum_annual_rent}
                                    rent={getActiveLease(unit.leases)?.annual_rent_amount ?? undefined}
                                    purpose_type={unit.purpose_type}
                                    constructionProgressItem={unit.property.construction_progress_items.length > 0 ? unit.property.construction_progress_items[0] : undefined}
                                    pmPackage={undefined}
                                    isMobile={true}
                                />
                            </Col>
                        )
                    )
                })}
            </Row>
        </Container >
    );
};

export default OwnedUnits;
