import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface AlertState {
  type: "success" | "warning" | "error" | "info" | "action";
  title: string;
  message: string;
  visible: boolean;
  actionLabel?: string;
  onActionClick?: () => void;
  showAlert: (
    type: AlertState["type"],
    title: string,
    message?: string,
    actionLabel?: string,
    onActionClick?: () => void
  ) => void;
  hideAlert: () => void;
}

const useAlertStore = create<AlertState>()(
  devtools((set) => ({
    type: "info",
    title: "",
    message: "",
    visible: false,
    actionLabel: undefined,
    onActionClick: undefined,

    // Function to show an alert with optional action label and callback
    showAlert: (type, title, message, actionLabel, onActionClick) =>
      set({
        type,
        title,
        message,
        visible: true,
        actionLabel,
        onActionClick,
      }),

    // Function to hide the alert
    hideAlert: () =>
      set({
        type: "info",
        title: "",
        message: "",
        visible: false,
        actionLabel: undefined,
        onActionClick: undefined,
      }),
  }))
);

export default useAlertStore;
