import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const Container = styled.div`
  padding: 24px 16px;
  background-color: ${colorPalette.blue50};
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;

  @media (max-width: 767.98px) {
    margin-top: 16px;
  }
`;

export const Title = styled.div`
  margin-bottom: 8px;
  color: ${colorPalette.successMedium};
  ${typeScale.headingLMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const Subtitle = styled.div`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};
`;
