import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Main container for the page
export const Container = styled.div`
  padding-bottom: 32px;
`;

export const HeaderContainer = styled.div`
  padding: 12px 16px;
  gap: 16px;

  @media (max-width: 767.98px) {
    padding: 24px 12px 12px;
    background-color: transparent;
  }
`;

export const HeaderTitle = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingLMain}

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const HeaderSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 4px;
`;

export const HeaderSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS}
  }
`;

export const HeaderTagsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

// Loading container for Lottie animation
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

// Spinner loader when fetching more units
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
