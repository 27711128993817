import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";
import MaterialIcon from "../../../../../../components/material-icon";

export const FAQWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
  overflow: hidden;
`;

export const FAQItemWrapper = styled.div`
  border-bottom: 1px solid ${colorPalette.blue200};

  &:last-child {
    border-bottom: none;
  }
`;

export const FAQQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  cursor: pointer;
  background-color: white;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain}

  &:hover {
    background-color: ${colorPalette.blue50};
  }

  @media (max-width: 767.98px) {
    padding: 16px;
    ${typeScale.bodySMMain}
  }
`;

export const FAQAnswer = styled.div<{ isVisible: boolean }>`
  padding: ${({ isVisible }) => (isVisible ? "16px" : "0")};
  height: ${({ isVisible }) => (isVisible ? "auto" : "0")};
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: ${colorPalette.blue50};
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS}
  }
`;

export const PlusIcon = styled(MaterialIcon)<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
`;
