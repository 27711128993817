import React from "react";
import { EmptyStateContainer, Image, Title, Description } from "./empty-state.styles";
import useIsMobile from "../../../../hooks/useIsMobile";

interface EmptyStateProps {
    imageSrc: string;
    imageWidth?: string;
    title: string;
    description: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ imageSrc, imageWidth, title, description }) => {
    const isMobile = useIsMobile();

    return (
        <EmptyStateContainer>
            {isMobile ? (
                <div className="d-flex text-start" style={{ gap: 16 }}>
                    <Image src={imageSrc} width={imageWidth ?? "100%"} alt="Question Coin" />
                    <div>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </div>
                </div>
            ) : (
                <>
                    <Image src={imageSrc} width={imageWidth ?? "100%"} alt="Question Coin" />
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </>
            )}
        </EmptyStateContainer>
    );
};

export default EmptyState;
