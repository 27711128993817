import React from "react";
import {
    ListItemContainer,
    IconContainer,
    ListItemDetails,
    ListItemTextContainer,
    ListItemTitle,
    ListItemSubtitle,
    ListItemAmount,
    ListItemProperty,
    ListItemStatus,
} from "./transaction-item.styles";
import MaterialIcon from "../../../../../../components/material-icon";
import { formatMoney } from "../../../../../../utils/helpers";
import { MaterialSymbol } from "material-symbols";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { colorPalette } from "../../../../../../utils/theme";

interface TransactionItemProps {
    title: string;
    property?: string;
    description?: string | null;
    amount: number;
    icon: MaterialSymbol;
    iconColor: string;
}

const TransactionItem: React.FC<TransactionItemProps> = ({ title, property, description, amount, icon, iconColor }) => {
    const isMobile = useIsMobile();

    return (
        <ListItemContainer>
            {isMobile ? (
                <>
                    <ListItemDetails>
                        <IconContainer>
                            <MaterialIcon icon={icon} size={18} color={iconColor} />
                        </IconContainer>
                        <ListItemTextContainer>
                            <ListItemTitle>{title}</ListItemTitle>
                            {description && <ListItemSubtitle>{description}</ListItemSubtitle>}
                            {property && <ListItemProperty>{property}</ListItemProperty>}
                        </ListItemTextContainer>
                    </ListItemDetails>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        <div>
                            <ListItemAmount className={amount > 0 ? "positive" : "negative"}>
                                {formatMoney(amount, false)} AED
                            </ListItemAmount>
                            <ListItemStatus>Offen</ListItemStatus>
                        </div>
                        <MaterialIcon icon="chevron_forward" size={20} color={colorPalette.blue500} />
                    </div>
                </>
            ) : (
                <>
                    <ListItemDetails>
                        <IconContainer>
                            <MaterialIcon icon={icon} size={18} color={iconColor} />
                        </IconContainer>
                        <ListItemTextContainer>
                            <ListItemTitle>{title}</ListItemTitle>
                            {description && <ListItemSubtitle>{description}</ListItemSubtitle>}
                        </ListItemTextContainer>
                    </ListItemDetails >
                    {property && <ListItemProperty>{property}</ListItemProperty>}
                    <ListItemAmount className={amount > 0 ? "positive" : "negative"}>
                        {formatMoney(amount)} AED
                    </ListItemAmount>
                </>
            )}
        </ListItemContainer >
    );
};

export default TransactionItem;
