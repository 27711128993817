// ProfileEditAddresDetailsPage.tsx
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../../../components/material-icon";
import { colorPalette } from "../../../../utils/theme";
import {
    StyledContainer,
    HeaderContainer,
    Title,
    HeaderLeftContent,
} from "./edit-address-details-page.styles";
import useIsMobile from "../../../../hooks/useIsMobile";
import PersonalDataForm from "../../../../components/personal-data-form/personal-data-form";
import useCustomNavigate from "../../../../hooks/useCustomNavigate";

const ProfileEditAddresDetailsPage = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    return (
        <StyledContainer>
            <Row>
                <Col xs={12} lg={10} xl={8}>
                    <HeaderContainer>
                        <HeaderLeftContent>
                            {!isMobile && (
                                <MaterialIcon icon="arrow_back" size={24} color={colorPalette.textMain} onClick={() => navigate(-1)} />
                            )}
                            <Title>Persönliche Daten ändern</Title>
                        </HeaderLeftContent>
                    </HeaderContainer>

                    <PersonalDataForm onCancel={() => navigate(-1)} />
                </Col>
            </Row>
        </StyledContainer>
    );
};

export default ProfileEditAddresDetailsPage;
