import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const PopupContainer = styled.div`
  position: absolute;
  background-color: ${colorPalette.primaryDark};
  color: ${colorPalette.white};
  border-radius: 4px;
  width: 225px;
  z-index: 100;
  transform: translate(-50%, -100%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Arrow = styled.div`
  position: absolute;
  top: 100%; /* Position the arrow at the bottom of the popup */
  left: 50%; /* Center the arrow horizontally */
  transform: translateX(-50%);
  width: 20px;
  height: 10px;
  background-color: ${colorPalette.primaryDark};
  clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
`;

export const CurrencyRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
  border-bottom: 1px solid #ffffff33;

  &:last-child {
    border-bottom: none;
  }
`;

export const CurrencyLabel = styled.span`
  color: ${colorPalette.blue300};
  ${typeScale.bodyXSMain};
`;

export const CurrencyValue = styled.span`
  color: ${colorPalette.blue200};
  ${typeScale.headingXSMain};
`;

export const Disclaimer = styled.p`
  margin: 0;
  padding: 8px 16px;
  color: ${colorPalette.blue300};
  ${typeScale.bodyXXS};
`;
