// scrollStore.ts
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ScrollState {
  atBottom: boolean;
  setAtBottom: (value: boolean) => void;
}

const useScrollStore = create<ScrollState>()(
  devtools((set) => ({
    atBottom: false,
    setAtBottom: (value) => set({ atBottom: value }),
  }))
);

export default useScrollStore;
