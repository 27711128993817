// ArticleSection.tsx
import React, { useEffect, useRef, useState } from 'react';
import {
    ArticleImage,
    ArticleTitle,
    ArticleDescription,
    ArticlesContainer,
    ScrollButton,
    ArticleItem,
    HeaderContainer,
    Title,
} from './articles.styles';
import CTAButton from '../../../../components/cta-button/cta-button';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import useIsMobile from '../../../../hooks/useIsMobile';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import { ARTICLES } from '../../../../config/articleConfig';

const Articles: React.FC = () => {
    const navigate = useCustomNavigate();
    const isMobile = useIsMobile();

    // References
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // States
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const updateScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollButtons();
        const handleResize = () => updateScrollButtons();
        window.addEventListener('resize', handleResize);

        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener('scroll', updateScrollButtons);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', updateScrollButtons);
            }
        };
    }, []);

    const scroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -300 : 300,
                behavior: 'smooth',
            });
            updateScrollButtons();
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
        setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
        const walk = (x - startX) * 1.5;
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
    };

    return (
        <ArticlesContainer>
            <HeaderContainer>
                <div className="d-flex align-items-center" style={{ gap: 4, cursor: isMobile ? "pointer" : "auto" }} onClick={isMobile ? () => { } : undefined}>
                    <Title>Wissenswertes</Title>
                    <MaterialIcon className="d-md-none d-inline" icon="chevron_forward" size={24} color={colorPalette.blue500} />
                </div>
                {!isMobile && (
                    <CTAButton
                        variant="ghost"
                        size="S"
                        label="Alle Artikel"
                        icon="arrow_outward"
                        onClick={() => { }}
                        noPadding
                    />
                )}
            </HeaderContainer>
            <div className="scroll-wrapper">
                {canScrollLeft && (
                    <ScrollButton className="scroll-left d-none d-md-inline" onClick={() => scroll('left')}>
                        <MaterialIcon icon="arrow_back" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
                <div
                    className="updates-container"
                    ref={scrollContainerRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUpOrLeave}
                    onMouseLeave={handleMouseUpOrLeave}
                >
                    {ARTICLES.map((article) => (
                        <ArticleItem key={article.id} onClick={() => navigate(`/articles/${article.id}`)}>
                            <ArticleImage src={article.image} alt={article.title} draggable={false} />
                            <ArticleTitle>{article.title}</ArticleTitle>
                            <ArticleDescription>{article.description}</ArticleDescription>
                        </ArticleItem>
                    ))}
                </div>
                {canScrollRight && (
                    <ScrollButton className="scroll-right d-none d-md-inline" onClick={() => scroll('right')}>
                        <MaterialIcon icon="arrow_forward" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
            </div>
        </ArticlesContainer>
    );
};

export default Articles;
