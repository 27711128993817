import { API_URL } from "../utils/constants";
import { ILease } from "./leaseService";
import { IUnit, IUnitOwner } from "./unitService";

export enum DocumentType {
  PASSPORT = "passport",
  PROOF_OF_ADDRESS = "proof-of-address",
  EMIRATES_ID = "emirates-id",
  VISA = "visa",
  TENANCY_CONTRACT = "tenancy-contract",
  PURCHASE_CONTRACT = "purchase-contract",
  TITLE_DEED = "title-deed",
  OQOOD = "oqood",
  POWER_OF_ATTORNEY = "power-of-attorney",
  PROPERTY_MANAGEMENT_CONTRACT = "property-management-contract",
}

export const formatDocumentType = (documentType: DocumentType | null) => {
  switch (documentType) {
    case DocumentType.PASSPORT:
      return "Reisepass";
    case DocumentType.PROOF_OF_ADDRESS:
      return "Wohnsitznachweis";
    case DocumentType.EMIRATES_ID:
      return "Emirates ID";
    case DocumentType.VISA:
      return "VISA";
    case DocumentType.TENANCY_CONTRACT:
      return "Mietvertrag";
    case DocumentType.PURCHASE_CONTRACT:
      return "Kaufvertrag";
    case DocumentType.TITLE_DEED:
      return "Title Deed";
    case DocumentType.OQOOD:
      return "Oqood";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Vollmacht";
    case DocumentType.PROPERTY_MANAGEMENT_CONTRACT:
      return "Verwaltungsvertrag";
    default:
      return "Unbekannt";
  }
};

export interface IDocument {
  document_id: number;
  document_type?: DocumentType | null;
  file_type: string;
  file_path: string;
  file_name: string;
  valid_until: string | null;
  internal_document: boolean;
  created_at: string;
  updated_at: string;
  unit?: IUnit | null;
  lease?: ILease | null;
  unit_owner?: IUnitOwner | null;
}

export const downloadDocument = async (
  documentId: number
): Promise<Blob | null> => {
  const response = await fetch(`${API_URL}/documents/${documentId}/download`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};

export const deleteDocument = async (document_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/documents/${document_id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return response.status === 200;
};
