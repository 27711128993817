import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useSearchStore from '../../stores/searchStore';
import InfoModal from '../../modals/info-modal/info-modal';
import useInvestorDetailStore from '../../stores/investorDetailStore';
import { SPACES_ENDPOINT } from '../../utils/constants';
import LogoLight from '../../assets/images/logo/logo-light.svg';
import LogoMulticolor from '../../assets/images/logo/logo-multicolor.svg';
import {
    Navbar,
    NavbarTitle,
    SearchContainer,
    SearchInput,
    SearchIcon,
    Avatar,
    TopbarLogo,
    AvatarDropdownItem,
    AvatarDropdownContainer,
    DropdownSubtitle,
    DropdownTitle,
    DropdownHeader,
    DropdownItemLabel,
    InitialsAvatar
} from './topbar.styles';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';
import { logout } from '../../services/authService';
import useConstructionUpdateStore from '../../stores/constructionUpdateStore';
import useUnitDetailStore from '../../stores/unitDetailStore';
import useDashboardStore from '../../stores/dashboardStore';
import useUnitStore from '../../stores/unitStore';
import { useLocation } from 'react-router-dom';
import { usePWA } from '../../context/PWAContext';
import useIsMobile from '../../hooks/useIsMobile';
import useCustomNavigate from '../../hooks/useCustomNavigate';

interface TopbarProps {
    isFullPage?: boolean;
    deactivateSidebar?: boolean;
    theme?: 'light' | 'dark';
    toggleSidebar: () => void;
}

const Topbar: React.FC<TopbarProps> = ({ isFullPage = false, deactivateSidebar = false, theme = "light", toggleSidebar }) => {
    const navigate = useCustomNavigate();
    const location = useLocation();
    const isPWA = usePWA();
    const isMobile = useIsMobile();

    // Stores
    const { investor, reset: resetInvestoreDetailStore } = useInvestorDetailStore();
    const { search, setSearch } = useSearchStore();
    const resetUnitStore = useUnitStore(state => state.reset);
    const resetUnitDetailStore = useUnitDetailStore(state => state.reset);
    const resetConstructionUpdateStore = useConstructionUpdateStore(state => state.reset);
    const resetDashboardStore = useDashboardStore(state => state.reset);

    // States
    const [isHistoryAvailable, setIsHistoryAvailable] = useState(false);
    const [title, setTitle] = useState<string>("");
    const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    // Set Title
    useEffect(() => {
        setIsSearchVisible(false);
        setTitle("Investorenportal");

        const historyState = window.history.state;
        setIsHistoryAvailable(historyState && historyState.idx > 0);
    }, [location]);

    const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, [setSearch]);

    // Functions
    const onClickLogout = async () => {
        await logout();

        resetUnitStore();
        resetConstructionUpdateStore();
        resetInvestoreDetailStore();
        resetUnitDetailStore();
        resetDashboardStore();

        navigate("/login", { replace: true, clearHistory: true });
    };

    // Memos
    const initials = useMemo(() => {
        if (!investor) return '';
        const names = `${investor.first_name} ${investor.last_name}`.split(' ');
        return names.slice(0, 2).map(name => name[0].toUpperCase()).join('');
    }, [investor]);

    const themeColor = useMemo(() => {
        if (theme === "dark") {
            return colorPalette.white;
        } else {
            return colorPalette.textMain;
        }
    }, [theme]);

    return (
        <Navbar className="navbar navbar-expand-lg navbar-dark" themeVariant={theme}>
            <div className="container-fluid d-flex justify-content-between">
                {isFullPage ? (
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        <TopbarLogo src={theme === "dark" ? LogoLight : LogoMulticolor} alt="Logo" onClick={() => navigate("/", { replace: true, clearHistory: true })} />
                    </div>

                ) : (
                    <div className="d-flex d-lg-none align-items-center" style={{ gap: 8 }}>
                        {isMobile && isHistoryAvailable && (
                            <MaterialIcon icon="arrow_back_ios" color={themeColor} size={20} onClick={() => navigate(-1)} />
                        )}
                        <TopbarLogo className="d-lg-none d-block" src={theme === "dark" ? LogoLight : LogoMulticolor} onClick={() => navigate("/", { replace: true, clearHistory: true })} />
                    </div>
                )}

                {!isFullPage && (
                    <div>
                        <NavbarTitle className="ms-2 d-none d-lg-block">{title}</NavbarTitle>
                    </div>
                )}

                <div className="d-flex align-items-center">
                    {isSearchVisible && (
                        <div className="d-none d-lg-flex align-items-center">
                            <SearchContainer className="me-3">
                                <SearchIcon />
                                <SearchInput
                                    type="search"
                                    placeholder="Suche nach..."
                                    aria-label="Search"
                                    value={search}
                                    onChange={handleSearchInputChange}
                                />
                            </SearchContainer>
                        </div>
                    )}
                    {/*<NotificationIcon
                        className="d-none d-lg-block"
                        onClick={() => setIsInfoModalVisible(true)}
                    />*/}

                    {!deactivateSidebar && !isPWA && (
                        <MaterialIcon className="d-flex d-lg-none" icon="menu" color={themeColor} size={28} onClick={toggleSidebar} />
                    )}

                    {isMobile && isPWA && investor && !location.pathname.includes("/profile") && (
                        <MaterialIcon icon="account_circle" color={themeColor} size={24} onClick={() => navigate("/profile", { replace: true, clearHistory: true })} />
                    )}

                    {/* Avatar and Dropdown */}
                    <div
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                        className="position-relative d-none d-lg-block"
                    >
                        {investor && (
                            investor?.avatar ? (
                                <Avatar showBorder={showDropdown} src={`${SPACES_ENDPOINT}${investor.avatar}`} onClick={() => navigate("/profile", { replace: true, clearHistory: true })} />
                            ) : (
                                <InitialsAvatar showBorder={showDropdown} onClick={() => navigate("/profile", { replace: true, clearHistory: true })}>{initials}</InitialsAvatar>
                            )
                        )}
                        {showDropdown && (
                            <AvatarDropdownContainer>
                                <DropdownHeader>
                                    <DropdownTitle className="mb-0">{investor?.first_name} {investor?.last_name}</DropdownTitle>
                                    <DropdownSubtitle>{investor?.email}</DropdownSubtitle>
                                </DropdownHeader>
                                <AvatarDropdownItem onClick={() => {
                                    setShowDropdown(false);
                                    navigate("/profile", { replace: true, clearHistory: true });
                                }}>
                                    <MaterialIcon icon="account_circle" size={20} color={colorPalette.textMain} />
                                    <DropdownItemLabel>Mein Account</DropdownItemLabel>
                                </AvatarDropdownItem>
                                <AvatarDropdownItem onClick={() => {
                                    setShowDropdown(false);
                                    navigate("/profile/settings");
                                }}>
                                    <MaterialIcon icon="manage_accounts" size={20} color={colorPalette.textMain} />
                                    <DropdownItemLabel>Einstellungen</DropdownItemLabel>
                                </AvatarDropdownItem>
                                <AvatarDropdownItem onClick={onClickLogout}>
                                    <MaterialIcon icon="logout" size={20} color={colorPalette.textMain} />
                                    <DropdownItemLabel>Abmelden</DropdownItemLabel>
                                </AvatarDropdownItem>
                            </AvatarDropdownContainer>
                        )}
                    </div>
                </div>
            </div>
            {isInfoModalVisible && (
                <InfoModal onClose={() => setIsInfoModalVisible(false)} />
            )}
        </Navbar>
    );
};

export default Topbar;
