import React, { HTMLInputAutoCompleteAttribute, useState } from 'react';
import { ErrorText, IconContainer, Input, InputContainer, InputWrapper, Label, SubtitleText, TextArea } from './custom-input-field.styles';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';

interface CustomInputFieldProps {
    className?: string;
    id: string;
    placeholder?: string;
    maxLength?: number;
    value: string;
    autoComplete?: HTMLInputAutoCompleteAttribute | undefined;
    onClear?: () => void;
    onChange: (value: string) => void;
    type?: 'text' | 'email' | 'password' | 'number';
    required?: boolean;
    disabled?: boolean;
    subtitle?: string;
    errorMessage?: string;
    isTextArea?: boolean;
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
    className,
    id,
    placeholder,
    maxLength,
    value,
    autoComplete,
    onChange,
    onClear,
    type = 'text',
    required = false,
    disabled = false,
    subtitle,
    errorMessage,
    isTextArea = false,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <InputContainer className={`${className} form-floating`}>
            {isTextArea ? (
                <TextArea
                    id={id}
                    className={value.length > 0 ? "form-control" : undefined}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    disabled={disabled}
                    isError={!!errorMessage}
                />
            ) : (
                <InputWrapper className="form-floating">
                    <Input
                        id={id}
                        className={value.length > 0 ? "form-control" : undefined}
                        type={showPassword && type === 'password' ? 'text' : type}
                        placeholder={`${placeholder} ${required ? "*" : ""}`}
                        autoComplete={autoComplete}
                        maxLength={maxLength}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        required={required}
                        disabled={disabled}
                        isError={!!errorMessage}
                    />
                    {value.length > 0 && (
                        <Label htmlFor={id}>{placeholder} {required ? "*" : ""}</Label>
                    )}
                    <IconContainer>
                        {type === 'password' && !isTextArea && (
                            <MaterialIcon
                                icon={showPassword ? "visibility_off" : "visibility"}
                                size={20}
                                color={colorPalette.textMain}
                                onClick={togglePasswordVisibility}
                            />
                        )}
                        {value && onClear && !disabled && (
                            <MaterialIcon
                                icon="close"
                                size={20}
                                color={colorPalette.textMain}
                                onClick={onClear}
                            />
                        )}
                    </IconContainer>
                </InputWrapper>
            )}
            {subtitle && !errorMessage && <SubtitleText>{subtitle}</SubtitleText>}
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </InputContainer>
    );
};

export default CustomInputField;
