import React, { useState } from 'react';
import {
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ImageCount,
    ImageTitle,
    NavigationButton,
    ArrowContainer,
    MobileCloseButton,
} from './image-modal.styles';
import { colorPalette } from '../../utils/theme';
import MaterialIcon from '../../components/material-icon';
import CTAButton from '../../components/cta-button/cta-button';
import useIsMobile from '../../hooks/useIsMobile';
import LazyLoadImage from '../../components/lazy-load-image/lazy-load-image';
import { useSwipeable } from 'react-swipeable';

interface ImageModalProps {
    images: string[];
    title: string;
    subtitle: string;
    isOpen: boolean;
    onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ images, title, subtitle, isOpen, onClose }) => {
    const isMobile = useIsMobile();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [closing, setClosing] = useState(false);

    const handleClose = () => {
        if (isMobile) {
            setClosing(true);
            setTimeout(() => {
                onClose();
                setClosing(false);
            }, 300);
        } else {
            onClose();
        }
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const handlers = useSwipeable({
        onSwipedDown: handleClose,
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrevious,
        delta: 30,
        trackMouse: true,
    });

    if (!isOpen) return null;

    return (
        <ModalOverlay closing={closing}>
            {isMobile && (
                <MobileCloseButton>
                    <MaterialIcon icon="close" size={24} color={colorPalette.white} onClick={handleClose} />
                </MobileCloseButton>
            )}
            <ModalContent {...handlers} closing={closing}>
                <ModalHeader>
                    <ImageCount>{currentImageIndex + 1} / {images.length}</ImageCount>
                    {!isMobile && (
                        <CTAButton
                            variant="ghost"
                            size="S"
                            icon="close"
                            label="Schließen"
                            color={colorPalette.white}
                            onClick={handleClose}
                        />
                    )}
                </ModalHeader>
                <ArrowContainer>
                    {images.length > 1 && (
                        <NavigationButton onClick={handlePrevious}>
                            <MaterialIcon icon="arrow_back" size={20} color={colorPalette.textMain} />
                        </NavigationButton>
                    )}
                    {isMobile ? (
                        <LazyLoadImage
                            margin="0 auto"
                            maxWidth="100%"
                            maxHeight="calc(100vh - 300px)"
                            src={images[currentImageIndex]}
                            alt={`Image ${currentImageIndex + 1}`}
                        />
                    ) : (
                        <LazyLoadImage
                            margin="0 auto"
                            height="calc(100vh - 300px)"
                            src={images[currentImageIndex]}
                            alt={`Image ${currentImageIndex + 1}`}
                        />
                    )}
                    {images.length > 1 && (
                        <NavigationButton onClick={handleNext}>
                            <MaterialIcon icon="arrow_forward" size={20} color={colorPalette.textMain} />
                        </NavigationButton>
                    )}
                </ArrowContainer>
                <ModalFooter>
                    <ImageTitle>
                        {title}
                        <span>{subtitle}</span>
                    </ImageTitle>
                </ModalFooter>
            </ModalContent>
        </ModalOverlay>
    );
};

export default ImageModal;
