import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Spinner } from "react-bootstrap";
import {
    PopupContainer,
    Arrow,
    CurrencyRow,
    CurrencyLabel,
    CurrencyValue,
    Disclaimer,
} from "./currency-conversion-popup.styles";
import { formatMoney } from "../../../../utils/helpers";
import { colorPalette } from "../../../../utils/theme";

interface CurrencyConversionPopupProps {
    amount: number;
    anchorElement: HTMLElement | null;
}

const CurrencyConversionPopup: React.FC<CurrencyConversionPopupProps> = ({ amount, anchorElement }) => {
    const [position, setPosition] = useState<{ top: number; left: number } | null>(null);
    const [conversionRates, setConversionRates] = useState<{ EUR: number; USD: number } | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (anchorElement) {
            const rect = anchorElement.getBoundingClientRect();
            const scrollX = window.scrollX || document.documentElement.scrollLeft;
            const scrollY = window.scrollY || document.documentElement.scrollTop;

            setPosition({
                // Adjust the top value to make it closer to the text
                top: rect.top + scrollY, // Adjust as necessary
                // Center the popup horizontally over the amount
                left: rect.left + scrollX + rect.width / 3,
            });
        }
    }, [anchorElement]);


    // Fetch current exchange rates and calculate position
    useEffect(() => {
        // Fetch conversion rates (keep your existing logic)
        const fetchConversionRates = async () => {
            setLoading(true);
            try {
                const response = await fetch("https://api.exchangerate-api.com/v4/latest/AED");
                if (!response.ok) {
                    throw new Error("Failed to fetch conversion rates.");
                }
                const data = await response.json();
                setConversionRates({
                    EUR: data.rates.EUR,
                    USD: data.rates.USD,
                });
            } catch (error) {
                console.error("Error fetching conversion rates:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchConversionRates();
    }, []);

    if (!position) return null;

    return ReactDOM.createPortal(
        <PopupContainer style={{ top: position.top, left: position.left }}>
            <Arrow />
            {loading ? (
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    size="sm"
                    aria-hidden="true"
                    style={{ margin: 16, color: colorPalette.blue300 }}
                />
            ) : (
                <>
                    <CurrencyRow>
                        <CurrencyLabel>AED</CurrencyLabel>
                        <CurrencyValue>{formatMoney(amount, false)}</CurrencyValue>
                    </CurrencyRow>
                    <CurrencyRow>
                        <CurrencyLabel>EUR</CurrencyLabel>
                        <CurrencyValue>{formatMoney(amount * (conversionRates?.EUR ?? 0), false)}</CurrencyValue>
                    </CurrencyRow>
                    <CurrencyRow>
                        <CurrencyLabel>USD</CurrencyLabel>
                        <CurrencyValue>{formatMoney(amount * (conversionRates?.USD ?? 0), false)}</CurrencyValue>
                    </CurrencyRow>
                    <Disclaimer>
                        Tageskurs vom {new Date().toLocaleDateString()}. Die Umrechnung dient
                        lediglich zur Information und stellt keine verbindliche Bestätigung dar.
                    </Disclaimer>
                </>
            )}
        </PopupContainer>,
        document.body
    );
};

export default CurrencyConversionPopup;
