import styled from "styled-components";
import { colorPalette, typeScale } from "../../../utils/theme";

export const ContractDetails = styled.div`
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue100};
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

export const ContractDetailItem = styled.div`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${colorPalette.blue200};

  &:last-child {
    border-bottom: none;
  }
`;

export const ContractDetailLabel = styled.div`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;

export const ContractDetailValue = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};
`;

export const CategoryTitle = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
  padding-top: 21px;
  padding-bottom: 21px;
`;

export const DocumentsContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue200};
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }
`;

export const ItemTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
`;

export const ItemSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.successMedium};
  ${typeScale.bodyXXSMain}
`;

export const ButtonWrapper = styled.div`
  margin: 16px 16px 36px 16px;
`;
