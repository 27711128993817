import React from 'react';
import {
    CardContainer,
    Header,
    Title,
    ContentContainer,
    UnitsList,
    UnitItem,
    Circle,
    UnitLabel,
    UnitDescription,
    ChartWrapper,
    CenterText,
} from './mobile-total-units-card.styles';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';

ChartJS.register(ArcElement, Tooltip, Legend);

interface MobileTotalUnitsCardProps {
    offPlanUnits: number;
    unrentedUnits: number;
    rentedUnits: number;
    totalUnits: number;
}

const MobileTotalUnitsCard: React.FC<MobileTotalUnitsCardProps> = ({
    offPlanUnits,
    unrentedUnits,
    rentedUnits,
    totalUnits,
}) => {
    const navigate = useCustomNavigate();

    const data = {
        labels: ['Off-Plan (In Bau)', 'Fertig gebaut', 'Vermietet'],
        datasets: [
            {
                data: [offPlanUnits, unrentedUnits, rentedUnits],
                backgroundColor: [
                    colorPalette.blue300, // Off-Plan
                    colorPalette.textMain, // Unrented
                    colorPalette.successMedium, // Rented
                ],
                borderWidth: 0,
                spacing: 4,
                hoverOffset: 2,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                callbacks: {
                    label: function (tooltipItem: any) {
                        const value = tooltipItem.raw;
                        const percentage = ((value / totalUnits) * 100).toFixed(1);
                        return `${tooltipItem.label}: ${value} (${percentage}%)`;
                    },
                },
            },
        },
        cutout: '90%',
        responsive: true,
        maintainAspectRatio: false,
    };

    return (
        <CardContainer onClick={() => navigate("/my-units")}>
            <Header>
                <Title>Einheiten im Portfolio</Title>
                <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
            </Header>
            <ContentContainer>
                <UnitsList>
                    <UnitItem>
                        <Circle color={colorPalette.blue300} />
                        <div>
                            <UnitLabel>{offPlanUnits} {offPlanUnits === 1 ? 'Einheit' : 'Einheiten'}</UnitLabel>
                            <UnitDescription>Off-Plan (In Bau)</UnitDescription>
                        </div>
                    </UnitItem>
                    <UnitItem>
                        <Circle color={colorPalette.textMain} />
                        <div>
                            <UnitLabel>{unrentedUnits} {unrentedUnits === 1 ? 'Einheit' : 'Einheiten'}</UnitLabel>
                            <UnitDescription>Fertig (Unvermietet)</UnitDescription>
                        </div>
                    </UnitItem>
                    <UnitItem>
                        <Circle color={colorPalette.successMedium} />
                        <div>
                            <UnitLabel>{rentedUnits} {rentedUnits === 1 ? 'Einheit' : 'Einheiten'}</UnitLabel>
                            <UnitDescription>Fertig (Vermietet)</UnitDescription>
                        </div>
                    </UnitItem>
                </UnitsList>
                <ChartWrapper>
                    <Doughnut data={data} options={options} />
                    <CenterText>
                        <div>{totalUnits}</div>
                        <div>Einheiten</div>
                        <div>(Gesamt)</div>
                    </CenterText>
                </ChartWrapper>
            </ContentContainer>
        </CardContainer>
    );
};

export default MobileTotalUnitsCard;
