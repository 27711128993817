import React from 'react';
import { MaterialSymbol } from 'material-symbols';
import MaterialIcon from '../material-icon';
import { ButtonContainer, SpinnerContainer } from './cta-button.styles';

interface ButtonProps {
    className?: string | undefined;
    variant: 'primary' | 'secondary' | 'tertiary' | 'ghost';
    size: 'L' | 'M' | 'S' | 'XS';
    label: string;
    disabled?: boolean;
    icon?: MaterialSymbol;
    iconStyle?: 'outlined' | 'round' | 'sharp' | 'two-tone' | 'filled';
    color?: string;
    fullWidth?: boolean;
    loading?: boolean;
    noPadding?: boolean;
    onClick?: () => void;
}

const CTAButton: React.FC<ButtonProps> = ({
    className,
    variant,
    size,
    label,
    disabled,
    icon,
    iconStyle = 'outlined',
    color,
    fullWidth = false,
    loading = false,
    noPadding = false,
    onClick,
}) => {
    return (
        <ButtonContainer
            className={className}
            variant={variant}
            color={color}
            size={size}
            fullWidth={fullWidth}
            disabled={disabled || loading}
            noPadding={noPadding}
            onClick={onClick}
        >
            {/* Render loading spinner if loading, else render label and icon */}
            {loading ? (
                <SpinnerContainer
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                />
            ) : (
                <>
                    {variant !== 'ghost' && icon && (
                        <MaterialIcon
                            icon={icon}
                            style={iconStyle}
                            size={24}
                            color={color}
                            marginRight="8px"
                        />
                    )}
                    {label}
                    {variant === 'ghost' && icon && (
                        <MaterialIcon
                            icon={icon}
                            style={iconStyle}
                            size={size === "L" ? 20 : 16}
                            color={color}
                            marginLeft={size === "L" ? "4px" : "2px"}
                        />
                    )}
                </>
            )}
        </ButtonContainer>
    );
};

export default CTAButton;
