import React from "react";
import MaterialIcon from "../material-icon";
import { MaterialSymbol } from "material-symbols";
import { IconWrapper, NavContainer, NavItem, NavItemLabel } from "./bottom-navigation-bar.styles";
import { useLocation } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import { usePWA } from "../../context/PWAContext";
import { colorPalette } from "../../utils/theme";
import useCustomNavigate from "../../hooks/useCustomNavigate";

interface NavItemProps {
    icon: MaterialSymbol;
    label: string;
    link: string;
}

const NAV_ITEMS: NavItemProps[] = [
    { icon: "grid_view", label: "Dashboard", link: "/" },
    { icon: "home_work", label: "Einheiten", link: "/my-units" },
    { icon: "table_chart_view", label: "Finanzen", link: "/financials" },
    { icon: "description", label: "Verträge", link: "/contracts" },
    { icon: "school", label: "Wissen", link: "/knowledge-hub" },
];

const NavigationBottomBar: React.FC = () => {
    const navigate = useCustomNavigate();
    const location = useLocation();
    const isMobile = useIsMobile();
    const isPWA = usePWA();

    if (!isMobile || !isPWA) return (<></>);

    const handleNavigate = (link: string) => {
        navigate(link, { replace: true, clearHistory: true });
    };

    return (
        <NavContainer>
            {NAV_ITEMS.map((item, index) => (
                <NavItem
                    key={index}
                    onClick={() => handleNavigate(item.link)}
                >
                    <IconWrapper>
                        <MaterialIcon icon={item.icon} size={24} color={location.pathname === item.link ? colorPalette.blue500 : colorPalette.textMain} />
                    </IconWrapper>
                    <NavItemLabel active={location.pathname === item.link}>{item.label}</NavItemLabel>
                </NavItem>
            ))}
        </NavContainer>
    );
};

export default NavigationBottomBar;
