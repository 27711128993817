import React from "react";
import { Container, Subtitle, Title } from "./total-profit.styles";
import { formatMoney } from "../../../../../../utils/helpers";

interface TotalProfitProps {
    totalProfit: number;
    year: string;
}

const TotalProfit: React.FC<TotalProfitProps> = ({ totalProfit, year }) => {
    return (
        <Container>
            <Title>AED {formatMoney(totalProfit)}</Title>
            <Subtitle>Gesamtgewinn {year}</Subtitle>
        </Container>
    );
};

export default TotalProfit;
