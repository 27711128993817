import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const CardContainer = styled.div`
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue200};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain}
  }
`;

export const ProgressContainer = styled.div`
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ProgressBar = styled.div`
  background-color: ${colorPalette.blue200};
  border-radius: 30px;
  height: 8px;
  margin-bottom: 16px;
  overflow: hidden;

  .progress {
    background: linear-gradient(90deg, #7FCCFA 80%, #276EF1 100%);
    height: 100%;
    border-radius: 8px;
  }

  @media (max-width: 767.98px) {
    height: 5px;
  }
`;

export const ProgressDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ProgressPercentage = styled.p`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXLMain}
`;

export const ProgressStatus = styled.p`
  margin: 0;
  color: ${colorPalette.primaryDark};
  ${typeScale.bodyXLMain}

  @media (max-width: 767.98px) {
    ${typeScale.bodySM}
  }
`;

export const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  border-top: 1px solid ${colorPalette.blue100};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const DetailLabel = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;

export const DetailValue = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain}
`;

export const LinkIcon = styled.div`
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
`;
