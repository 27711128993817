import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  margin-top: 42px;
  margin-bottom: 16px;

  @media (max-width: 767.98px) {
    margin-top: 32px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  cursor: pointer;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const Content = styled.div`
  padding-left: 12px;
  padding-right: 12px;
`;
