import styled from "styled-components";
import { colorPalette } from "../../../../utils/theme";

export const HorizontalScrollWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 12px;
  padding-left: 16px;
  padding-right: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0 calc(98%);
    scroll-snap-align: start;
  }
`;

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  gap: 8px;
`;

export const IndicatorDot = styled.div<{ isActive: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? colorPalette.primaryDark : colorPalette.blue300)};
  cursor: pointer;
  transition: background-color 0.3s ease;
`;
