import MaterialIcon from "../../../../../../components/material-icon";
import { formatMoney } from "../../../../../../utils/helpers";
import { colorPalette } from "../../../../../../utils/theme";
import { CardContainer, PackageHeader, TitleContainer, PackageIcon, PackageTitle, PackageSubtitle, PriceInfo, PriceAmount, PriceText, TooltipIcon, PriceInfoDetail, FeatureList, FeatureItem, IconWrapper, FeatureItemTitle, ViewMoreLink } from "./package-card.styles";
import LogoIconWhite from '../../../../../../assets/icons/logo-icon-white.png';
import LogoIconDark from '../../../../../../assets/icons/logo-icon-dark.png';

interface PackageCardProps {
    title: string;
    price: number;
    minFee: number;
    features: string[];
    onClickInfo: () => void;
    isPremium: boolean;
}

const PackageCard: React.FC<PackageCardProps> = ({
    title,
    price,
    minFee,
    features,
    onClickInfo,
    isPremium,
}) => (
    <CardContainer>
        <PackageHeader premium={isPremium}>
            <TitleContainer>
                <PackageIcon src={isPremium ? LogoIconWhite : LogoIconDark} alt="logo" />
                <PackageTitle>{title}</PackageTitle>
            </TitleContainer>
            <PackageSubtitle premium={isPremium}>Die Premiumlösung für Investoren, die</PackageSubtitle>
            <PriceInfo>
                <PriceAmount>{price} %</PriceAmount> <PriceText>der Jahresmiete</PriceText>
                <TooltipIcon onClick={onClickInfo}>
                    <MaterialIcon icon="info" color={isPremium ? colorPalette.blue600 : colorPalette.blue200} />
                </TooltipIcon>
            </PriceInfo>
            <PriceInfoDetail premium={isPremium}>Mindestens AED {formatMoney(minFee)}</PriceInfoDetail>
            <PriceInfoDetail premium={isPremium}>
                zzgl. einmaliger <u>Handover Fee und Kautionen</u> • Jährliche Abrechnung
            </PriceInfoDetail>
        </PackageHeader>
        <FeatureList>
            {features.map((feature, index) => (
                <FeatureItem key={index}>
                    <div className="d-flex align-items-center gap-1">
                        <IconWrapper premium={isPremium}>
                            <MaterialIcon icon="check" size={20} color={colorPalette.white} />
                        </IconWrapper>
                        <FeatureItemTitle>{feature}</FeatureItemTitle>
                    </div>
                </FeatureItem>
            ))}
        </FeatureList>
        <ViewMoreLink href="#">
            Alle Leistungen ansehen
        </ViewMoreLink>
    </CardContainer>
);

export default PackageCard;
