import styled, { css, keyframes } from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

const fadeInSlideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 32px;
`;

export const Title = styled.h4`
  margin: 0;
  padding: 16px 12px;
  color: ${colorPalette.primaryDark};
  ${typeScale.headingLMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const AnimatedSection = styled.div<{ isMounted: boolean; delay: number }>`
  opacity: 0;
  transform: translateY(20px);
  ${({ isMounted, delay }) =>
    isMounted &&
    css`
      animation: ${fadeInSlideUp} 800ms ease forwards;
      animation-delay: ${delay}ms;
    `}
`;
