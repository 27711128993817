import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #ffffffb2;
  border: 1px solid ${colorPalette.blue200};
  box-shadow: 0px 0px 20px 0px #0000001a;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
`;

export const BackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(20px);
  z-index: 1;
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const Amount = styled.p`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain}
`;

export const GrossReturn = styled.p`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXSMain}
`;

export const Label = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;

export const Transactions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  border-top: 1px solid ${colorPalette.blue300};
`;

export const TransactionAmount = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM}
`;

export const TransactionTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain}
`;
