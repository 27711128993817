import React, { useCallback } from 'react';
import {
    SectionListContainer,
    SectionItem,
    SectionText,
    ContentSpacing,
    Container,
    HeaderTitle,
} from './about-us-list.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import useCustomNavigate from '../../../../hooks/useCustomNavigate';
import { usePWA } from '../../../../context/PWAContext';

const AboutUsList: React.FC = () => {
    const navigate = useCustomNavigate();
    const isPWA = usePWA();

    const handleNavigation = useCallback((url: string) => {
        if (isPWA) {
            navigate(url);
        } else {
            window.open(url, "_blank");
        }
    }, [isPWA]);

    return (
        <Container>
            <HeaderTitle>Über managd</HeaderTitle>
            <SectionListContainer>
                <SectionItem onClick={() => alert("Coming soon...")}>
                    <SectionText>Über uns</SectionText>
                    <ContentSpacing>
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </ContentSpacing>
                </SectionItem>

                <SectionItem onClick={() => navigate("/faq")}>
                    <SectionText>FAQ</SectionText>
                    <ContentSpacing>
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </ContentSpacing>
                </SectionItem>

                <SectionItem onClick={() => handleNavigation("/terms-and-conditions")}>
                    <SectionText>Allgemeine Geschäftsbedingungen</SectionText>
                    <ContentSpacing>
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </ContentSpacing>
                </SectionItem>

                <SectionItem onClick={() => handleNavigation("/privacy-policy")}>
                    <SectionText>Datenschutzerklärung</SectionText>
                    <ContentSpacing>
                        <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                    </ContentSpacing>
                </SectionItem>
            </SectionListContainer>
        </Container>
    );
};

export default AboutUsList;
