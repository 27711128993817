import React, { useEffect, useRef, useState } from 'react';
import { Placeholder } from './lazy-load-image.styles';
import LightLogoIcon from '../../assets/icons/logo-icon-light.png';

interface LazyLoadImageProps {
    src: string;
    alt: string;
    margin?: string | number;
    maxWidth?: string | number;
    maxHeight?: string | number;
    width?: string | number;
    height?: string | number;
    borderRadius?: string;
    objectPosition?: string;
    onClick?: () => void;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
    src,
    alt,
    margin,
    maxWidth,
    maxHeight,
    width,
    height,
    borderRadius,
    objectPosition,
    onClick,
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);

    const handleLoad = () => {
        setIsLoaded(true);
        setHasError(false);
    };

    const handleError = () => {
        setHasError(true);
        setIsLoaded(false);
    };

    // Lazy-load image using IntersectionObserver
    useEffect(() => {
        const imgElement = imgRef.current;
        if (!imgElement) return;

        observer.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    imgElement.src = src; // Trigger image loading
                    observer.current?.disconnect();
                }
            });
        });

        observer.current.observe(imgElement);

        return () => {
            observer.current?.disconnect();
        };
    }, [src]);

    return (
        <div
            style={{
                position: 'relative',
                width,
                height,
                maxWidth,
                maxHeight,
                margin,
                borderRadius,
            }}
        >
            {/* Placeholder: visible until image is fully loaded */}
            {!isLoaded && !hasError && (
                <Placeholder
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: borderRadius || 0,
                    }}
                >
                    <img
                        src={LightLogoIcon}
                        alt="Placeholder image"
                        style={{ width: 44, height: 44 }}
                    />
                </Placeholder>
            )}

            {/* Actual image: hidden until fully loaded */}
            <img
                ref={imgRef}
                alt={alt}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: objectPosition,
                    opacity: isLoaded && !hasError ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                    borderRadius: borderRadius || 0,
                    ...(onClick ? { cursor: 'pointer' } : {}),
                }}
                onLoad={handleLoad}
                onError={handleError}
                onClick={onClick}
                draggable={false}
            />
        </div>
    );
};

export default LazyLoadImage;
