import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const DetailItemContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "hideBottomBorder" && prop !== "removeHorizontalPadding",
})<{ hideBottomBorder: boolean; removeHorizontalPadding: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px
    ${({ removeHorizontalPadding }) => (removeHorizontalPadding ? "0" : "16px")}
    24px
    ${({ removeHorizontalPadding }) => (removeHorizontalPadding ? "0" : "16px")};
  border-bottom: ${({ hideBottomBorder }) =>
    hideBottomBorder ? "none" : `1px solid ${colorPalette.blue200}`};
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.bodySM};
`;

export const Value = styled.div`
  color: ${colorPalette.textMain};
  ${typeScale.bodyMDMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain};
  }
`;
