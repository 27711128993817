import React, { useCallback } from 'react';
import {
    FooterContainer,
    FooterLink,
    FooterLinksWrapper,
    FooterCopyright,
    FooterLogo,
    FooterContent,
    MobileFooterContainer,
    MobileFooterLinksWrapper,
    MobileFooterCopyright
} from './footer.styles';
import LogoDark from '../../assets/images/logo/logo-dark.svg';
import LogoLight from '../../assets/images/logo/logo-light.svg';
import LogoMulticolor from '../../assets/images/logo/logo-multicolor.svg';
import useIsMobile from '../../hooks/useIsMobile';
import { usePWA } from '../../context/PWAContext';

interface FooterProps {
    theme?: 'dark' | 'light' | 'white';
}

const Footer: React.FC<FooterProps> = ({ theme = 'white' }) => {
    const isMobile = useIsMobile();
    const isPWA = usePWA();

    const getLogo = useCallback(() => {
        if (theme === "dark") return LogoLight;
        else if (theme === "light") return LogoDark;
        return LogoMulticolor;
    }, [theme]);

    if (isMobile && !isPWA) {
        return (
            <MobileFooterContainer themeVariant={theme}>
                <MobileFooterLinksWrapper>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Über Uns</FooterLink>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Services</FooterLink>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Kontakt</FooterLink>
                    <FooterLink href="/faq" target="_blank" rel="noopener noreferrer" themeVariant={theme}>FAQ</FooterLink>
                    <FooterLink href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" themeVariant={theme}>AGB's</FooterLink>
                    <FooterLink href="/privacy-policy" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Datenschutzerklärung</FooterLink>
                </MobileFooterLinksWrapper>
                <MobileFooterCopyright themeVariant={theme}>
                    © 2025 - Managd Property Management LLC
                </MobileFooterCopyright>
            </MobileFooterContainer>
        );
    }

    return (
        <FooterContainer themeVariant={theme}>
            <FooterContent themeVariant={theme}>
                <FooterLogo src={getLogo()} alt="Managd Logo" />
                <FooterLinksWrapper>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Über Uns</FooterLink>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Services</FooterLink>
                    <FooterLink href="#" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Kontakt</FooterLink>
                    <FooterLink href="/faq" target="_blank" rel="noopener noreferrer" themeVariant={theme}>FAQ</FooterLink>
                    <FooterLink href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" themeVariant={theme}>AGB's</FooterLink>
                    <FooterLink href="/privacy-policy" target="_blank" rel="noopener noreferrer" themeVariant={theme}>Datenschutzerklärung</FooterLink>
                </FooterLinksWrapper>
            </FooterContent>
            <FooterCopyright themeVariant={theme}>
                © 2025 - Managd Property Management LLC
            </FooterCopyright>
        </FooterContainer>
    );
};

export default Footer;
