import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Container = styled.div``;

export const Headline = styled.h4`
  margin-bottom: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXXSMain}
`;

export const LeaseCardContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  background-color: ${colorPalette.white};
  border-radius: 8px;
  border: 1px solid ${colorPalette.blue100};
  padding: 24px 16px;
  position: relative;
`;

export const LeaseDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorPalette.blue200};
  padding-bottom: 16px;
`;

export const LeaseInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const LeaseTitle = styled.h4`
  margin-bottom: 4px;
  color: ${colorPalette.textMain};
  ${typeScale.headingXXSMain}
`;

export const LeaseSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS}
`;

export const LeaseAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const LeaseAmount = styled.h4`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.headingXXSMain}
`;

export const LeasePercentageChange = styled.p`
  margin: 0;
  color: ${colorPalette.successMedium};
  ${typeScale.bodyXSMain}
`;

export const LeaseContractContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-top: 16px;
  cursor: pointer;
`;

export const LeaseContractLink = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  color: ${colorPalette.textMain};
  cursor: pointer;
`;

export const LeaseContractText = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};
`;

export const ArrowIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.blue100};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  img {
    width: 50%;
    height: 50%;
  }
`;
