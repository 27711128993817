// tab-bar.styles.ts
import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

export const TabBarContainer = styled.div`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  background-color: ${colorPalette.white};
  border-bottom: 1px solid ${colorPalette.blue300};
  margin-bottom: 16px;
  overflow-x: auto;
  white-space: nowrap;
  gap: 16px;

  position: sticky;
  top: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabButton = styled.button<{ active: boolean }>`
  padding: 24px 0 16px 0;
  background-color: transparent;
  color: ${({ active }) =>
    active ? colorPalette.textMain : colorPalette.textDark};
  border: none;
  cursor: pointer;
  text-align: center;
  border-bottom: ${({ active }) =>
    active ? `3px solid ${colorPalette.blue500}` : "none"};
  ${({ active }) => (active ? typeScale.bodyLGMain : typeScale.bodyLG)};
  white-space: nowrap;

  &:hover {
    background-color: ${colorPalette.blue50};
  }

  &:focus {
    background-color: transparent;
  }
`;
