import { styled } from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  overflow-y: visible;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;

  position: relative; /* Necessary for dropdown positioning */
  z-index: 1; /* Ensure dropdown appears on top of this container */

  /* Hide scrollbar for better mobile appearance */
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CategoryTitle = styled.h4`
  margin-bottom: 8px;
  padding-left: 16px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS}
  text-transform: uppercase;
`;
