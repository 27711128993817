import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../../../utils/theme";

export const ListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${colorPalette.blue200};

  &:last-child {
    border-bottom: none;
  }
`;

export const IconContainer = styled.div`
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${colorPalette.blue100};
`;

export const ListItemDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 45%;

  @media (max-width: 767.98px) {
    width: auto;
  }
`;

export const ListItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ListItemTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXSMain};
`;

export const ListItemSubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;

export const ListItemProperty = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  text-align: center;
  width: 30%;
  ${typeScale.bodyXS};

  @media (max-width: 767.98px) {
    text-align: left;
    width: 100%;
  }
`;

export const ListItemAmount = styled.div`
  width: 15%;
  text-align: right;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXSMain};

  &.positive {
    color: ${colorPalette.successMedium};
  }

  &.negative {
    color: ${colorPalette.textMain};
  }

  @media (max-width: 767.98px) {
    width: auto;
  }
`;

export const ListItemStatus = styled.p`
  margin: 0;
  text-align: end;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS};
`;
