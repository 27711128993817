import React, { useEffect, useRef, useState } from 'react';
import {
    CardContainer,
    SliderControl,
    DetailsSection,
    AttributeRow,
    AttributeItem,
    AttributeIcon,
    AttributeLabel,
    PurchaseInfoRow,
    PurchaseInfoLabel,
    PurchaseInfoValue,
    UnitStatistic,
    UnitStatisticLabel,
    UnitStatistics,
    CarouselIndicator,
    CarouselIndicators,
} from './unit-details-card.styles';
import MaterialIcon from '../../../../components/material-icon';
import { colorPalette } from '../../../../utils/theme';
import { formatMoney } from '../../../../utils/helpers';
import ImageModal from '../../../../modals/image-modal/image-modal';
import LazyLoadImage from '../../../../components/lazy-load-image/lazy-load-image';
import useIsMobile from '../../../../hooks/useIsMobile';
import { Carousel } from 'bootstrap';

interface UnitDetailsCardProps {
    images: string[];
    bedrooms: number;
    bathrooms: number;
    size: number;
    purchaseDate: string;
    purchasePrice: number;
    unit_number: string;
    property_name: string;
}

const UnitDetailsCard: React.FC<UnitDetailsCardProps> = ({
    images,
    bedrooms,
    bathrooms,
    size,
    purchaseDate,
    purchasePrice,
    unit_number,
    property_name,
}) => {
    const isMobile = useIsMobile();
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const carouselRef = useRef<HTMLDivElement | null>(null);
    const carouselInstanceRef = useRef<Carousel | null>(null);

    useEffect(() => {
        if (carouselRef.current) {
            carouselInstanceRef.current = new Carousel(carouselRef.current, {
                interval: false,
                ride: false,
            });

            // Listen to the carousel's slide event
            const handleSlide = () => {
                const activeElement = carouselRef.current?.querySelector('.carousel-item.active');
                if (activeElement) {
                    const carouselItems = Array.from(carouselRef.current!.querySelectorAll('.carousel-item'));
                    const newIndex = carouselItems.indexOf(activeElement);
                    setActiveIndex(newIndex);
                }
            };

            carouselRef.current.addEventListener('slid.bs.carousel', handleSlide);

            return () => {
                carouselRef.current?.removeEventListener('slid.bs.carousel', handleSlide);
                carouselInstanceRef.current?.dispose();
            };
        }
    }, []);

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            .carousel-item {
                transition: transform 0.4s ease-in-out !important;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return (
        <>
            <CardContainer>
                {isMobile && (
                    <UnitStatistics>
                        <UnitStatistic>
                            <MaterialIcon icon="bed" size={20} color={colorPalette.blue300} />
                            <UnitStatisticLabel>{bedrooms} Bedroom</UnitStatisticLabel>
                        </UnitStatistic>
                        <UnitStatistic>
                            <MaterialIcon icon="straighten" size={20} color={colorPalette.blue300} />
                            <UnitStatisticLabel>{size} Sqft</UnitStatisticLabel>
                        </UnitStatistic>
                        <UnitStatistic>
                            <MaterialIcon icon="shoppingmode" size={20} color={colorPalette.blue300} />
                            <UnitStatisticLabel>AED {formatMoney(purchasePrice, false)}</UnitStatisticLabel>
                        </UnitStatistic>
                    </UnitStatistics>
                )}
                <div
                    id="carouselExample"
                    className="carousel slide"
                    ref={carouselRef}
                >
                    {images.length > 1 && (
                        <>
                            {/* Indicators */}
                            <CarouselIndicators>
                                {images.map((_, index) => (
                                    <CarouselIndicator
                                        data-bs-target="#carouselExample"
                                        data-bs-slide-to={index}
                                        aria-label={`Slide ${index + 1}`}
                                        className={activeIndex === index ? 'active' : ''}
                                        key={`indicator-${index}`}
                                    />
                                ))}
                            </CarouselIndicators>
                        </>
                    )}

                    <div className="carousel-inner">
                        {images.map((src, index) => (
                            <div
                                className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                                key={`image-${index}`}
                            >
                                <LazyLoadImage
                                    src={src}
                                    alt={`Unit Image ${index + 1}`}
                                    width="100%"
                                    height="350px"
                                    onClick={() => setIsImageModalOpen(true)}
                                />
                            </div>
                        ))}
                    </div>
                    {images.length > 1 && (
                        <>
                            {/* Previous and Next Controls */}
                            <SliderControl
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="prev"
                            >
                                <MaterialIcon icon="arrow_back" size={20} color={colorPalette.textMain} />
                            </SliderControl>
                            <SliderControl
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselExample"
                                data-bs-slide="next"
                            >
                                <MaterialIcon icon="arrow_forward" size={20} color={colorPalette.textMain} />
                            </SliderControl>
                        </>
                    )}
                </div>
                {!isMobile && (
                    <DetailsSection>
                        <AttributeRow>
                            <AttributeItem>
                                <AttributeIcon>
                                    <MaterialIcon icon="bed" size={24} color={colorPalette.blue300} />
                                </AttributeIcon>
                                <AttributeLabel>{bedrooms} Bedroom</AttributeLabel>
                            </AttributeItem>
                            <AttributeItem>
                                <AttributeIcon>
                                    <MaterialIcon icon="bathtub" size={24} color={colorPalette.blue300} />
                                </AttributeIcon>
                                <AttributeLabel>{bathrooms} Bathroom</AttributeLabel>
                            </AttributeItem>
                            <AttributeItem>
                                <AttributeIcon>
                                    <MaterialIcon icon="straighten" size={24} color={colorPalette.blue300} />
                                </AttributeIcon>
                                <AttributeLabel>{size} Sqft</AttributeLabel>
                            </AttributeItem>
                        </AttributeRow>
                        <PurchaseInfoRow>
                            <PurchaseInfoLabel>
                                <MaterialIcon icon="event_available" size={20} color={colorPalette.blue600} />
                                Kaufdatum
                            </PurchaseInfoLabel>
                            <PurchaseInfoValue>{purchaseDate}</PurchaseInfoValue>
                        </PurchaseInfoRow>
                        <PurchaseInfoRow>
                            <PurchaseInfoLabel>
                                <MaterialIcon icon="shopping_bag" size={20} color={colorPalette.blue600} />
                                Kaufpreis
                            </PurchaseInfoLabel>
                            <PurchaseInfoValue>AED {formatMoney(purchasePrice, false)}</PurchaseInfoValue>
                        </PurchaseInfoRow>
                    </DetailsSection>
                )}
            </CardContainer>

            <ImageModal
                images={images}
                title={`Unit ${unit_number}`}
                subtitle={property_name}
                isOpen={isImageModalOpen}
                onClose={() => setIsImageModalOpen(false)}
            />
        </>
    );
};

export default UnitDetailsCard;
