import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Container,
    ActionsContainer,
    ContentContainer,
    Overlay,
    Header,
    HeaderTitle,
    CategoryTitle,
    FileCard,
    FileName,
    EmptyState,
    CategorySubtitle,
} from './document-upload-modal.styles';
import { colorPalette } from '../../utils/theme';
import MaterialIcon from '../../components/material-icon';
import CTAButton from '../../components/cta-button/cta-button';
import { DocumentType, formatDocumentType } from '../../services/documentService';
import DateSelect from '../../components/date-select/date-select';
import { usePWA } from '../../context/PWAContext';

export interface DocumentUploadDialog {
    documentType: DocumentType;
    enableDocumentExpiry?: boolean;
}

interface DocumentUploadModalProps {
    documentType: DocumentType;
    enableDocumentExpiry?: boolean;
    onClickUpload: (file: File, expiryDate: Date | null) => void;
    onClose: () => void;
}

const DocumentUploadModal: React.FC<DocumentUploadModalProps> = ({
    documentType,
    enableDocumentExpiry = false,
    onClickUpload,
    onClose,
}) => {
    const isPWA = usePWA();

    // References
    const fileInputRef = useRef<HTMLInputElement>(null);

    // States
    const [expiryDate, setExpiryDate] = useState<Date | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    // Prevent scrolling down
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        const preventDefault = (e: TouchEvent) => {
            e.preventDefault();
        };

        document.addEventListener('touchmove', preventDefault, { passive: false });

        return () => {
            document.body.style.overflow = 'unset';
            document.removeEventListener('touchmove', preventDefault);
        };
    }, []);

    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file && (file.type === 'application/pdf' || file.type.startsWith('image/'))) {
            setSelectedFile(file);
        } else {
            alert('Only PDF and image files are allowed.');
        }
    };

    const openFileChooser = () => {
        fileInputRef.current?.click();
    };

    const onHandleUploadClick = useCallback(() => {
        if (selectedFile) {
            onClickUpload(selectedFile, expiryDate);
        }
    }, [selectedFile, expiryDate]);

    return (
        <Overlay onClick={onClose}>
            <Container onClick={(e) => e.stopPropagation()}>
                <Header>
                    <HeaderTitle>{formatDocumentType(documentType)} hochladen</HeaderTitle>
                    <MaterialIcon icon="close" size={24} color={colorPalette.blue500} onClick={onClose} />
                </Header>
                <ContentContainer>
                    {/*<CategoryTitle>Ausgewählte Datei</CategoryTitle>*/}
                    {documentType === DocumentType.PROOF_OF_ADDRESS && (
                        <CategorySubtitle>Bitte lade als Nachweis deines Wohnsitzes eine aktuelle Verbrauchsrechnung (z.B. Strom, Gas, Wasser) hoch, die <strong>nicht älter als 3 Monate</strong> ist.</CategorySubtitle>
                    )}

                    {selectedFile ? (
                        <FileCard>
                            <MaterialIcon icon="task" size={24} color={colorPalette.successMedium} />
                            <FileName>{selectedFile.name}</FileName>
                            <CTAButton
                                variant="ghost"
                                size="S"
                                icon="task"
                                label="Erneut hochladen"
                                onClick={() => setSelectedFile(null)}
                            />
                        </FileCard>
                    ) : (
                        <EmptyState>
                            <CTAButton
                                variant="ghost"
                                size="S"
                                icon="description"
                                label="Datei auswählen"
                                onClick={openFileChooser}
                            />
                            <input
                                id="file-upload"
                                type="file"
                                accept=".pdf, image/*"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </EmptyState>
                    )}

                    {enableDocumentExpiry && (
                        <>
                            <CategoryTitle style={{ marginTop: 16 }}>Gültigkeitsdatum</CategoryTitle>
                            <DateSelect
                                id="upload-document-expiry-date"
                                label="Ablaufdatum"
                                placeholder="Datum auswählen"
                                date={expiryDate}
                                onChange={setExpiryDate}
                                labelOnTop
                            />
                        </>
                    )}
                </ContentContainer>
                <ActionsContainer isPWA={isPWA}>
                    <CTAButton
                        variant="primary"
                        size="L"
                        label="Dokument hochladen"
                        fullWidth
                        onClick={onHandleUploadClick}
                        disabled={!selectedFile || (enableDocumentExpiry && !expiryDate)}
                    />
                    <CTAButton
                        variant="secondary"
                        size="L"
                        label="Abbrechen"
                        fullWidth
                        onClick={onClose}
                    />
                </ActionsContainer>
            </Container>
        </Overlay>
    );
};

export default DocumentUploadModal;
