import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const TabBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: white;
  border-bottom: 1px solid ${colorPalette.blue300};
  color: ${colorPalette.white};
  transition: background-color 0.3s ease;
`;

export const LogoContainer = styled.div`
  img {
    width: 120px;
  }
`;

export const TabList = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const HeroSection = styled.section`
  margin-left: 70px;
  margin-right: 70px;
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const Headline = styled.h1`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingLMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingMain};
  }
`;

export const Subtitle = styled.h1`
  margin-bottom: 8px;
  color: ${colorPalette.textDark};
  ${typeScale.headingXS};

  @media (max-width: 767.98px) {
    ${typeScale.bodySM};
  }
`;

// Features Section
export const FeatureSection = styled.section`
  margin-left: 70px;
  margin-right: 70px;
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid ${colorPalette.blue200};

  @media (max-width: 767.98px) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const FeatureImage = styled.img`
  width: 100px;
  margin-bottom: 18px;
  cursor: pointer;
`;

export const FeatureHeadline = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain}
  }
`;

export const FeatureDescription = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM}

  @media (max-width: 767.98px) {
    margin-bottom: 40px;
  }
`;

// Package Section
export const PackageSection = styled.section`
  background: ${colorPalette.blue50};
  padding: 60px 70px;
  text-align: center;

  @media (max-width: 767.98px) {
    padding: 30px 16px;
    background: none;
  }
`;

export const PackageSectionHeadline = styled.h4`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    text-align: start;
  }
`;

export const PackageSectionDescription = styled.p`
  margin-bottom: 32px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyMD};

  @media (max-width: 767.98px) {
    text-align: start;
    ${typeScale.bodySM};
  }
`;

export const HorizontalScrollWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0 calc(98%);
    scroll-snap-align: start;
  }
`;

export const PackageButtonWrapper = styled.div`
  margin-top: 32px;
  justify-self: center;
  width: 400px;

  @media (max-width: 767.98px) {
    margin-top: 24px;
    width: 100%;
  }
`;

export const PackageSectionFooterText = styled.p`
  margin-top: 32px;
  margin-bottom: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXSMain}

  span {
    cursor: pointer;
    color: ${colorPalette.blue500};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767.98px) {
    text-align: left;
    margin-top: 24px;
    color: ${colorPalette.textDark};
  }
`;

// FAQ Section
export const FAQSection = styled.section`
  margin-left: 70px;
  margin-right: 70px;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 767.98px) {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const FAQSectionBadge = styled.p`
  margin-bottom: 4px;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXSMain}
`;

export const FAQSectionHeadline = styled.h4`
  margin-bottom: 28px;
  color: ${colorPalette.textMain};
  ${typeScale.headingLMain}

  @media (max-width: 767.98px) {
    margin-bottom: 16px;
    ${typeScale.headingMain}
  }
`;

export const FAQSectionDescription = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyLG}

  @media (max-width: 767.98px) {
    margin-bottom: 24px;
    ${typeScale.bodySM}
  }
`;
