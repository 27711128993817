import React, { useCallback, useEffect, useState } from 'react';
import {
    SidebarModalBackdrop,
    SidebarModal,
    SidebarModalHeadline,
    SidebarModalDivider,
    SidebarModalScrollableContent,
    HeadlineContainer,
    SidebarModalHeader,
    SidebarModalSubtitle,
} from '../sidemodal.styles';
import { downloadLeaseDocument, ILease, PaymentPurpose } from '../../../services/leaseService';
import MaterialIcon from '../../../components/material-icon';
import { colorPalette } from '../../../utils/theme';
import CTAButton from '../../../components/cta-button/cta-button';
import { ContractDetailItem, ContractDetailLabel, ContractDetailValue, ContractDetails, ButtonWrapper, CategoryTitle, CardContainer, Item, ItemTitle, ItemSubtitle, DocumentsContainer } from './active-tenancy-contract-sidemodal.styles';
import useAlertStore from '../../../stores/alertStore';
import { formatMoney } from '../../../utils/helpers';
import { usePWA } from '../../../context/PWAContext';
import { Spinner } from 'react-bootstrap';
import { formatDocumentType, IDocument } from '../../../services/documentService';

interface ActiveTenancyContractSidebarModalProps {
    lease: ILease;
    unit_number: string;
    property_name: string;
    bedroom: number;
    square_feet: number;
    isVisible: boolean;
    onClose: () => void;
}

const ActiveTenancyContractSidebarModal: React.FC<ActiveTenancyContractSidebarModalProps> = ({
    lease,
    unit_number,
    property_name,
    bedroom,
    square_feet,
    isVisible,
    onClose
}) => {
    // Stores
    const isPWA = usePWA();
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [downloadingStates, setDownloadingStates] = useState<{ [documentId: number]: boolean }>({});

    // Prevent scrolling down
    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';

            const preventBackgroundScroll = (e: TouchEvent) => {
                const modalContent = (e.target as HTMLElement).closest('.sidebar-modal-content') as HTMLElement;

                if (
                    !modalContent ||
                    (modalContent.scrollHeight <= modalContent.clientHeight)
                ) {
                    e.preventDefault();
                }
            };

            document.addEventListener('touchmove', preventBackgroundScroll, { passive: false });

            return () => {
                document.body.style.overflow = 'unset';
                document.removeEventListener('touchmove', preventBackgroundScroll);
            };
        }
    }, [isVisible]);

    // Functions
    const onDownloadLeaseDocument = useCallback(async (leaseDocument: IDocument) => {
        if (!lease || lease.documents.length === 0) return;
    
        try {
            setDownloadingStates((prev) => ({
                ...prev,
                [leaseDocument.document_id]: true,
            }));
    
            const blob: Blob | null = await downloadLeaseDocument(lease.lease_id, leaseDocument.document_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);
                const isViewable = /image|pdf/.test(blob.type);
    
                if (isViewable && !/Mobi/i.test(navigator.userAgent)) {
                    window.open(blobUrl, '_blank');
                } else {
                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = leaseDocument.file_name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            }
        } catch (error) {
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setDownloadingStates((prev) => ({
                ...prev,
                [leaseDocument.document_id]: false,
            }));
        }
    }, [lease, showAlert]);

    return (
        <SidebarModalBackdrop visible={isVisible} onClick={onClose}>
            <SidebarModal visible={isVisible} isPWA={isPWA}>
                <SidebarModalHeader>
                    <HeadlineContainer>
                        <SidebarModalHeadline>Aktueller Mietvertrag</SidebarModalHeadline>
                        <SidebarModalSubtitle>{unit_number} • {property_name} • {bedroom} Bedroom • {square_feet} Sqft.</SidebarModalSubtitle>
                    </HeadlineContainer>
                    <MaterialIcon icon="close" size={24} color={colorPalette.blue500} onClick={onClose} />
                </SidebarModalHeader>
                <SidebarModalDivider />
                <SidebarModalScrollableContent withDefaultPadding={false} isPWA={isPWA} className="sidebar-modal-content">
                    <ContractDetails>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="location_away" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Mieter</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>{lease.tenant.first_name} {lease.tenant.last_name}</ContractDetailValue>
                        </ContractDetailItem>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="local_atm" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Mieteinnahmen</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>AED {formatMoney(lease.annual_rent_amount, false)}</ContractDetailValue>
                        </ContractDetailItem>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="event_upcoming" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Startdatum Mietvertrag</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>{new Date(lease.start_date).toLocaleDateString()}</ContractDetailValue>
                        </ContractDetailItem>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="event_repeat" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Laufzeit</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>1 Jahr</ContractDetailValue>
                        </ContractDetailItem>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="checkbook" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Anzahl an Schecks</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>{lease.payments.filter(payment => payment.purpose === PaymentPurpose.RENT).length}</ContractDetailValue>
                        </ContractDetailItem>
                        <ContractDetailItem>
                            <div className="d-flex" style={{ gap: 8 }}>
                                <MaterialIcon icon="move_to_inbox" size={20} color={colorPalette.textDark} />
                                <ContractDetailLabel>Mietkaution</ContractDetailLabel>
                            </div>
                            <ContractDetailValue>AED {formatMoney(lease.deposit_amount, false)}</ContractDetailValue>
                        </ContractDetailItem>
                    </ContractDetails>

                    {lease.documents.length > 0 && (
                        <DocumentsContainer>
                            <CategoryTitle>Mietdokumente</CategoryTitle>
                            <CardContainer>
                                {lease.documents.map((document) => (
                                    <Item
                                        key={document.document_id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onDownloadLeaseDocument(document);
                                        }}
                                    >
                                        <div className="d-flex align-items-center" style={{ gap: 8 }}>
                                            <MaterialIcon icon="contact_page" size={20} color={colorPalette.successMedium} />
                                            <div>
                                                <ItemTitle>{formatDocumentType(document.document_type ?? null)}</ItemTitle>
                                                <ItemSubtitle>Aktiv</ItemSubtitle>
                                            </div>
                                        </div>
                                        {downloadingStates[document.document_id] ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ color: colorPalette.blue500 }}
                                            />
                                        ) : (
                                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                                        )}
                                    </Item>
                                ))}
                            </CardContainer>
                        </DocumentsContainer>
                    )}

                    <ButtonWrapper>
                        <CTAButton
                            variant="ghost"
                            size="S"
                            icon="contact_support"
                            label="Fragen? Kontaktiere uns"
                            onClick={() => alert("Coming soon...")}
                            noPadding
                        />
                    </ButtonWrapper>
                </SidebarModalScrollableContent>
            </SidebarModal>
        </SidebarModalBackdrop >
    );
};

export default ActiveTenancyContractSidebarModal;
