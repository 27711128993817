import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const Title = styled.h2`
  color: ${colorPalette.primaryDark};
  margin-top: 16px;
  margin-bottom: 16px;
  ${typeScale.headingMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingSMMain};
  }
`;

export const CardContainer = styled.div`
  background: linear-gradient(
    90deg,
    ${colorPalette.successMedium} 0%,
    ${colorPalette.successDark} 100%
  );
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  color: ${colorPalette.white};
  cursor: pointer;
  margin-bottom: 24px;

  @media (max-width: 767.98px) {
    margin-bottom: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  @media (max-width: 767.98px) {
    padding: 16px;
  }
`;

export const HeaderTitle = styled.h2`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.headingSMMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const PaymentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 767.98px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Amount = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.headingMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 767.98px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const DueDate = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.headingSMMain}

  @media (max-width: 767.98px) {
    ${typeScale.headingXXSMain};
  }
`;

export const Label = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  ${typeScale.bodyXS}

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 767.98px) {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const ProgressSegment = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "filled",
})<{ filled: boolean }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ filled }) =>
    filled ? colorPalette.white : "rgba(255, 255, 255, 0.3)"};
  z-index: 1;
`;

export const ProgressSegmentLine = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "width" && prop !== "filled",
})<{ width: string; filled: boolean }>`
  height: 5px;
  border-radius: 8px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${({ filled }) =>
    filled ? colorPalette.white : "rgba(255, 255, 255, 0.3)"};
  width: ${({ width }) => width};
`;

export const StatusSection = styled.div`
  margin-top: 16px;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;

  @media (max-width: 767.98px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
`;

export const StatusLabel = styled.p`
  margin: 0;
  color: ${colorPalette.white};
  ${typeScale.bodyMDMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain};
  }
`;

export const StatusDescription = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  ${typeScale.bodyXS};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;
