import React, { useRef, useState, useEffect } from 'react';
import {
    PropertyUpdatesContainer,
    ScrollButton,
    Title,
    Subtitle,
    HeaderContainer
} from './property-updates.styles';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';
import Construction1 from '../../assets/images/property-updates/construction-update-1.png';
import Construction2 from '../../assets/images/property-updates/construction-update-2.png';
import Construction3 from '../../assets/images/property-updates/construction-update-3.png';
import Construction4 from '../../assets/images/property-updates/construction-update-4.png';
import useIsMobile from '../../hooks/useIsMobile';
import CTAButton from '../cta-button/cta-button';
import UpdateCard from './components/update-card';

export interface PropertyUpdate {
    id: number;
    image: string;
    title: string;
    date: string;
    category: string;
    project_name: string;
    youtubeVideoId: string;
}

const mockData: PropertyUpdate[] = [
    {
        id: 1,
        image: Construction1,
        title: 'Bauupdate im Februar - Der Grundstein ist gelegt',
        date: '22.09.2024',
        category: 'Bauupdates',
        project_name: 'The Ivy',
        youtubeVideoId: 'l8_qBH1amAs',
    },
    {
        id: 2,
        image: Construction2,
        title: 'Fortschritt im März - Die Struktur nimmt Form an',
        date: '22.03.2024',
        category: 'Bauupdates',
        project_name: 'The Vyne',
        youtubeVideoId: 'l8_qBH1amAs',
    },
    {
        id: 3,
        image: Construction3,
        title: 'Bauupdate im April - Der Rohbau ist abgeschlossen',
        date: '22.04.2024',
        category: 'Bauupdates',
        project_name: 'The Vyne',
        youtubeVideoId: 'l8_qBH1amAs',
    },
    {
        id: 4,
        image: Construction4,
        title: 'Bauupdate im Mai - Fassadenarbeiten beginnen',
        date: '22.05.2024',
        category: 'Bauupdates',
        project_name: 'The Ivy',
        youtubeVideoId: 'l8_qBH1amAs',
    },
];

interface PropertyUpdatesProps {
    className?: string | undefined;
    propertyId?: number;
    propertyName?: string;
}

const PropertyUpdates: React.FC<PropertyUpdatesProps> = ({ className, propertyId, propertyName }) => {
    const isMobile = useIsMobile();

    // References
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // States
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    // Functions
    const updateScrollButtons = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
        }
    };

    const scroll = (direction: 'left' | 'right') => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: direction === 'left' ? -300 : 300,
                behavior: 'smooth',
            });
            updateScrollButtons();
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
        setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0);
        const walk = (x - startX) * 1.5;
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
    };

    // Effects
    useEffect(() => {
        updateScrollButtons();
        const handleResize = () => updateScrollButtons();
        window.addEventListener('resize', handleResize);
        const ref = scrollContainerRef.current;

        if (ref) {
            ref.addEventListener('scroll', updateScrollButtons);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            if (ref) {
                ref.removeEventListener('scroll', updateScrollButtons);
            }
        };
    }, []);

    return (
        <PropertyUpdatesContainer className={className}>
            <HeaderContainer>
                {isMobile ? (
                    <>
                        <div className="d-flex align-items-center" style={{ gap: 4, cursor: "pointer", width: "auto" }} onClick={() => alert("Coming soon...")}>
                            <Title>Bau-Updates</Title>
                            <MaterialIcon icon="chevron_forward" size={24} color={colorPalette.blue500} />
                        </div>
                        <Subtitle>{propertyName ? propertyName : "Alle Projekte"}</Subtitle>
                    </>
                ) : (
                    <>
                        <div className="d-flex align-items-center justify-content-between" style={{ gap: 4 }}>
                            <Title style={{ cursor: "pointer" }} onClick={() => alert("Coming soon...")}>Bau-Updates</Title>
                            <CTAButton
                                variant="ghost"
                                size="S"
                                label="Alle Bau-Updates"
                                icon="arrow_outward"
                                onClick={() => alert("Coming soon...")}
                                noPadding
                            />
                        </div>
                        <Subtitle>{propertyName ? propertyName : "Alle Projekte"}</Subtitle>
                    </>
                )}
            </HeaderContainer>
            <div className="scroll-wrapper">
                {canScrollLeft && (
                    <ScrollButton className="left d-none d-md-inline" onClick={() => scroll('left')}>
                        <MaterialIcon icon="arrow_back" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
                <div
                    className="updates-container"
                    ref={scrollContainerRef}
                    onScroll={updateScrollButtons}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUpOrLeave}
                    onMouseLeave={handleMouseUpOrLeave}
                >
                    {mockData.map((update) => {
                        return (<UpdateCard update={update} key={update.id.toString()} />);
                    })}
                </div>
                {canScrollRight && (
                    <ScrollButton className="right d-none d-md-inline" onClick={() => scroll('right')}>
                        <MaterialIcon icon="arrow_forward" size={16} color={colorPalette.textMain} />
                    </ScrollButton>
                )}
            </div>
        </PropertyUpdatesContainer>
    );
};

export default PropertyUpdates;
