import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const CardContainer = styled.div`
  background: #ffffffa6;
  backdrop-filter: blur(100px);
  border-radius: 12px;
  padding: 24px;
  border: 1px solid ${colorPalette.blue200};
`;

export const Title = styled.h2`
  margin-bottom: 8px;
  color: ${colorPalette.textMain};
  ${typeScale.headingSMMain};

  @media (max-width: 767.98px) {
    ${typeScale.headingXSMain};
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 16px;
  color: ${colorPalette.textDark};
  ${typeScale.bodySM};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXS};
  }
`;

export const Checklist = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ChecklistItem = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "clickable",
})<{ clickable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 16px;
  gap: 24px;
  transition: transform 0.3s ease;

  &:hover {
    cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
    transform: ${({ clickable }) => (clickable ? "scale(0.99)" : "none")};
  }

  &:last-child {
    padding-bottom: 0;
  }

  @media (max-width: 767.98px) {
    padding-bottom: 8px;
  }
`;

export const ChecklistItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const ChecklistIcon = styled.div<{ completed: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  background-color: ${({ completed }) =>
    completed ? colorPalette.successMedium : colorPalette.blue300};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const ChecklistText = styled.h3`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyLGMain};

  @media (max-width: 767.98px) {
    ${typeScale.bodySMMain};
  }
`;

export const ChecklistDescription = styled.p`
  margin: 4px 0 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXS};

  @media (max-width: 767.98px) {
    ${typeScale.bodyXXS};
  }
`;

export const ArrowIcon = styled.div`
  margin-left: auto;
  color: #b0b0b0;
  font-size: 1rem;
`;
