import styled from "styled-components";
import { colorPalette, typeScale } from "../../utils/theme";

// Button Size Definitions
const buttonSizeStyles = {
  L: {
    padding: "12px 24px",
    fontSize: typeScale.bodyMDMain.fontSize,
    fontWeight: typeScale.bodyMDMain.fontWeight,
    lineHeight: typeScale.bodyMDMain.lineHeight,
    fontFamily: typeScale.bodyMDMain.fontFamily,
  },
  M: {
    padding: "12px 24px",
    fontSize: typeScale.bodySMMain.fontSize,
    fontWeight: typeScale.bodySMMain.fontWeight,
    lineHeight: typeScale.bodySMMain.lineHeight,
    fontFamily: typeScale.bodySMMain.fontFamily,
  },
  S: {
    padding: "8px 16px",
    fontSize: typeScale.bodySMMain.fontSize,
    fontWeight: typeScale.bodySMMain.fontWeight,
    lineHeight: typeScale.bodySMMain.lineHeight,
    fontFamily: typeScale.bodySMMain.fontFamily,
  },
  XS: {
    padding: "8px 12px",
    fontSize: typeScale.bodyXSMain.fontSize,
    fontWeight: typeScale.bodyXSMain.fontWeight,
    lineHeight: typeScale.bodyXSMain.lineHeight,
    fontFamily: typeScale.bodyXSMain.fontFamily,
  },
};

// Button Color Styles for Different Variants
const buttonColorStyles = {
  primary: {
    backgroundColor: colorPalette.blue800,
    color: colorPalette.white,
  },
  secondary: {
    backgroundColor: colorPalette.primaryLight,
    color: colorPalette.blue800,
  },
  tertiary: {
    backgroundColor: colorPalette.blue200,
    color: colorPalette.blue800,
  },
  ghost: {
    backgroundColor: "transparent",
    color: colorPalette.blue500,
  },
};

// Hover Styles for Different Variants
const hoverStyles = {
  primary: { backgroundColor: colorPalette.blue700 },
  secondary: { backgroundColor: colorPalette.blue300 },
  tertiary: { backgroundColor: colorPalette.blue300 },
  ghost: { backgroundColor: "transparent" },
};

// Pressed Styles for Different Variants
const pressedStyles = {
  primary: { backgroundColor: colorPalette.blue900 },
  secondary: { backgroundColor: colorPalette.blue200 },
  tertiary: { backgroundColor: colorPalette.blue200 },
  ghost: { backgroundColor: "transparent" },
};

// Disabled Styles for Different Variants
const disabledStyles = {
  primary: { opacity: "30%", cursor: "not-allowed" },
  secondary: { opacity: "25%", cursor: "not-allowed" },
  tertiary: { opacity: "25%", cursor: "not-allowed" },
  ghost: { backgroundColor: "transparent", cursor: "not-allowed" },
};

export const SpinnerContainer = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid ${colorPalette.white};
  border-top: 3px solid ${colorPalette.blue800};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

// Button Container styled-component
export const ButtonContainer = styled.button.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "variant" &&
    prop !== "color" &&
    prop !== "size" &&
    prop !== "fullWidth" &&
    prop !== "disabled" &&
    prop !== "noPadding",
})<{
  variant: "primary" | "secondary" | "tertiary" | "ghost";
  color?: string;
  size: "L" | "M" | "S" | "XS";
  fullWidth: boolean;
  disabled?: boolean;
  noPadding?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.2s ease;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  border: none;

  // Apply padding and border-radius based on variant and size
  ${({ size, noPadding }) =>
    `
    border-radius: 4px;
    padding: ${noPadding ? "0" : buttonSizeStyles[size].padding};
  `}

  // Set font size and weight
  font-size: ${({ size }) => buttonSizeStyles[size].fontSize};
  font-weight: ${({ size }) => buttonSizeStyles[size].fontWeight};
  white-space: nowrap;

  // Set background and text color based on variant
  background-color: ${({ variant }) =>
    buttonColorStyles[variant].backgroundColor};
  color: ${({ color, variant }) => color || buttonColorStyles[variant].color};

  // Hide content when loading
  ${({ disabled }) =>
    disabled &&
    `
    & > *:not(${SpinnerContainer}) {
      display: none;
    }
  `}

  // Hover and active states
  &:hover {
    background-color: ${({ variant }) => hoverStyles[variant].backgroundColor};
    opacity: ${({ variant }) => (variant === "ghost" ? 0.8 : 1)};
  }
  &:active {
    background-color: ${({ variant }) =>
      pressedStyles[variant].backgroundColor};
  }

  // Disabled styles
  ${({ disabled, variant }) => disabled && disabledStyles[variant]}
`;
