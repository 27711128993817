import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { formatMoney } from '../../../../utils/helpers';
import { colorPalette, fonts } from '../../../../utils/theme';
import {
    ChartContainer,
    PortfolioVolume,
    PortfolioSubtitle,
    ChartHeader,
    ROIContainer,
    ROILabel,
    ROISubLabel,
    ChartContent,
} from './portfolio-chart-card.styles';
import { useContentArea } from '../../../../context/ContentAreaContext';
import useIsMobile from '../../../../hooks/useIsMobile';

interface PortfolioChartCardProps {
    portfolioVolume: number;
    grossYield: number;
    years: number[];
    volumeData: number[];
}

export interface PortfolioChartCardRef {
    hideTooltip: () => void;
}

const PortfolioChartCard = forwardRef<PortfolioChartCardRef, PortfolioChartCardProps>(
    ({ portfolioVolume, grossYield, years, volumeData }, ref) => {
        const { contentAreaRef } = useContentArea();
        const isMobile = useIsMobile();

        // References
        const chartRef = useRef<any>(null);

        // States
        const [backgroundColor, setBackgroundColor] = useState<string | CanvasGradient>(colorPalette.blue400);
        const [lineBorderColor, setLineBorderColor] = useState<string | CanvasGradient>("");

        useEffect(() => {
            if (chartRef && chartRef.current) {
                const chart = chartRef.current;
                const canvas = chart.canvas.getContext('2d');

                const { chartArea } = chart;
                if (!chartArea) return;

                const { top, bottom } = chartArea;

                // Create gradient for the line border
                const lineGradient = canvas.createLinearGradient(0, 0, canvas.canvas.width, 0);
                lineGradient.addColorStop(0, '#0F1C4D');
                lineGradient.addColorStop(0.68, '#276EF1');
                setLineBorderColor(lineGradient);

                // Create gradient for the background fill with reversed colors
                const fillGradient = canvas.createLinearGradient(0, top, 0, bottom);
                fillGradient.addColorStop(0, 'rgba(39, 110, 241, 0.25)');
                fillGradient.addColorStop(0.95, 'rgba(39, 110, 241, 0)');
                setBackgroundColor(fillGradient);
            }

            const handleScroll = () => {
                const tooltipEl = document.getElementById('chartjs-tooltip');
                if (tooltipEl) {
                    tooltipEl.style.opacity = '0';
                }
            };

            const scrollContainer = contentAreaRef.current;
            if (scrollContainer) {
                scrollContainer.addEventListener('scroll', handleScroll);
            }

            return () => {
                if (scrollContainer) {
                    scrollContainer.removeEventListener('scroll', handleScroll);
                }

                const tooltipEl = document.getElementById('chartjs-tooltip');
                if (tooltipEl) {
                    tooltipEl.remove();
                }
            };
        }, [chartRef, contentAreaRef]);

        const data = {
            labels: years,
            datasets: [
                {
                    label: 'Portfolio Value',
                    data: volumeData,
                    fill: true,
                    backgroundColor: backgroundColor,
                    borderColor: lineBorderColor,
                    borderWidth: 3,
                    pointRadius: 1,
                    pointHitRadius: 40,
                    pointHoverRadius: 10,
                    pointBorderColor: "transparent",
                    borderCapStyle: 'round' as CanvasLineCap,
                    borderJoinStyle: 'round' as CanvasLineJoin,
                    tension: 0.4,
                },
            ],
        };

        const options = useMemo(() => {
            return {
                type: "line",
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        border: {
                            color: "rgba(235,241,247,0.5)",
                        },
                        ticks: {
                            font: {
                                fontSize: "0.625rem",
                                fontWeight: 400,
                                lineHeight: 1.2,
                                family: fonts.primary,
                                color: colorPalette.textDark,
                            },
                            color: colorPalette.textDark,
                            padding: 10,
                        }
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                        border: {
                            display: false,
                        },
                        ticks: {
                            display: true,
                            stepSize: 1_000_000,
                            font: {
                                fontSize: "0.625rem",
                                fontWeight: 400,
                                lineHeight: 1.2,
                                family: fonts.primary,
                                color: colorPalette.textDark,
                            },
                            color: colorPalette.textDark,
                            callback: function (value: any) {
                                return value === 0 ? '' : value / 1_000_000 + 'M';
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                        external: function (context: any) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.style.opacity = '0';
                                tooltipEl.style.position = 'absolute';
                                tooltipEl.style.background = '#0F1C4D';
                                tooltipEl.style.borderRadius = '8px';
                                tooltipEl.style.padding = '12px';
                                tooltipEl.style.pointerEvents = 'none';
                                tooltipEl.style.fontFamily = fonts.primary;
                                tooltipEl.style.color = '#FFFFFF';
                                tooltipEl.style.zIndex = '4';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            const tooltipModel = context.tooltip;
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = '0';
                                return;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                const dataIndex = tooltipModel.dataPoints[0].dataIndex;
                                const rawValue = tooltipModel.dataPoints[0].raw as number;
                                const formattedValue = `AED ${formatMoney(rawValue, false)}`;
                                const year = tooltipModel.title[0] || '';

                                const volumeData = context.chart.data.datasets[0].data;
                                let units = 0;

                                for (let i = 1; i <= dataIndex; i++) {
                                    if (volumeData[i] !== volumeData[i - 1]) {
                                        units++;
                                    }
                                }

                                const unitsText = `${units} ${units === 1 ? 'Einheit' : 'Einheiten'}`;
                                const dotSize = isMobile ? "12px" : "20px"

                                tooltipEl.innerHTML = `
                                <div style="display: flex; align-items: center;">
                                    <div style="width: ${dotSize}; height: ${dotSize}; border-radius: 50%; background-color: ${colorPalette.blue400}; margin-right: 8px;"></div>
                                    <div>
                                        <div style="font-size: ${isMobile ? "10px" : "12px"}; font-weight: 500; font-family: Manrope; color: ${colorPalette.textDark};">
                                            ${year}
                                        </div>
                                        <div style="font-size: ${isMobile ? "14px" : "16px"}; font-weight: 700; font-family: Manrope; color: ${colorPalette.blue50};">
                                            ${formattedValue}
                                        </div>
                                        <div style="font-size: ${isMobile ? "12px" : "14px"}; font-weight: 500; font-family: Manrope; color: ${colorPalette.blue300};">
                                            ${unitsText}
                                        </div>
                                    </div>
                                </div>
                            `;
                            }

                            // Position the tooltip
                            const position = context.chart.canvas.getBoundingClientRect();
                            const tooltipWidth = tooltipEl.offsetWidth;
                            const tooltipHeight = tooltipEl.offsetHeight;

                            let left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipWidth / 2;
                            let top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipHeight - 8;

                            // Adjust if the tooltip is going beyond the viewport
                            if (left + tooltipWidth > window.innerWidth) {
                                left = window.innerWidth - tooltipWidth - 10;
                            }

                            if (left < 0) {
                                left = 10;
                            }

                            if (top < 0) {
                                top = position.top + window.pageYOffset + tooltipModel.caretY + 8;
                            }

                            // Apply adjusted position
                            tooltipEl.style.opacity = '1';
                            tooltipEl.style.left = `${left}px`;
                            tooltipEl.style.top = `${top}px`;
                        }
                    }
                }
            };
        }, [isMobile]);

        const hideTooltip = () => {
            const tooltipEl = document.getElementById('chartjs-tooltip');
            if (tooltipEl) {
                tooltipEl.style.opacity = '0';
            }
        };

        useImperativeHandle(ref, () => ({
            hideTooltip,
        }));

        return (
            <ChartContainer>
                <ChartHeader>
                    <div>
                        <PortfolioVolume>AED {formatMoney(portfolioVolume, false)}</PortfolioVolume>
                        <PortfolioSubtitle>Portfoliovolumen (Kaufpreis)</PortfolioSubtitle>
                    </div>
                    <ROIContainer>
                        {grossYield > 0 && (
                            <div>
                                <ROISubLabel>{grossYield.toFixed(2)}%</ROISubLabel>
                                <ROILabel>ROI (Brutto)</ROILabel>
                            </div>
                        )}
                    </ROIContainer>
                </ChartHeader>
                <ChartContent>
                    <Line ref={chartRef} data={data} options={options} />
                </ChartContent>
            </ChartContainer>
        );
    });

export default PortfolioChartCard;
