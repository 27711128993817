import React from 'react';
import {
    CardContainer,
    Header,
    HeaderTitle,
    Amount,
    Details,
    GrossReturn,
    Label,
    Transactions,
    TransactionItem,
    TransactionAmount,
    TransactionTitle,
    RentSummary,
} from './rent-payments-card.styles';
import { colorPalette } from '../../../../utils/theme';
import MaterialIcon from '../../../../components/material-icon';
import { formatMoney } from '../../../../utils/helpers';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';

interface RentPaymentsCardProps {
    unitNumber: string;
    propertyName: string;
    annualRent: number;
    grossReturn: number;
    onViewAll: () => void;
}

const RentPaymentsCard: React.FC<RentPaymentsCardProps> = ({
    unitNumber,
    propertyName,
    annualRent,
    grossReturn,
    onViewAll,
}) => {
    const isMobile = useIsMobile();

    return (
        <CardContainer>
            {isMobile ? (
                <Header>
                    <div>
                        <Amount>AED {formatMoney(annualRent, false)}</Amount>
                        <Label>Gewinn 2024 ({unitNumber} • {propertyName})</Label>
                    </div>

                    <div className="d-flex text-end" style={{ gap: 16 }}>
                        <div>
                            <GrossReturn>{grossReturn.toFixed(2)}%</GrossReturn>
                            <Label>Nettorendite</Label>
                        </div>
                        <div>
                            <GrossReturn>{grossReturn.toFixed(2)}%</GrossReturn>
                            <Label>Bruttorendite</Label>
                        </div>
                    </div>
                </Header>
            ) : (
                <>
                    <Header>
                        <HeaderTitle>Einnahmen & Ausgaben</HeaderTitle>
                        <CTAButton
                            variant="ghost"
                            size="S"
                            label="Details"
                            icon="arrow_outward"
                            onClick={onViewAll}
                            color={colorPalette.blue500}
                            noPadding
                        />
                    </Header>
                    <RentSummary>
                        <Amount>AED {formatMoney(annualRent, false)}</Amount>
                        <GrossReturn>{grossReturn.toFixed(2)}%</GrossReturn>
                    </RentSummary>
                    <Details>
                        <Label>Gewinn 2024 ({unitNumber} • {propertyName})</Label>
                        <Label>Bruttoredite</Label>
                    </Details>
                </>
            )}
            <Transactions>
                <TransactionItem>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        <MaterialIcon icon="location_away" size={24} color={colorPalette.successMedium} />
                        <TransactionTitle>Mieteinnahmen</TransactionTitle>
                    </div>
                    <TransactionAmount>AED {formatMoney(100000)}</TransactionAmount>
                </TransactionItem>
                <TransactionItem>
                    <div className="d-flex align-items-center" style={{ gap: 8 }}>
                        <MaterialIcon icon="send_money" size={24} color={colorPalette.errorDark} />
                        <TransactionTitle>Ausgaben</TransactionTitle>
                    </div>
                    <TransactionAmount>- AED {formatMoney(10000)}</TransactionAmount>
                </TransactionItem>
                {isMobile && (
                    <TransactionItem>
                        <CTAButton
                            variant="ghost"
                            size="S"
                            label="Einnahmen & Ausgaben"
                            icon="arrow_outward"
                            onClick={onViewAll}
                            color={colorPalette.blue500}
                            noPadding
                        />
                    </TransactionItem>
                )}
            </Transactions>
        </CardContainer>
    );
};

export default RentPaymentsCard;
