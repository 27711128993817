// searchStore.ts
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface SearchStoreState {
  search: string;
  setSearch: (value: string) => void;
}

const useSearchStore = create<SearchStoreState>()(
  devtools((set) => ({
    search: "",
    setSearch: (value: string) => set({ search: value }),
  }))
);

export default useSearchStore;
