import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { IoChevronDownOutline } from 'react-icons/io5';
import { FilterContainer, FilterLabel, DropdownContainer, DropdownItem } from './filter.styles';
import { useContentArea } from '../../context/ContentAreaContext';
import MaterialIcon from '../material-icon';
import { colorPalette } from '../../utils/theme';

interface DropdownOption {
    label: string;
    value: string;
}

interface FilterProps {
    label: string;
    hasDropdown?: boolean;
    items?: DropdownOption[];
    multiple?: boolean;
    selectedItems?: string[];
    isSelected?: boolean;
    disabled?: boolean;
    onSelect?: (values: string[]) => void;
}

const Filter: React.FC<FilterProps> = ({
    label,
    hasDropdown = false,
    items = [],
    multiple = false,
    isSelected = false,
    disabled = false,
    selectedItems = [],
    onSelect,
}) => {
    const { contentAreaRef } = useContentArea();

    // States
    const [selected, setSelected] = useState(isSelected);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const filterRef = useRef<HTMLDivElement | null>(null);

    const [dropdownStyles, setDropdownStyles] = useState({
        top: 0,
        left: 0,
        width: 0,
    });

    const toggleDropdown = () => {
        if (disabled) return;
        setIsOpen((prev) => !prev);
    };

    const handleItemClick = (value: string) => {
        const newSelectedItems = new Set(selectedItems);

        if (newSelectedItems.has(value)) {
            newSelectedItems.delete(value);
        } else {
            if (!multiple) newSelectedItems.clear();
            newSelectedItems.add(value);
        }

        if (onSelect) {
            onSelect(Array.from(newSelectedItems));
        }
        setIsOpen(false);
    };

    const handleFilterClick = () => {
        if (disabled || hasDropdown) return;
        setSelected((prev) => !prev);
        if (onSelect) onSelect(Array.from(selectedItems));
    };

    const updateDropdownPosition = () => {
        if (filterRef.current) {
            const rect = filterRef.current.getBoundingClientRect();
            setDropdownStyles({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
            });
        }
    };

    useEffect(() => {
        if (isOpen) {
            updateDropdownPosition();
        }
    }, [isOpen]);

    useEffect(() => {
        const handleScrollOrResize = () => {
            if (isOpen) {
                updateDropdownPosition();
            }
        };

        const scrollContainer = contentAreaRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScrollOrResize);
            scrollContainer.addEventListener('resize', handleScrollOrResize);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScrollOrResize);
                scrollContainer.removeEventListener('resize', handleScrollOrResize);
            }
        };
    }, [isOpen, contentAreaRef]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                filterRef.current &&
                !filterRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const displayLabel = multiple
        ? label
        : selectedItems.length > 0
            ? `${label}: ${Array.from(selectedItems)
                .map((value) => items.find((item) => item.value === value)?.label)
                .join(', ')}`
            : label;

    return (
        <div ref={filterRef} style={{ position: 'relative' }}>
            <FilterContainer
                isSelected={hasDropdown ? selectedItems.length > 0 : selected}
                onClick={hasDropdown ? toggleDropdown : handleFilterClick}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
            >
                <FilterLabel>{displayLabel}</FilterLabel>
                {!hasDropdown && selected && (
                    <MaterialIcon icon="close" size={16} color={colorPalette.blue300} />
                )}
                {hasDropdown && <IoChevronDownOutline />}
            </FilterContainer>
            {hasDropdown && isOpen &&
                ReactDOM.createPortal(
                    <DropdownContainer
                        ref={dropdownRef}
                        style={{
                            position: 'absolute',
                            top: dropdownStyles.top,
                            left: dropdownStyles.left,
                            width: dropdownStyles.width,
                        }}
                    >
                        {items.map((item) => (
                            <DropdownItem key={item.value} onClick={() => handleItemClick(item.value)}>
                                {item.label}
                            </DropdownItem>
                        ))}
                    </DropdownContainer>,
                    document.body
                )}
        </div>
    );
};

export default Filter;
