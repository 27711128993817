import styled from "styled-components";
import { colorPalette, typeScale } from "../../../../utils/theme";

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border: 1px solid ${colorPalette.blue200};
  border-radius: 8px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const TableHeader = styled.th`
  padding: 12px 16px;
  background: ${colorPalette.blue200};
  color: ${colorPalette.textMain};
  ${typeScale.bodySMMain};
`;

export const TableRow = styled.tr<{ highlight?: boolean }>`
  background: ${({ highlight }) =>
    highlight ? colorPalette.errorLight : "transparent"};
  &:nth-child(even) {
    background: ${colorPalette.blue50};
  }
`;

export const TableCell = styled.td`
  padding: 16px;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXS};

  &:first-child,
  &:last-child {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const TableBody = styled.tbody`
  tr:hover {
    background: ${colorPalette.blue100};
  }
`;

export const CategoryTitle = styled.p`
  margin: 0;
  color: ${colorPalette.textMain};
  ${typeScale.bodyXSMain};
`;

export const CategorySubtitle = styled.p`
  margin: 0;
  color: ${colorPalette.textDark};
  ${typeScale.bodyXXS};
`;

export const StatusBadge = styled.span<{
  type: "open" | "received" | "due" | "paid";
}>`
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: bold;
  ${typeScale.bodyXS};
  color: ${colorPalette.white};
  background: ${({ type }) =>
    type === "open"
      ? colorPalette.textMain
      : type === "received"
      ? colorPalette.successMedium
      : type === "due"
      ? colorPalette.errorDark
      : colorPalette.blue200};
`;

export const Amount = styled.p`
  margin: 0;
  ${typeScale.bodySMMain}
`;
