import { Col, Row } from 'react-bootstrap';
import CTAButton from '../../../../components/cta-button/cta-button';
import useIsMobile from '../../../../hooks/useIsMobile';
import { StyledContainer, Subtitle, Title } from './update-address.styles';
import PersonalDataForm from '../../../../components/personal-data-form/personal-data-form';

interface UpdateAddressProps {
    onClickNext: () => void;
}

const UpdateAddress: React.FC<UpdateAddressProps> = ({ onClickNext }) => {
    const isMobile = useIsMobile();

    return (
        <StyledContainer>
            <Row className="justify-content-center" style={{ marginTop: 32 }}>
                <Col xs={12} lg={8}>
                    <Title>Vervollständige deine Daten</Title>
                    <Subtitle>Bitte vervollständige deine persönlichen Daten, die du beim Kauf deiner Wohnung angegeben hast. Dies ist notwendig, um gesetzliche Regularien in den VAE einzuhalten.</Subtitle>
                    <div style={{ marginTop: 12 }}>
                        <CTAButton
                            variant="ghost"
                            size={isMobile ? "M" : "L"}
                            label="Wie werden meine Daten verwendet?"
                            onClick={() => alert("Coming soon...")}
                            noPadding
                        />
                    </div>

                    <PersonalDataForm onDataStored={onClickNext} />
                </Col>
            </Row>
        </StyledContainer>
    );
};

export default UpdateAddress;
