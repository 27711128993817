import React, { useState } from 'react';
import { PropertyUpdate } from '../property-updates';
import useIsMobile from '../../../hooks/useIsMobile';
import YouTubeModal from '../../../modals/youtube-modal/youtube-modal';
import { CardContainer, PlayButton, UpdateContent, UpdateDate, UpdateTitle } from './update-card.styles';
import { colorPalette } from '../../../utils/theme';
import MaterialIcon from '../../material-icon';
import LazyLoadImage from '../../lazy-load-image/lazy-load-image';

interface UpdateCardProps {
    update: PropertyUpdate;
}

const UpdateCard: React.FC<UpdateCardProps> = ({ update }) => {
    const isMobile = useIsMobile();

    // States
    const [isHovering, setIsHovering] = useState(false);
    const [isYouTubeModalOpen, setIsYouTubeModalOpen] = useState(false);
    const [youtubeUrl, setYoutubeUrl] = useState("");

    // Functions
    const openYouTubeVideo = (videoId: string) => {
        const url = `https://www.youtube.com/watch?v=${videoId}`;
        setYoutubeUrl(videoId);

        if (isMobile && window.matchMedia('(display-mode: standalone)').matches) {
            // If on mobile PWA, try to open YouTube app
            window.open(url, '_blank');
        } else {
            // On desktop, open the modal
            setIsYouTubeModalOpen(true);
        }
    };

    const handleCloseYoutubeModal = () => {
        setIsYouTubeModalOpen(false);
        setYoutubeUrl("");
    };

    return (
        <>
            <CardContainer
                key={update.id}
                onClick={() => openYouTubeVideo(update.youtubeVideoId)}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <div style={{ position: 'relative', width: '100%' }}>
                    <LazyLoadImage
                        src={update.image}
                        alt={update.title}
                        width="100%"
                        height={220}
                        borderRadius="8px"
                    />
                    <PlayButton isHovering={isHovering}>
                        <MaterialIcon icon="play_arrow" size={30} color={colorPalette.blue400} />
                    </PlayButton>
                </div>
                <UpdateContent>
                    <UpdateDate>{update.date} • {update.project_name}</UpdateDate>
                    <UpdateTitle>{update.title}</UpdateTitle>
                </UpdateContent>
            </CardContainer>

            {/* YouTube Modal for Desktop */}
            {!isMobile && (
                <YouTubeModal
                    isOpen={isYouTubeModalOpen}
                    url={youtubeUrl}
                    onClose={handleCloseYoutubeModal}
                />
            )}
        </>
    );
};

export default UpdateCard;
